import usePost from '@base/hooks/usePost';
import { FilterInput } from '@base/types/interfaces/common';
import { BaseMutationResponse, BaseResponse } from '@base/types/interfaces/response';
import { graphQLApi } from '@base/utils/axios/graphql';
import {
  CustomerPreview,
  IAddAssignInput,
  ICustomerContactAssignInput,
  ICustomerModel,
  ICustomersResponse,
  IDeleteAssignInput,
  IDeleteCustomerContactAssignInput,
  IShortCustomer,
} from '@customer/customer/types/interface/customer';
import {
  CUSTOMER_CUSTOMER,
  CUSTOMER_EMPLOYEE_CONTACT_LIST,
  GET_CUSTOMER_ASSIGNED_REPS,
  GET_CUSTOMER_INFO,
} from '@customer/customer/services/graphql';
//import { UPDATE_CUSTOMER, GET_CUSTOMER_INFO } from './graphql/customer';

export const useCustomerContacts = (customerId: string, page = 1, size = 100) => {
  let filter: FilterInput = {
    filters: {
      category: 'CATEGORY_CONTACT',
      contactType: ['CONTACT_TYPE_EMPLOYEE'],
      parent: customerId,
    },
    paging: {
      page,
      size,
    },
  };
  let queryKeys = ['customer_customerAssignContacts', customerId, page];
  const usePostResult = usePost<ICustomersResponse>(queryKeys, CUSTOMER_EMPLOYEE_CONTACT_LIST, {
    filter,
  });
  // console.log('useCustomerContacts: ', usePostResult);
  return usePostResult;
};

export const useCustomerAssignReps = (customerId: string, page = 1, size = 100) => {
  let params = {
    customerId: customerId,
  };
  let queryKeys = ['customer_staffs', customerId, page];
  const usePostResult = usePost<ICustomersResponse>(queryKeys, GET_CUSTOMER_ASSIGNED_REPS, params);
  // console.log('useCustomerAssignReps: ', usePostResult);
  return usePostResult;
};

//get customer info
export const useCustomer = (id: string, defaultOptions: any[]) => {
  let params = {
    id,
  };
  let queryKeys = ['customer_customer', id];
  const usePostResult = usePost<IShortCustomer>(queryKeys, GET_CUSTOMER_INFO, params, {
    //keepPreviousData: true,
    enabled: defaultOptions?.length === 0 || defaultOptions === null,
  });

  return usePostResult;
};

//get customer info
export const useCustomerPreview = (id: string, defaultOptions: any[]) => {
  let params = {
    id,
  };
  let queryKeys = ['customer_customer', id];
  const usePostResult = usePost<CustomerPreview>(queryKeys, CUSTOMER_CUSTOMER, params, {
    enabled: defaultOptions?.length === 0 || defaultOptions === null,
  });

  return usePostResult;
};
