import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { ListType } from '@base/types/enums';
//import { getMoreActions } from '@base/utils/helpers/list-page-utils';
import {
  customerListFilterState,
  customerWriteOptionAtom,
} from '@customer/customer/recoil/atom/list-atom';
import { MENU_CUSTOMER } from '@base/config/menus';
import ListToolbarContainer from '@base/containers/list/toolbar';
import { CATEGORY_OPTIONS, CUSTOMER_ADD_OPTIONS } from '@customer/main/config/constants';
import CustomerMergeModal from '@customer/customer/containers/customer-merge-modal';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_USER_SETTING } from '@base/services/graphql/setting';

const PageToolbar: React.FC<any> = (props) => {
  const { isSplitMode, setIsSplitMode, category, refetch } = props;
  //recoil
  const [listFilter, setListFilter] = useRecoilState(customerListFilterState);
  const setWriteOption = useSetRecoilState(customerWriteOptionAtom); //for write page
  //state
  const [showMerge, setShowMerge] = useState(false);
  let { listType } = listFilter;
  const navigate = useNavigate();

  //mutation update
  const mUserSettingUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  //do action
  const handleSelectAction = (actionKey: string) => {
    switch (actionKey) {
      case 'merge':
        setShowMerge(true);
        break;
    }
  };

  //change list type
  const hanldeChangeListType = (listType: ListType) => {
    //update to recoil
    const newListFilter = {
      ...listFilter,
      listType,
    };
    setListFilter(newListFilter);

    //save setting to db
    const params = {
      userSetting: {
        menu: MENU_CUSTOMER,
        key: 'list',
        value: JSON.stringify(newListFilter)
      },
    };
    mUserSettingUpdate.mutate(params);
  };

  //more actions
  const moreActions = {
    actions: {
      merge: {
        label: 'Merge',
        icon: 'merge',
        iconType: 'common',
      },
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => handleSelectAction(key),
  };

  //console.log('showMerge', showMerge);
  return (
    <>
      <ListToolbarContainer
        listTypeProps={{
          listTypeStr: listType,
          listTypes: [ListType.GRID, ListType.LIST, ListType.SPLIT],
          onChangeListType: hanldeChangeListType
        }}
        actionsProps={moreActions}
        isSplitMode={isSplitMode}
        //setIsSplitMode={setIsSplitMode}
        onRefresh={refetch}
        addOptions={CUSTOMER_ADD_OPTIONS}
        onAdd={(selectedCategory: string | undefined) =>
          setWriteOption((filter) => ({
            ...filter,
            isOpenWrite: true,
            writeType: selectedCategory === undefined ? (category === 'all' ? 'account' : category) : selectedCategory,
            writeMenu: 'list',
          }))
        }
        categoryOptions={CATEGORY_OPTIONS}
        categorySelected={category}
        onCategoryChange={(category: string) => {
          //go to
          navigate(`/${MENU_CUSTOMER}/${category}`);
        }}
        menu={MENU_CUSTOMER}
      />
      {showMerge &&
        <CustomerMergeModal
          title={'Merge Customer'}
          isOpen={showMerge}
          onClose={() => setShowMerge(false)}
          defaultItems={[]}
          category={category}
          onReload={refetch}
        />
      }
    </>
  );
};

export default PageToolbar;
