import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { components } from 'react-select';
//import AsyncSelect from '@base/components/form/async-select';
import { Select } from '@base/components/form';
import { Avatar } from '@base/components';
import { FormIcon } from '@base/components/form/icon';
import CustomerPopover from '@customer/customer/containers/customer-popover';
import {
  CUSTOMER_CATEGORY_ACCOUNT,
  CUSTOMER_CATEGORY_ACCOUNT_NUM,
  CUSTOMER_CATEGORY_CONTACT,
  CUSTOMER_CATEGORY_CONTACT_NUM,
  CUSTOMER_CATEGORY_EMPLOYEE,
  CUSTOMER_TYPE_ENUM,
} from '@customer/customer/config/constants';
//import { CustomerCategory } from '@customer/customer/types/enums/customer-enums';
import { useCustomersAutoComplete } from '@customer/customer/services/list-service';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../base/assets/icons/svg-icons';

interface ICustomerAutoCompleteProps {
  className?: string;
  ctrlStyles?: any;
  placeholder?: string;
  placement?: string;
  category?: string;
  type?: string;
  accountId?: string;
  single?: boolean;
  visible?: boolean;
  showAvatar?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  showAllOption?: boolean;
  showPopover?: boolean;
  isDisabled?: boolean;
  exceptItems?: any;
  value: any;
  onChange: any;
  addLabel?: string;
  onAdd?: any;
}

/**
 *
 * @param {*} props
 * @returns
 */
const CustomerAutoComplete: React.FC<ICustomerAutoCompleteProps> = (props) => {
  const {
    className,
    ctrlStyles,
    placeholder = 'Type or click to select a customer...',
    placement = 'right',
    category = '', //'' : all, 'account', 'contact'
    type = '', //'TYPE_VENDOR, TYPE_PARTNER'
    accountId, //for get account's employees
    single = false, //
    visible = true, //hide or display selected items
    showAvatar = false,
    showEmail = false,
    showPhone = false,
    showAllOption = false,
    showPopover = false,
    isDisabled = false,
    exceptItems = [],
    value, //[], initial value
    onChange,
    addLabel,
    onAdd,
  } = props;
  const { t } = useTranslation();
  //state
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const setSearchTextDebounced = useRef(
    _.debounce((searchText) => setSearchText(searchText), 1000),
  ).current;
  const [options, setOptions] = useState<any>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const popoverRef = useRef(null); //for popover
  //const [target, setTarget] = useState(null); //for popover

  //initial selected
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          //2 cases for value: string[] or object[]
          let isArrayString = _.isString(value[0]);
          if (isArrayString) {
            const selectedIds = selectedValue?.map((_ele: any) => _ele.value);
            if (JSON.stringify(value) !== JSON.stringify(selectedIds)) {
              const newValue: any = [];
              value.map((_item: string) => {
                //find in options
                const fOption = options.find((_ele: any) => _ele.value === _item);
                if (fOption) {
                  newValue.push(fOption);
                }
              });
              setSelectedValue(newValue);
            }
          } else {
            //array object
            if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
              setSelectedValue(value);
            }
          }
        } else {
          setSelectedValue([]);
        }
      } else {
        //single object
        if (_.isString(value)) {
          if (value !== selectedValue?.value) {
            //find in options
            const fOption = options.find((_ele: any) => _ele.value === value);
            if (fOption) {
              setSelectedValue(fOption);
            }
          }
        } else {
          if (value?.value !== selectedValue?.value) {
            setSelectedValue(value);
          }
          //if value is not in options
          const fIndex = options.findIndex((_ele: any) => _ele.value === value?.value);
          if (fIndex === -1) {
            const newOptions = [...options];
            newOptions.unshift(value);
            setOptions(newOptions);
          }
        }
      }
    } else {
      setSelectedValue(null);
    }
  }, [value, options]);

  //build params
  const getSearchParams = () => {
    let params: any = {
      filter: {
        //filters: {},
        //keyword: searchText,
        query: '',
        paging: {
          page: 1,
          size: 50,
        },
      },
    };

    //category params
    if (category === CUSTOMER_CATEGORY_ACCOUNT) {
      params.filter.query = `category=${CUSTOMER_CATEGORY_ACCOUNT_NUM}`;
    } else if (category === CUSTOMER_CATEGORY_CONTACT) {
      params.filter.query = `category=${CUSTOMER_CATEGORY_CONTACT_NUM}`;
      if (accountId) {
        params.filter.query += ` contactType=1 accounts=${accountId}`;
      } else {
        params.filter.query += ` {contactType=2 contactType=3 contactType=4 contactType=5 contactType=6}`;
      }
    }
    // else if (category === CUSTOMER_CATEGORY_EMPLOYEE) {
    //   params.filter.query = `category=${CUSTOMER_CATEGORY_CONTACT_NUM}`;
    //   params.filter.query += ` contactType=1`; //CONTACT_TYPE_EMPLOYEE
    //   if (accountId) {
    //     params.filter.query += ` accounts=${accountId}`;
    //   }
    // }

    //type params
    if (type && type.length > 0) {
      //params.filter.filters.type = type.split(','); // type='partner, vendor'
      type.split(',').map((_ele: string) => {
        params.filter.query += ` type=${CUSTOMER_TYPE_ENUM[_ele]}`; //type=3,4
      });
    }

    return params;
  };

  //convert to select options
  const formatSelectOptions = (results: any) => {
    let tmpOptions = results?.data?.map((_item: any) => {
      return {
        ..._item,
        label: _item.name,
        value: _item.id,
      };
    });
    //TODO: filter by execptIds
    if (showAllOption) {
      tmpOptions?.unshift({
        label: t('All Customers'),
        value: 'all',
      });
    }
    return tmpOptions;
  };

  // const {
  //   data: results,
  //   status: searchStatus,
  //   refetch,
  // } = useCustomersAutoComplete(getSearchParams());
  const results = [
    {
      id: "hanbiro",
      name: "Hanbiro Company"
    }
  ];
  const searchStatus = "loaded";
  //// console.log('postResult', results);

  //init states list
  useEffect(() => {
    if (results) {
      let newOptions = formatSelectOptions(results);
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [results]);

  //input text change
  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to ""
    if (event.action !== 'input-blur' && event.action !== 'menu-close') {
      setInputText(inputText);
      setSearchTextDebounced(inputText);
    }
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    let newItem = newValue;
    if (visible) {
      if (!single) {
        //just apply for multi
        const allIdx = newItem?.findIndex((ele: any) => ele.value === 'all');
        if (allIdx > -1) {
          newItem = [newItem[allIdx]];
        }
      }
      setSelectedValue(newItem);
    }
    //callback
    onChange && onChange(newItem);
  };

  //selected items from popover
  const handleSelectCustomers = (items: any) => {
    if (single) {
      //items is []
      if (items && items.length > 0) {
        const newSelect = {
          ...items[0],
          label: items[0].name,
          value: items[0].id,
        };
        if (visible) {
          setSelectedValue(newSelect);
        }
        //callback
        onChange && onChange(newSelect);
      }
    } else {
      //items is []
      if (items && items.length > 0) {
        const newSelect = selectedValue ? [...selectedValue] : [];
        items.map((item: any) => {
          const idx = newSelect.findIndex((el) => el.id === item.id);
          if (idx === -1) {
            newSelect.push({
              ...item,
              label: item.name,
              value: item.id,
            });
          }
        });
        if (visible) {
          setSelectedValue(newSelect);
        }
        //callback
        onChange && onChange(newSelect);
      }
    }
    //close popover
    setShowList(false);
  };

  //selected options
  const CustomMultiValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    const primaryEmail = data?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    const primaryPhone = data?.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    const getLabel = () => {
      if (showEmail) {
        return primaryEmail?.email;
      } else if (showPhone) {
        return primaryPhone?.phoneNumber;
      } else {
        return children;
      }
    };
    return (
      <components.MultiValueLabel {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">{getLabel()}</div>
        </div>
      </components.MultiValueLabel>
    );
  };

  //single selected option
  const CustomSingleValueLabel = ({ children, ...props }: any) => {
    const { data } = props;
    const primaryEmail = data?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
    return (
      <components.SingleValue {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">
            {showEmail ? primaryEmail?.email : children}
          </div>
        </div>
      </components.SingleValue>
    );
  };

  //custom options
  const CustomOption = ({ children, ...props }: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center css-customer-autocomplete">
          {showAvatar && (
            <div className="avatar avatar-xs">
              {/* TODO: data?.photo */}
              <Avatar url={''} alt={data?.name} height={26} width={26} />
            </div>
          )}
          <div className="d-flex mg-l-10 css-flex">{children}</div>
        </div>
        {data.value === 'all' && <hr className="mg-y-10" />}
      </components.Option>
    );
  };
  //render
  return (
    <div
      className={classNames('pos-relative', className)}
      style={{ minWidth: '250px' }}
      ref={popoverRef}
    >
      <Select
        isMulti={!single}
        ctrlStyles={ctrlStyles}
        noOptionsMessage={() => 'No customers found.'}
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={<div className="icons-size-1">{Icon('m_customer')}</div>}
        isClearable
        isDisabled={isDisabled}
        components={{
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
          SingleValue: CustomSingleValueLabel,
        }}
        isLoading={false}
        inputValue={inputText}
        onInputChange={handleInputChange}
        value={selectedValue}
        options={options}
        //getOptionLabel={color => color.name}
        onChange={handleSelectChange}
        addLabel={addLabel}
        onAdd={onAdd}
        onClickIndicator={(e: any) => {
          setShowList(!showList);
          //setTarget(e.target);
        }}
      />
      {/* <AsyncSelect
        isMulti={!single}
        isClearable
        cacheOptions
        placeholder={<div className="react-select-placeholder">{placeholder}</div>}
        iconIndicator={
          <div className="icons-size-1">{<FormIcon icon="m_customer" iconType="main" />}</div>
        }
        onClickIndicator={(e: any) => {
          setShowList(!showList);
          setTarget(e.target);
        }}
        components={{
          Option: CustomOption,
          MultiValueLabel: CustomMultiValueLabel,
          SingleValue: CustomSingleValueLabel,
        }}
        defaultOptions={options}
        loadOptions={fetchOptions}
        value={selectedValue}
        onChange={handleSelectChange}
        addLabel={category === 'account' ? 'Add new account' : 'Add new contact'}
        onAdd={onAdd}
        isDisabled={isDisabled}
      /> */}

      {showPopover && (
        <CustomerPopover
          placement={placement}
          visibleTab={true}
          category={category} //'account', 'contact', '' --> all
          type={type}
          accountId={accountId}
          single={single}
          isOpen={showList}
          onClose={() => setShowList(false)}
          data={selectedValue}
          onApply={handleSelectCustomers}
          containerRef={document.body} //popoverRef, document.body
          target={popoverRef}
        />
      )}
    </div>
  );
};

export default CustomerAutoComplete;
