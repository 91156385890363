import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@base/components/form';
import usePosts from '@base/hooks/usePosts';
import { GET_REGION_CITIES, GET_REGION_STATES } from '@base/services/graphql/setting';
import { useRecoilValue } from 'recoil';
import { countriesSelector, defaultCountrySelector } from '@base/recoil/selectors';

interface IState {
  subdivision: string;
}

interface ICity {
  city: string;
}

const AddressItem: React.FC<any> = (props: any) => {
  const { className = '', value, onChange } = props;
  const countries = useRecoilValue(countriesSelector);
  const defaultCountry = useRecoilValue(defaultCountrySelector);

  //default
  const defaultValue = {
    // label: {
    //   languageKey: 'Primary',
    //   label: LABEL_VALUE_PRIMARY,
    // },
    // labelValue: '',
    country: defaultCountry, //country, isoCode2
    zipcode: '',
    state: '',
    city: '',
    street: '',
    primary: false,
  };
  //state
  const { t } = useTranslation();
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [addressValue, setAddressValue] = useState<any>(defaultValue);

  //init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(addressValue)) {
        setAddressValue(value);
      }
    } else {
      setAddressValue(defaultValue);
    }
  }, [value]);

  //HOOK get states
  const stateParams = {
    filter: {
      filters: {
        //countryCode: addressValue.country?.isoCode2,
        query: `countryCode=${addressValue.country?.isoCode2}`
      },
      paging: {
        page: 1,
        size: 500,
      },
    },
  };
  const { data: statesData, isLoading: stateLoading } = usePosts<IState[]>(
    ['regions', addressValue.country?.isoCode2],
    GET_REGION_STATES,
    stateParams,
    {
      keepPreviousData: true,
      enabled: addressValue.country?.isoCode2?.length > 0,
    },
  );
  //HOOK get cities
  const cityParams = {
    filter: {
      filters: {
        //subdivision: addressValue.state,
        query: `subdivision=${addressValue.state}`
      },
      paging: {
        page: 1,
        size: 500,
      },
    },
  };
  const { data: citiesData, isFetching: cityLoading } = usePosts<ICity[]>(
    ['regions', addressValue.state],
    GET_REGION_CITIES,
    cityParams,
    {
      keepPreviousData: true,
      enabled: addressValue.state.length > 0,
    },
  );

  //init states list
  useEffect(() => {
    if (statesData?.data) {
      let _rows: any = [];
      statesData?.data.map((_item: any) => {
        if (_item?.subdivision != '') {
          _rows.push({ value: _item?.subdivision, label: _item?.subdivision });
        }
      });
      setStates(_rows);
    }
  }, [statesData]);

  //init cities list
  useEffect(() => {
    if (citiesData?.data) {
      let _rows: any = [];
      citiesData?.data?.map((_item: any) => {
        if (_item?.city != '') {
          _rows.push({ value: _item?.city, label: _item?.city });
        }
      });
      setCities(_rows);
    }
  }, [citiesData]);

  //value change
  const handleValueChange = (keyAttribute: string, keyValue: any) => {
    const newAddressValue = { ...addressValue };
    newAddressValue[keyAttribute] = keyValue;
    if (keyAttribute === 'country') {
      newAddressValue.state = '';
      newAddressValue.city = '';
    }
    if (keyAttribute === 'state') {
      newAddressValue.city = '';
    }
    setAddressValue(newAddressValue);
    //callback
    onChange && onChange(newAddressValue);
  };

  //// console.log('addressValue', addressValue);
  return (
    <div className={className}>
      <div className="address-relative">
        <div className="form-row mg-b-10">
          <div className="col-md-6">
            <Select
              outSide
              isSearchable={false}
              value={addressValue.country}
              options={countries}
              getOptionLabel={(option: any) => option.country}
              getOptionValue={(option: any) => option.isoCode2}
              onChange={(newOption: any) => handleValueChange('country', newOption)}
            />
          </div>

          <div className="col-md-6">
            <input
              name="zipcode"
              placeholder={'zip code'}
              className="form-control ht-38"
              type="text"
              value={addressValue.zipcode}
              onChange={(e: any) => handleValueChange('zipcode', e.target.value)}
            />
          </div>
        </div>

        <div className="form-row mg-b-10">
          <div className="col-md-6">
            <Select
              outSide
              isSearchable={false}
              options={states}
              value={
                addressValue.state
                  ? states[states.findIndex((_ele: any) => _ele.value === addressValue.state)]
                  : null
              }
              onChange={(newOption: any) => handleValueChange('state', newOption.value)}
              isLoading={stateLoading}
            />
          </div>

          <div className="col-md-6">
            <Select
              outSide
              isSearchable={false}
              options={cities}
              value={
                addressValue.city
                  ? cities[cities.findIndex((_ele: any) => _ele.value === addressValue.city)]
                  : null
              }
              onChange={(newOption: any) => handleValueChange('city', newOption.value)}
              isLoading={cityLoading}
            />
          </div>
        </div>

        <div className="form-row mg-b-10">
          <div className="col-md-6">
            <input
              //name="street"
              placeholder={t('address')}
              className="form-control ht-38"
              type="text"
              value={addressValue.street}
              onChange={(e: any) => handleValueChange('street', e.target.value)}
            />
          </div>
          {/* <div className={classNames('col-md-6', { 'd-none': isSingle })}>
            <Select
              value={addressValue?.option}
              options={addressOptions}
              onChange={(item: any) => {
                handleOptionChange(item);
              }}
            />
          </div> */}
        </div>

        {/* {addressValue?.option?.value === '1' && !isSingle && (
          <div className="form-row mg-b-10">
            <div className="col-md-12">
              <input
                name="label_value"
                placeholder={t('crm_new_common_description')}
                className="form-control ht-38"
                type="text"
                onChange={(e) => {
                  handleOptionDescriptionChange(e.target.value);
                }}
                defaultValue={addressValue?.optionDescription}
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AddressItem;
