import React, { useState } from 'react';
import { SpanLang, AsyncSelect } from '@base/components/form';
import Canvas from '@base/components/canvas';
import classNames from 'classnames';

/**
 *
 * @param {*} param0
 * @returns
 */
const ParentAccount = (props: any) => {
  const {
    className,
    value, //initial data: [{}, {}]
    onChange,
  } = props;

  //state
  const [options, setOptions] = useState<any[]>([]);
  //for sidebar
  const [showAdd, setShowAdd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({
    value: '',
    label: '',
  });
  const [accountChoosed, setAccountChoosed] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);

  //save new account
  const handleSave = () => {
    setIsSaving(true);
    setTimeout(() => {
      setAccountChoosed(accountChoosed.concat([data]));
      setAccounts(accounts.concat([data]));
      setData({
        value: '',
        label: '',
      });
      setIsSaving(false);
      setShowAdd(false);
    }, 500);
  };

  //new account ID
  const onIDChange = (value: any) => {
    setData((dataOld) => ({
      ...dataOld,
      value: value,
      time: new Date().getTime(),
    }));
  };

  //Add new: For get new account IDs
  const onNameChange = (e: any) => {
    const label = e.target.value;

    setData((dataOld) => ({
      ...dataOld,
      label: label,
      time: new Date().getTime(),
    }));
  };

  //fetch data
  const fetchOptions = async (inputValue: any) => {
    let options: any[] = [];

    options.push({
      value: 'red',
      label: 'Red',
    });

    options.push({
      value: 'blue',
      label: 'Blue',
    });

    setOptions(options);
    return options;
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    onChange && onChange(newValue);
  };

  //render
  return (
    <div className={classNames('pos-relative', className)}>
      <AsyncSelect
        //isMulti
        cacheOptions
        defaultOptions={options}
        value={value ? value : []} //[{label: "Customer Name 32", value: "Cust-ID-32"}]
        loadOptions={fetchOptions}
        onChange={handleSelectChange}
      />
      {showAdd && (
        <Canvas isVisible={showAdd} onCloseSideBar={() => setShowAdd(false)} className={'wd-400'}>
          <Canvas.Header
            title={<SpanLang keyLang="crm_new_generalsetting_add_item" isString={true} />}
          />
          <Canvas.Body>
            <form>
              <div className="form-group">
                <label htmlFor="formGroupExampleInput" className="d-block">
                  <SpanLang keyLang="crm_new_common_id" isString={true} />
                  <span className="tx-danger">*</span>
                </label>
                {/* <CodeGenerator
                  value={data?.value}
                  menu="parent_account"
                  onChange={onIDChange}
                /> */}
              </div>
              <div className="form-group">
                <label htmlFor="formGroupExampleInput2" className="d-block">
                  <SpanLang keyLang="crm_new_common_name" isString={true} />
                  <span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  value={data?.label}
                  onChange={onNameChange}
                />
              </div>
            </form>
          </Canvas.Body>
          <Canvas.Footer
            onClose={() => setShowAdd(false)}
            onSave={handleSave}
            saveLoading={isSaving}
          />
        </Canvas>
      )}
    </div>
  );
};

export default ParentAccount;
