import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import ReactGrid from '@base/components/react-grid';
import { deviceAtom } from '@base/recoil/atoms/app';
import { Checkbox } from '@base/components/form';
import { ListType } from '@base/types/enums';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import * as keyNames from '@customer/customer/config/key-names';
import { ListBody, ListPagination } from '@base/components/list';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import { PAGING_ITEMS_PER_PAGE } from '@customer/customer/config/constants';
import { getMapColumns, getMapColumnsInSplitMode } from '@customer/customer/config/list';
import { MENU_CUSTOMER } from '@base/config/menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_USER_SETTING } from '@base/services/graphql/setting';

const PageBody: React.FC<any> = (props) => {
  const {
    isSplitMode,
    category, //router category
    isFetching,
    fields,
    itemsList,
    paging,
    selectedIds,
    onChecked,
    onGridChecked,
  } = props;
  const { t } = useTranslation();
  //state
  const { device } = useRecoilValue(deviceAtom);
  const [listFilter, setListFilter] = useRecoilState(customerListFilterState);
  const { listType } = listFilter;

  //mutation update
  const mUserSettingUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  //config disable sortBy columns
  const disableSortByColumns = [keyNames.KEY_NAME_CUSTOMER_PHOTO];
  const columnsByGrid = [keyNames.KEY_NAME_CUSTOMER_NAME, keyNames.KEY_NAME_CUSTOMER_EMAIL];
  if (category === 'contact') {
    columnsByGrid.push(keyNames.KEY_NAME_CUSTOMER_MOBILE);
    columnsByGrid.push(keyNames.KEY_NAME_CUSTOMER_TYPE);
  } else {
    columnsByGrid.push(keyNames.KEY_NAME_CUSTOMER_PHONES);
    columnsByGrid.push(keyNames.KEY_NAME_CUSTOMER_INDUSTRIES);
  }
  const hideColumns = [
    keyNames.KEY_NAME_CUSTOMER_NAME,
    keyNames.KEY_NAME_CUSTOMER_CREATED_BY,
    keyNames.KEY_NAME_CUSTOMER_CREATED_AT,
    keyNames.KEY_NAME_CUSTOMER_UPDATED_BY,
    keyNames.KEY_NAME_CUSTOMER_UPDATED_AT,
    keyNames.KEY_NAME_CUSTOMER_PHOTO,
    keyNames.KEY_NAME_CUSTOMER_CATEGORY,
    keyNames.KEY_NAME_CUSTOMER_DESCRIPTION,
  ];

  //columns in grid split mode
  const gridFieldsInSplitMode: any[] = [];
  columnsByGrid.map((_col: string) => {
    fields.map((_ele: any) => {
      if (_ele.name === _col) {
        gridFieldsInSplitMode.push(_ele);
      }
    });
  });
  //console.log('gridFieldsInSplitMode', gridFieldsInSplitMode);

  //set filter sort param
  const setSort = (sort: any) => {
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        [category]: {
          ...listFilter.filter[category],
          sort: sort,
        },
      },
    };
    setListFilter(newListFilter);
  };

  //paging change
  const handlePagingChange = (page: number, size: number) => {
    const curPageSize = listFilter.filter[category].paging.size;

    //update to recoil
    const newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        [category]: {
          ...listFilter.filter[category],
          paging: { page, size },
        },
      },
    };
    setListFilter(newListFilter);

    //save setting to db - only update page size
    if (curPageSize !== size) {
      const newFilterSetting = {
        ...listFilter,
        filter: {
          ...listFilter.filter,
          [category]: {
            ...listFilter.filter[category],
            paging: { page: 1, size },
          },
        },
      };
      const params = {
        userSetting: {
          menu: MENU_CUSTOMER,
          key: 'list',
          value: JSON.stringify(newFilterSetting)
        },
      };
      mUserSettingUpdate.mutate(params);
    }
  };

  //grid props
  const gridProps = {
    isSplitMode,
    //category: category, //router category
    //primaryKey: 'id',
    //titleKey: 'name',
    //titleUrlKey: 'url',
    gridData: itemsList,
    isLoading: isFetching,
    device: device !== undefined ? device : 'desktop',
    columns: isSplitMode ? gridFieldsInSplitMode : fields,
    columnRenderRemap: isSplitMode ? getMapColumnsInSplitMode(category) : getMapColumns(category),
    hideColumns,
    checkedIds: selectedIds,
    onCheckedRow: onGridChecked,
    //gridNoData: <GridNothing />,
    //gridLoading: <ListLoading />,
    //gridItemRender: null,
    photoKey: 'photo',
  };

  //fields for table
  const tableFields = fields.map((_ele: any) => ({
    ..._ele,
    enableSorting: !disableSortByColumns.includes(_ele.keyName),
    width: _ele.keyName === keyNames.KEY_NAME_CUSTOMER_PHOTO ? '100px' : 'auto',
  }));

  //paging
  const pagingProps = {
    isSplitMode,
    totalPage: paging?.totalPage || 1,
    totalItems: paging?.totalItems || 0,
    currentPage: paging?.currentPage || 1,
    itemPerPage: listFilter.filter[category].paging.size || PAGING_ITEMS_PER_PAGE,
    onChange: handlePagingChange,
  };

  //build columns for table v8
  const tableColumns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        width: '45px',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...makeTable8Columns(tableFields, getMapColumns(category), { category }, []),
    ],
    [tableFields, selectedIds],
  );

  //v8
  const listTable8Props = {
    className: '',
    columns: tableColumns,
    data: itemsList,
    initialState: {
      //pageCount: paging?.totalItems || 0,
      pageSize: paging?.itemPerPage || 15,
      //pageIndex: paging?.currentPage || 1,
      //sorts: [{ field: 'createdAt', orderBy: DESC }],
    },
    //rowSelected: selectedIds,
    onRowSelect: onChecked,
    onSortBy: (clName: any, isSorted: any) => {
      //isSorted from react-table-v8 that is false, 'asc' or 'desc'
      //// console.log('isSorted', isSorted);
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
  };

  //v8: body
  const ListBodyMemo = useMemo(() => {
    return (
      <>
        {/* table list */}
        {listType == ListType.LIST && <ListReactTable8 {...listTable8Props} />}
        {/* grid list */}
        {listType == ListType.GRID && (
          <ReactGrid
            {...gridProps}
          // gridItemRender={(props: any) => <CustomGridItem {...props} />}
          />
        )}
      </>
    );
  }, [itemsList, isSplitMode, listType]);

  //main
  return (
    <ListBody isScrollY={listType !== ListType.LIST}>
      {isSplitMode ? (
        <ReactGrid
          {...gridProps}
        // gridItemRender={(props: any) => <CustomGridItem {...props} />}
        />
      ) : (
        ListBodyMemo
      )}
      <ListPagination type="full" {...pagingProps} />
    </ListBody>
  );
};

export default PageBody;
