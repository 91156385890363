import React from 'react';
import TextView from '@base/containers/view-field/text/view';
import { formatAddress } from '@customer/main/utils';

//convert value to address string
const View: React.FC = (props: any) => {
  const { value } = props;

  return (
    <div>
      <div className='form-group'>
        <span className='form-item-title'>Billing</span>
        {value?.billing ?
          <TextView value={formatAddress(value?.billing)} />
          :
          <div className='pd-t-15'>
            <TextView value={''} />
          </div>
        }
      </div>
      <div className='form-group'>
        <span className='form-item-title'>Shipping</span>
        {value?.shipping ?
          <TextView value={formatAddress(value?.shipping)} />
          :
          <div className='pd-t-15'>
            <TextView value={''} />
          </div>
        }
      </div>
    </div>
  );
};

export default View;
