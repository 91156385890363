import { useRecoilValue } from 'recoil';
import { FilterInput } from '@base/types/interfaces/common';
import usePosts from '@base/hooks/usePosts';
import usePost from '@base/hooks/usePost';
import { ICustomerModel, IShortCustomer } from '@customer/customer/types/interface/customer';
//import { stringify } from 'query-string';
import { MENU_CUSTOMER } from '@base/config/menus';
import { getQueryString } from '@base/services/list';
import { CUSTOMER_DUPLICATES_GET, CUSTOMER_LIST_FOR_SELECT, CUSTOMER_RELATED_PRODUCT_GET } from '@customer/customer/services/graphql';
import { generateFilterQuery, keyStringify } from '@base/utils/helpers/list-page-utils';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import { buildListSchema } from '@base/utils/helpers/schema';
import { default as configFields } from '@customer/customer/config/view-field';
//import { categoryEnum } from '@customer/customer/config/list';
import { ResponsePaging } from '@base/types/interfaces/response';
import {
  CUSTOMER_CATEGORY_ACCOUNT,
  CUSTOMER_CATEGORY_ACCOUNT_NUM,
  CUSTOMER_CATEGORY_ALL,
  CUSTOMER_CATEGORY_CONTACT,
  CUSTOMER_CATEGORY_CONTACT_NUM,
} from '../config/constants';

export const useCustomers = (category: string, fields: any[]) => {
  const menu = `${MENU_CUSTOMER}_customers`;

  const { filter } = useRecoilValue(customerListFilterState);

  //build filter
  let filtersQuery: FilterInput = {
    sort: filter[category].sort,
    paging: filter[category].paging,
  };
  //build query
  let strQuery = '';
  if (category === CUSTOMER_CATEGORY_ALL) {
    strQuery = `{category=${CUSTOMER_CATEGORY_ACCOUNT_NUM} category=${CUSTOMER_CATEGORY_CONTACT_NUM}}`;
  }
  if (category === CUSTOMER_CATEGORY_ACCOUNT) {
    strQuery = `category=${CUSTOMER_CATEGORY_ACCOUNT_NUM}`;
  }
  if (category === CUSTOMER_CATEGORY_CONTACT) {
    strQuery = `category=${CUSTOMER_CATEGORY_CONTACT_NUM}`;
  }
  //filter params
  const cateQuery = filter[category].query;
  strQuery += ' ' + generateFilterQuery(cateQuery);
  filtersQuery.query = strQuery;

  //build schema
  let listQuerySchema = '';
  if (fields && fields.length > 0) {
    listQuerySchema = buildListSchema({ fields, configFields });
  }
  //add category field
  listQuerySchema = listQuerySchema + '\ncategory';
  const queryString = getQueryString(menu, listQuerySchema);

  //get params
  let params = {
    filter: filtersQuery,
  };

  //query fields key
  const fieldQueryKey = fields.map((_ele: any) => _ele.keyName).join(',');

  const usePostResult = usePosts<ICustomerModel[]>(
    [menu, keyStringify(filtersQuery, ''), fieldQueryKey],
    queryString,
    params,
    {
      //keepPreviousData: true,
      enabled: fields.length > 0,
    },
  );

  return usePostResult;
};

export const useCustomersAutoComplete = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['customer_customersSelect', keyStringify(params.filter, '')],
    CUSTOMER_LIST_FOR_SELECT,
    params,
  );

  return usePostResult;
};

export const useCustomersAssign = (params: any) => {
  const usePostResult = usePost<ResponsePaging<IShortCustomer>>(
    ['customer_customersSelect', keyStringify(params, '')], //query keys
    CUSTOMER_LIST_FOR_SELECT,
    params,
  );

  return usePostResult;
};

export const useCustomerRelatedProducts = (custId: string) => {
  const usePostResult = usePost<any>(
    ['customer_customer', custId], //query keys
    CUSTOMER_RELATED_PRODUCT_GET,
    { id: custId },
    { enabled: custId !== '' || custId !== undefined }
  );

  return usePostResult;
};

export const useCustomersByField = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['customer_customersSelect', keyStringify(params.filter, '')],
    CUSTOMER_LIST_FOR_SELECT,
    params,
    {
      enabled: params.filter.query.length > 0,
    },
  );

  return usePostResult;
};

export const useCustomerDuplicates = (params: any) => {
  const usePostResult = usePosts<any[]>(
    ['customer_duplicates', keyStringify(params.filter, '')],
    CUSTOMER_DUPLICATES_GET,
    params,
    {
      enabled: params.filter.query.length > 0,
    },
  );

  return usePostResult;
};
