import React from 'react';
import { useRecoilValue } from 'recoil';
import { MENU_CUSTOMER } from '@base/config/menus';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { ViewFields } from '@base/components/hook-form/view';
import * as keyNames from '@customer/customer/config/key-names';
import Loading from '@base/components/loading';

interface IDetailViewProps {
  id: string;
  category: string; //menu category
  //showTop?: boolean;
}

const DetailView: React.FC<IDetailViewProps> = (props) => {
  const { category, id } = props;
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_CUSTOMER));

  //get fields by group
  const basicFields = viewData?.layout?.data?.[0]?.children ?? [];
  const moreFields = viewData?.layout?.data?.[1]?.children || [];
  //// console.log('basicFields', basicFields);

  //hidden fields
  let ignoreFields: string[] = [];
  if (category === 'account') {
    ignoreFields = [
      keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO,
      keyNames.KEY_NAME_CUSTOMER_CREATED_BY,
      keyNames.KEY_NAME_CUSTOMER_CREATED_AT,
      keyNames.KEY_NAME_CUSTOMER_UPDATED_BY,
      keyNames.KEY_NAME_CUSTOMER_UPDATED_AT,
      //keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT,
      //keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT,
    ];
  }
  if (category === 'contact') {
    const contactTypeField = basicFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE,
    );
    if (contactTypeField?.data?.keyName === 'CONTACT_TYPE_EMPLOYEE') {
      const showFields = [
        //keyNames.KEY_NAME_CUSTOMER_FAX,
        keyNames.KEY_NAME_CUSTOMER_ANNIVERSARIES,
        keyNames.KEY_NAME_CUSTOMER_DESCRIPTION,
      ];
      ignoreFields = moreFields
        .filter((_field: any) => !showFields.includes(_field.keyName))
        .map((_ele: any) => _ele.keyName);
    } else {
      ignoreFields = [
        keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO,
        keyNames.KEY_NAME_CUSTOMER_CREATED_BY,
        keyNames.KEY_NAME_CUSTOMER_CREATED_AT,
        keyNames.KEY_NAME_CUSTOMER_UPDATED_BY,
        keyNames.KEY_NAME_CUSTOMER_UPDATED_AT,
        //keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT,
        //keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT,
        //keyNames.KEY_NAME_CUSTOMER_AMOUNT_RECEIVED,
      ];
    }
  }

  return (
    <div className="pd-t-15">
      <div className="scroll-box" style={{ height: 'calc(100vh - 160px)' }}>
        <div className="view-aside-section-body">
          <div className="form-row mg-x-0 mg-t-10">
            <React.Suspense fallback={<Loading />}>
              <ViewFields
                fields={moreFields}
                ignoreFields={ignoreFields}
                column={2}
                menu={viewData?.menu ?? ''}
                menuSource={viewData?.menuSource ?? ''}
                menuSourceId={viewData?.menuSourceId ?? ''}
              />
            </React.Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailView;
