import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { ListContainer } from '@base/components/list';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { deviceAtom } from '@base/recoil/atoms/app';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { MENU_CUSTOMER } from '@base/config/menus';
import { listDataByMenuAtom } from '@base/recoil/atoms';
import { useCustomers } from '@customer/customer/services/list-service';
import displayLayoutConfig from '@customer/customer/config/display-layout';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import {
  KEY_NAME_CUSTOMER_CATEGORY,
  KEY_NAME_CUSTOMER_ID,
} from '@customer/customer/config/key-names';
import PageHeader from './header';
import PageToolbar from './toolbar';
import PageBody from './body';
import PageQuickAction from './quick-actions';
import WriteModal from '../write/write-modal';

interface IListPage {
  isSplitMode: boolean;
  setIsSplitMode?: (isSplitMode: boolean) => void;
}

const ListPage: React.FC<IListPage> = (props) => {
  const { isSplitMode, setIsSplitMode } = props;
  //router
  const params = useParams();
  let category = 'account'; //customer category
  const matchAll = useMatch('/customer/all/*');
  const matchAccount = useMatch('/customer/account/*');
  const matchContact = useMatch('/customer/contact/*');
  if (matchAll) {
    category = 'all';
  }
  if (matchAccount) {
    category = 'account';
  }
  if (matchContact) {
    category = 'contact';
  }
  //state
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { isDesktop, device, layout } = useRecoilValue(deviceAtom);
  const [listData, setListData] = useRecoilState(listDataByMenuAtom(MENU_CUSTOMER)); //current list
  const [listFilter, setListFilter] = useRecoilState<any>(customerListFilterState);
  const layoutMenu = `${MENU_CUSTOMER}_${category}`; //for list
  const navigate = useNavigate();

  /*===================================== HOOK ===================================== */
  //get pagelayout
  let { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    layoutMenu,
    'list',
  );

  //fields for build schema
  let viewingFields: any = [];
  if (listFilter.settingColumns[category]) {
    viewingFields = listFilter.settingColumns[category].filter((_ele: any) => _ele.isViewing);
  }
  //console.log('viewingFields', viewingFields);

  //get list data
  const { data: resultsPost, isFetching: isDataFetching, refetch } = useCustomers(category, viewingFields);
  //console.log('resultsPost', resultsPost);

  //set viewing columns
  useEffect(() => {
    //listLayoutData?.data === pagelayout fields
    if (listLayoutData?.data) {
      const curSettingColumns = listFilter.settingColumns[category];
      const newLayoutCols: any[] = [];
      listLayoutData.data.map((_ele: any) => {
        newLayoutCols.push({ ..._ele, isViewing: _ele.defaultViewInList }); //_ele.isDefault
      });
      if (!_.isEqual(curSettingColumns, newLayoutCols)) {
        //check user setting and layout setting
        let newSettingCols: any = [];
        if (!curSettingColumns || curSettingColumns?.length === 0) {
          newSettingCols = newLayoutCols;
        } else {
          curSettingColumns.map((_ele: any) => {
            //1.if not exist in pagelayout, skip
            if (newLayoutCols.findIndex((_col: any) => _col.keyName === _ele.keyName) > -1) {
              newSettingCols.push(_ele);
            }
          })
          //2.new fields in pagelayout, setting not have 
          newLayoutCols.map((_col: any) => {
            if (curSettingColumns.findIndex((_ele: any) => _col.keyName === _ele.keyName) === -1) {
              newSettingCols.push(_col);
            }
          })
        }
        //console.log('newSettingCols', newSettingCols);
        const newListFilter = {
          ...listFilter,
          settingColumns: {
            ...listFilter.settingColumns,
            [category]: newSettingCols,
          },
          activeMenu: category,
        };
        setListFilter(newListFilter);
      }
    }
  }, [listLayoutData, category]);

  //set data
  useEffect(() => {
    if (resultsPost?.data) {
      setListData(resultsPost.data);
    } else {
      setListData([]);
    }
  }, [resultsPost?.data]);

  //checked or unchecked all
  const handleCheckAll = (checkedValue: string) => {
    if (checkedValue === 'all') {
      let newSelectedIds = listData.map((_ele) => _ele.id);
      setSelectedIds(newSelectedIds);
    } else {
      setSelectedIds([]);
    }
  };

  //for react-table v8
  const handleCheckTableItem = (checkedValue: any[]) => {
    setSelectedIds(checkedValue);
  };

  //go view for new in case of split
  const handleGoView = (id: string, custCategory: string) => {
    if (isSplitMode) {
      const url =
        category === 'all'
          ? `/${MENU_CUSTOMER}/${category}/${id}/${custCategory}`
          : `/${MENU_CUSTOMER}/${category}/${id}`;
      navigate(url);
    }
  };

  /** ============================ RENDER ================================ */
  //toolbar
  const PageToolbarMemo = useMemo(() => {
    return (
      <PageToolbar
        isSplitMode={isSplitMode}
        setIsSplitMode={setIsSplitMode}
        category={category}
        refetch={refetch}
      />
    );
  }, [isSplitMode, category]);

  //header
  const PageHeaderMemo = useMemo(() => {
    return (
      <PageHeader
        isSplitMode={isSplitMode}
        fields={viewingFields}
        category={category}
        selectedIds={selectedIds}
        onChecked={handleCheckAll}
        refetch={refetch}
      />
    );
  }, [isSplitMode, category, viewingFields, listData, selectedIds]);

  //body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={isSplitMode}
        category={category}
        //isFetching={false}
        fields={viewingFields}
        itemsList={listData}
        paging={resultsPost?.paging}
        selectedIds={selectedIds}
        onChecked={handleCheckTableItem} //for table
        onGridChecked={setSelectedIds} //for grid
      />
    );
  }, [isSplitMode, category, listData, viewingFields, selectedIds]);

  //track get list and go to first order view
  useEffect(() => {
    //if first load and split = true, go to first item view, router
    if (params?.id === undefined && isSplitMode && listData.length > 0) {
      const [firstOrder] = listData;
      let custCategory =
        firstOrder[KEY_NAME_CUSTOMER_CATEGORY] === 'CATEGORY_ACCOUNT' ? 'account' : 'contact';
      const url =
        category === 'all'
          ? `/${MENU_CUSTOMER}/${category}/${firstOrder[KEY_NAME_CUSTOMER_ID]}/${custCategory}`
          : `/${MENU_CUSTOMER}/${category}/${firstOrder[KEY_NAME_CUSTOMER_ID]}`;
      navigate(url);
    }
  }, [category, listData]);

  //main
  return (
    <>
      <ListContainer
        isLoading={isLayoutLoading || isDataFetching}
        isSplitMode={isSplitMode}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          toolbar={PageToolbarMemo}
          header={PageHeaderMemo}
          body={PageBodyMemo}
        />
      </ListContainer>

      <PageQuickAction
        category={category}
        itemsList={listData}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onReload={refetch}
      />

      <WriteModal
        writeMenu='list'
        customerType={''}
        refetch={refetch}
        onGoView={handleGoView}
      />
    </>
  );
};

export default ListPage;
