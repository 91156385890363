import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  CornerUpLeft,
  Download,
  GitMerge,
  Mail,
  Smartphone,
  Trash2,
  UserCheck,
  UserMinus,
  UserPlus,
  Users,
} from 'react-feather';
import { confirmAlert } from '@base/components';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import ListQuickAction from '@base/components/list/list-quick-action';
import AssignRepModal from '@base/containers/assign-rep-modal';
import UnAssignRepModal from '@base/containers/unassign-rep-modal';
import {
  CUSTOMER_DELETE,
  CREATE_CUSTOMER_ASSIGN_REP,
  DELETE_CUSTOMER_ASSIGN_REP,
} from '@customer/customer/services/graphql';
import CustomerMergeModal from '@customer/customer/containers/customer-merge-modal';
import { useRecoilValue } from 'recoil';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';

const PageQuickAction: React.FC<any> = (props) => {
  const { category, itemsList, selectedIds, setSelectedIds, onReload } = props;
  const { t } = useTranslation();
  //state
  const [showModal, setShowModal] = useState(false);
  const [showUnModal, setShowUnModal] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const [allReps, setAllReps] = useState([]);
  const listFilter = useRecoilValue(customerListFilterState);
  const isGroupByDeleted = listFilter.filter[category].query?.deleted?.value || false;

  //init data
  useEffect(() => {
    let newReps: any = [];
    itemsList.map((_item: any) => {
      if (selectedIds.includes(_item.id)) {
        _item.assignTo?.map((_assign: any) => {
          const fIdx = newReps.findIndex((_rep: any) => _rep.id === _assign.id);
          if (fIdx === -1) {
            newReps.push(_assign);
          }
        });
      }
    });
    setAllReps(newReps);
  }, [itemsList, selectedIds]);

  //delete order mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    CUSTOMER_DELETE,
    'customer_deleteCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Deleted customer successfully!');
        // refecth data
        setSelectedIds([]);
        onReload && onReload();
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Deleted customer failed: ' + JSON.parse(error).message);
      },
    },
  );

  //assign reps mutation
  const mutationAssignReps: any = useMutationPost<BaseMutationResponse>(
    CREATE_CUSTOMER_ASSIGN_REP,
    'customer_createAssignTo',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Assigned new reps successfully!');
        //close modal
        setShowModal(false);
        // refecth data
        setSelectedIds([]);
        onReload && onReload();
        //TODO: how to refresh on Assigned Rep of right pane
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Assigned new reps failed: ' + JSON.parse(error).message);
      },
    },
  );

  //unassign reps mutation
  const mutationUnAssignReps: any = useMutationPost<BaseMutationResponse>(
    DELETE_CUSTOMER_ASSIGN_REP,
    'customer_deleteAssignTo',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Unassigned reps successfully!');
        //close modal
        setShowUnModal(false);
        // refecth data
        setSelectedIds([]);
        onReload && onReload();
        //TODO: how to refresh on Assigned Rep of right pane
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Unassigned reps failed: ' + JSON.parse(error).message);
      },
    },
  );

  //delete item
  const handleDelete = () => {
    mutationDelete.mutate({ ids: selectedIds });
    // confirmAlert({
    //   title: t('crm_new_common_delete'),
    //   message: t('crm_new_common_delete_msg'),
    //   buttons: [
    //     {
    //       label: 'No',
    //       className: 'btn-secondary',
    //     },
    //     {
    //       label: 'Yes',
    //       className: 'btn-primary',
    //       onClick: () => {
    //         mutationDelete.mutate({ ids: selectedIds });
    //       },
    //     },
    //   ],
    // });
  };

  //assign reps
  const handleAssign = (selectedReps: any[]) => {
    if (selectedReps && selectedReps.length > 0) {
      const params: any = {
        ids: selectedIds, //sales order ids
        assignTo: selectedReps.map((_rep: any) => ({
          user: {
            id: _rep.id,
            name: _rep.name,
            //group
          },
        })),
      };
      mutationAssignReps.mutate(params);
    }
  };

  //unassign reps
  const handleUnAssign = (selectedReps: any[]) => {
    if (selectedReps && selectedReps.length > 0) {
      const params: any = {
        ids: selectedIds, //sales order ids
        repIds: selectedReps.map((_rep: any) => _rep.id),
      };
      mutationUnAssignReps.mutate(params);
    }
  };

  //restore deleted items
  const handleRestore = () => {
    //TODO
  }

  //buttons
  let quickButtons: any[] = [];
  //add merge
  if (category !== 'all' && selectedIds.length > 1) {
    quickButtons.push({
      title: 'Merge',
      icon: <GitMerge />,
      //icon: 'merge',
      //iconType: 'common',
      callback: () => setShowMerge(true),
    });
  }
  if (category === 'account') {
    quickButtons = quickButtons.concat([
      {
        title: 'Change Rep(s)',
        icon: <UserCheck />,
        callback: () => setShowModal(true),
      },
      {
        title: 'Add Reps',
        icon: <UserPlus />,
        callback: () => setShowModal(true),
      },
      {
        title: 'Delete Reps',
        icon: <UserMinus />,
        callback: () => setShowUnModal(true),
      },
    ]);
  }
  //more
  quickButtons = quickButtons.concat([
    {
      title: 'Send Mail',
      icon: <Mail />,
      callback: (selected: any) => null,
    },
    {
      title: 'Send SMS',
      icon: <Smartphone />,
      callback: (selected: any) => null,
    },
    {
      title: 'Export',
      icon: <Download />,
      callback: (selected: any) => null,
    },
    {
      title: 'Delete',
      icon: <Trash2 />,
      callback: handleDelete,
    }
  ]);
  if (isGroupByDeleted) {
    quickButtons.push({
      title: 'Restore',
      icon: <CornerUpLeft />,
      //icon: 'merge',
      //iconType: 'common',
      callback: handleRestore,
    });
  }

  return (
    <>
      <ListQuickAction
        checked={selectedIds}
        buttons={quickButtons}
        resetSelected={() => setSelectedIds([])}
        visible={selectedIds.length > 0}
      />
      {showModal && (
        <AssignRepModal
          users={allReps}
          show={showModal}
          onClose={() => setShowModal(false)}
          onAssign={handleAssign}
          isAssigning={mutationAssignReps.isLoading}
        />
      )}
      {showUnModal && (
        <UnAssignRepModal
          users={allReps}
          show={showUnModal}
          onClose={() => setShowUnModal(false)}
          onUnAssign={handleUnAssign}
          isUnAssigning={mutationUnAssignReps.isLoading}
        />
      )}
      {showMerge && (
        <CustomerMergeModal
          title={category === 'account' ? 'Merge Account' : 'Merge Contact'}
          isOpen={showMerge}
          onClose={() => setShowMerge(false)}
          defaultItems={itemsList.filter((_item: any) => selectedIds.includes(_item.id))}
          category={category}
          onReload={() => {
            onReload && onReload();
            setSelectedIds([]);
          }}
        />
      )}
    </>
  );
};

export default PageQuickAction;
