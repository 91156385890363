import React, { useEffect, useState } from 'react';
import IPInput from '@base/components/form/ip-input';
import { Plus, Minus } from 'react-feather';

/**
 *
 * @param {*} props
 */
const IPAddress: React.FC<any> = (props: any) => {
  const { className = '', value, onChange, isMultiple = false } = props;

  //state
  const [rows, setRows] = useState(['...']);

  //init rows
  useEffect(() => {
    if (Array.isArray(value)) {
      if (value?.length > 0) {
        if (JSON.stringify(value) !== JSON.stringify(rows)) {
          // let _ips: any[] = [];
          // value?.map((_ele: any) => {
          //   _ips.push(_ele?.ip);
          // });
          setRows(value);
        }
      }
    } else {
      setRows(['...']);
    }
  }, [value]);

  //add more row
  const handleAddMore = () => {
    const newRows = [...rows];
    newRows.push('...');
    setRows(newRows);
    //callback
    onChange && onChange(newRows);
  };

  //remove a row
  const handleRemove = (idx: number) => {
    let tempRows = [...rows];
    tempRows.splice(idx, 1);
    setRows([...tempRows]);
    //callback
    onChange && onChange(tempRows);
  };

  //set value change
  const handleValueChange = (value: string, idx: number) => {
    const newRows = [...rows];
    newRows[idx] = value;
    setRows(newRows);
    //callback
    onChange && onChange(newRows);
  };

  //// console.log('rows', rows);
  //main render
  return (
    <div className={className}>
      {rows.map((item, idx) => {
        return (
          <div key={idx} className="d-flex align-items-center mg-b-5">
            <IPInput value={item} onChange={(value: any) => handleValueChange(value, idx)} />
            {isMultiple && idx !== 0 && (
              <button
                type="button"
                className="d-flex rounded bd wd-30 ht-30 bg-white bd-danger tx-danger mg-l-5"
                onClick={() => handleRemove(idx)}
              >
                <Minus />
              </button>
            )}
            {isMultiple && idx === 0 && (
              <button
                type="button"
                className="d-flex rounded bd wd-30 ht-30 bg-white bd-primary tx-primary mg-l-5"
                onClick={handleAddMore}
              >
                <Plus />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default IPAddress;
