import React from 'react';
import { RadioGroup } from '@base/components/form';

const CustomerTypeRadio: React.FC = (props: any) => {
  return (
    <RadioGroup value={props.value || 'none'} fieldValue="value" fieldLabel="label" {...props} />
  );
};

export default CustomerTypeRadio;
