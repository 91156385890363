import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// import { useQueryClient } from 'react-query';
import { useQueryClient } from '@tanstack/react-query'; //v4
//import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
//import withMiModal from '@base/hooks/hocs/withMiModal';
import withWriteForm from '@base/hooks/hocs/withWriteForm';
import Button from '@base/components/form/button';
//import Tabs from '@base/components/form/tabs';
import { ChevronUp } from 'react-feather';
import Field from '@base/components/hook-form/field';
//import { defaultCurrencySelector } from '@base/recoil/selectors';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { MENU_CUSTOMER_ACCOUNT, MENU_CUSTOMER_CONTACT } from '@base/config/menus';
import {
  //KEY_NAME_CUSTOMER_CURRENCY,
  KEY_NAME_CUSTOMER_CODE,
  KEY_NAME_CUSTOMER_PHOTO,
  KEY_NAME_CUSTOMER_TYPE,
  KEY_NAME_CUSTOMER_CONTACT_TYPE,
  KEY_NAME_CUSTOMER_EMPLOYEE_ROLE,
  KEY_NAME_CUSTOMER_ACCOUNT,
  KEY_NAME_CUSTOMER_PHONES,
  KEY_NAME_CUSTOMER_EMAIL,
  KEY_NAME_CUSTOMER_FAX,
  KEY_NAME_CUSTOMER_DEPARTMENT,
  KEY_NAME_CUSTOMER_POSITION,
  KEY_NAME_CUSTOMER_NAME,
} from '@customer/customer/config/key-names';
import { CUSTOMER_CONTACT_TYPE_EMPLOYEE } from '@customer/customer/config/constants';
import { CustomerContactType } from '@customer/customer/types/enums/customer-enums';
import { ADD_CUSTOMER } from '@customer/customer/services/graphql';
import { useUploadMutation } from '@base/hooks/useFileUploadMutation';
import LazyLoadImage from '@base/components/lazy-load-image';
import imageNoPic from '@base/assets/img/no-pic-m.png';
import { finalizeParams } from './payload';

interface WriteFormProps {
  fullScreen: boolean;
  showCategory?: boolean;
  showCanvas?: boolean;
  customerType?: any;
  account?: any;
  menuApi: string;
  onClose: () => void;
  onReload?: () => void;
  onGoView?: (id: string, category: string) => void;
  onSuccess?: any;
  //with write form
  defaultValues: any;
  fields: any[];
  tabs: any[];
  getParams: any;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const {
    fullScreen,
    showCategory = false,
    showCanvas = false,
    customerType,
    account, //for create employee
    menuApi,
    onClose,
    onReload,
    onGoView,
    onSuccess,
    //withWriteForm
    defaultValues,
    fields,
    tabs,
    getParams,
  } = props;
  //// console.log('config defaultValues', defaultValues);

  //state
  const [isReset, setIsReset] = useState(false);
  //const defaultCurrency: any = useRecoilValue(defaultCurrencySelector);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //set default value when create employee contact
  useEffect(() => {
    if (menuApi === MENU_CUSTOMER_CONTACT && account?.id) {
      setValue(KEY_NAME_CUSTOMER_CONTACT_TYPE, {
        keyName: 'CONTACT_TYPE_EMPLOYEE',
        languageKey: 'options_items__1_225f317c_6001_4468_b33f_a67a8959b370',
        label: 'Employee',
        value: 'CONTACT_TYPE_EMPLOYEE',
      });
      //// console.log('account', account);
      setValue(KEY_NAME_CUSTOMER_ACCOUNT, account);
    }
    if (customerType) {
      setValue(KEY_NAME_CUSTOMER_TYPE, customerType);
    } else {
      setValue(KEY_NAME_CUSTOMER_TYPE, null);
    }
  }, []);

  //watching
  const contactType: any = watch(KEY_NAME_CUSTOMER_CONTACT_TYPE as any);

  //upload
  const mUpload: any = useUploadMutation<BaseMutationResponse>(
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //toast.success('Uploaded successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        //// console.log('mutation error', error);
        toast.error('There was an error during uploading: ' + JSON.parse(error).message);
      },
    },
    (pEvent: ProgressEvent, partsNumber: number, partIndex: number) => {
      // console.log('uploading...');
    },
  );

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>(
    ADD_CUSTOMER,
    'customer_createCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        toast.success('Customer created successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There was an error during creating: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      if (isReset) {
        //re-set queries
        queryClient.removeQueries(['setting_nextId', 'account']); //{ exact: false }
        queryClient.removeQueries(['setting_nextId', 'contact']); //{ exact: false }
        reset();
      } else {
        onGoView &&
          onGoView(mutationAdd.data.id, menuApi === MENU_CUSTOMER_ACCOUNT ? 'account' : 'contact');
        onClose();
      }
      // refecth data
      onReload && onReload();
      if (onSuccess) {
        const newCustomer = {
          id: mutationAdd.data.id,
          value: mutationAdd.data.id,
          category: menuApi === MENU_CUSTOMER_ACCOUNT ? 'CATEGORY_ACCOUNT' : 'CATEGORY_CONTACT',
          name: mutationAdd.variables.customer[KEY_NAME_CUSTOMER_NAME],
          label: mutationAdd.variables.customer[KEY_NAME_CUSTOMER_NAME],
        };
        onSuccess(newCustomer);
      }
    }
  }, [mutationAdd.isSuccess]);

  //upload success
  useEffect(() => {
    if (mUpload.isSuccess) {
      //new customer
      const formData = getValues();
      const configParams = getParams(formData);
      //// console.log('configParams', configParams);
      const newParams = finalizeParams(configParams, menuApi);
      newParams.photo = mUpload.data.url;
      mutationAdd.mutate({ customer: newParams });
    }
  }, [mUpload.isSuccess]);

  //submit form
  const onSubmit = (formData: any) => {
    //upload image first if any
    if (formData[KEY_NAME_CUSTOMER_PHOTO]?.length > 0) {
      //upload to file server
      mUpload.mutate(formData[KEY_NAME_CUSTOMER_PHOTO][0]);
    } else {
      const configParams = getParams(formData);
      const newParams = finalizeParams(configParams, menuApi);
      newParams.photo = '';
      mutationAdd.mutate({ customer: newParams });
    }
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('There was an error during creating: ' + JSON.stringify(errors));
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <div className="d-flex mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                setIsReset(false);
                handleSubmit((data) => onSubmit(data), onError)();
              }}
              disabled={mUpload.isLoading || mutationAdd.isLoading || !isValid}
              loading={mUpload.isLoading || mutationAdd.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsReset(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mUpload.isLoading || mutationAdd.isLoading || !isValid}
                loading={mUpload.isLoading || mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //base info
  const renderBaseInfo = () => {
    const codeField = fields.find((_item) => _item.keyName === KEY_NAME_CUSTOMER_CODE);
    const customerTypeField = fields.find((_item) => _item.keyName === KEY_NAME_CUSTOMER_TYPE);
    const contactTypeField = fields.find(
      (_item) => _item.keyName === KEY_NAME_CUSTOMER_CONTACT_TYPE,
    );
    const photoField = fields.find((_item) => _item.keyName === KEY_NAME_CUSTOMER_PHOTO);
    //for customer type
    let newComponentProps = { ...customerTypeField?.componentProps };
    if (customerType) {
      newComponentProps.isDisabled = true;
    }

    return (
      <>
        {menuApi !== 'marketing_list' ? (
          <div className="d-flex flex-grow-1">
            <div className="d-flex wd-30p">
              {photoField ?
                <Field
                  item={{ ...photoField, columns: 1 }}
                  control={control}
                  errors={errors}
                />
                :
                <LazyLoadImage
                  url={imageNoPic}
                  thumb={imageNoPic}
                  height={75}
                  width={100}
                />
              }
            </div>
            <div className="flex-grow-1">
              <Field
                className="pd-r-5"
                item={{ ...codeField, columns: 1 }}
                control={control}
                //isHidden={codeField.section != tab.value}
                errors={errors}
              />
              {menuApi === MENU_CUSTOMER_ACCOUNT && customerTypeField && (
                <Field
                  className="pd-r-5"
                  item={{ ...customerTypeField, columns: 1, componentProps: newComponentProps }}
                  control={control}
                  errors={errors}
                />
              )}
              {menuApi === MENU_CUSTOMER_CONTACT && contactTypeField && (
                <Field
                  className="pd-r-5"
                  item={{
                    ...contactTypeField,
                    columns: 1,
                    componentProps: {
                      ...contactTypeField?.componentProps,
                      isDisabled: account?.id ? true : false,
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              )}
            </div>
          </div>
        ) : (
          <Field
            item={{ ...customerTypeField, componentProps: newComponentProps }}
            control={control}
            errors={errors}
          />
        )}
      </>
    );
  };

  //More fields
  const renderFields = () => {
    return (
      <div className="form-row">
        {fields.map((_item, _index) => {
          //for contact type == account, contact
          if (
            _item.keyName !== KEY_NAME_CUSTOMER_CODE &&
            _item.keyName !== KEY_NAME_CUSTOMER_PHOTO &&
            _item.keyName !== KEY_NAME_CUSTOMER_EMPLOYEE_ROLE &&
            _item.keyName !== KEY_NAME_CUSTOMER_ACCOUNT &&
            _item.keyName !== KEY_NAME_CUSTOMER_DEPARTMENT &&
            _item.keyName !== KEY_NAME_CUSTOMER_POSITION
          ) {
            let newComponentProps = { ..._item?.componentProps };
            if (
              menuApi === MENU_CUSTOMER_CONTACT &&
              _item.keyName === KEY_NAME_CUSTOMER_CONTACT_TYPE &&
              account?.id
            ) {
              newComponentProps.isDisabled = true;
            }
            if (_item.keyName === KEY_NAME_CUSTOMER_TYPE && customerType) {
              newComponentProps.isDisabled = true;
            }
            if (
              _item.keyName === KEY_NAME_CUSTOMER_PHONES ||
              _item.keyName === KEY_NAME_CUSTOMER_EMAIL ||
              _item.keyName === KEY_NAME_CUSTOMER_FAX
            ) {
              newComponentProps.category =
                menuApi === MENU_CUSTOMER_ACCOUNT ? 'account' : 'contact';
            }
            //not render customer type
            if (menuApi === MENU_CUSTOMER_ACCOUNT && _item.keyName === KEY_NAME_CUSTOMER_TYPE) {
              return;
            }
            //not render customer type
            if (
              menuApi === MENU_CUSTOMER_CONTACT &&
              contactType?.keyName === CustomerContactType[CUSTOMER_CONTACT_TYPE_EMPLOYEE] &&
              _item.keyName === KEY_NAME_CUSTOMER_TYPE
            ) {
              return;
            }
            //not render contact type
            if (
              menuApi === MENU_CUSTOMER_CONTACT &&
              _item.keyName === KEY_NAME_CUSTOMER_CONTACT_TYPE
            ) {
              return;
            }
            return (
              <Field
                key={_index}
                item={{
                  ..._item,
                  componentProps: newComponentProps,
                }}
                control={control}
                errors={errors}
              />
            );
          }
          //for contact type == employee, display employee role + account
          if (
            menuApi === MENU_CUSTOMER_CONTACT &&
            contactType?.keyName === CustomerContactType[CUSTOMER_CONTACT_TYPE_EMPLOYEE]
          ) {
            if (
              _item.keyName === KEY_NAME_CUSTOMER_EMPLOYEE_ROLE ||
              _item.keyName === KEY_NAME_CUSTOMER_ACCOUNT ||
              _item.keyName === KEY_NAME_CUSTOMER_DEPARTMENT ||
              _item.keyName === KEY_NAME_CUSTOMER_POSITION
            ) {
              let newComponentProps = { ..._item?.componentProps };
              if (_item.keyName === KEY_NAME_CUSTOMER_ACCOUNT && account?.id) {
                newComponentProps.isDisabled = true;
              }

              return (
                <Field
                  key={_index}
                  item={{
                    ..._item,
                    componentProps: newComponentProps,
                  }}
                  control={control}
                  errors={errors}
                />
              );
            }
          }
        })}
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  // console.log('form values', getValues()); //get form values
  // console.log('form errors', errors);
  // console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = '100vh'; //modal
  if (showCanvas) {
    if (showCategory) {
      formHeight = 'calc(100vh - 235px)';
    } else {
      formHeight = 'calc(100vh - 160px)';
    }
  } else {
    formHeight = 'calc(100vh - 320px)';
  }
  if (fullScreen) {
    formHeight = 'calc(100vh - 165px)';
  }

  return (
    <div className="pos-relative">
      <div className="bd-b pd-y-10">
        <div className="d-flex justify-content-end pd-b-0 pd-r-20 pd-l-20">
          <span>
            <span className="tx-danger">*</span>
            <span>is required field</span>
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="pd-15 scroll-box"
          style={{ minHeight: '250px', maxHeight: formHeight, overflowX: 'hidden' }}
        >
          {fields.length > 0 && renderBaseInfo()}
          {renderFields()}
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withWriteForm(WriteForm);
//export default withMiModal(withWriteForm(WriteForm));
