import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { DESC } from '@base/config/constant';
import { default as configFields } from '@desk/ticket/config/view-field';
import { buildListSchema } from '@base/utils/helpers/schema';
import { useTickets } from '@desk/ticket/services/ticket-service';
import { ListContainer } from '@base/components/list';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms';
import displayLayoutConfig from '@desk/ticket/config/display-layout';
import PageBody from './body';

const CustomerTicket: React.FC<any> = (props) => {
  const {
    menuSourceId, //customer id
    className = 'pd-y-20',
  } = props;
  //state
  const layoutMenu = `desk_ticket`; //for list
  const [{ isDesktop, device, layout }] = useRecoilState(deviceAtom);
  const [viewingFields, setViewingFields] = useState<any>([]);
  const [listFilter, setListFilter] = useState<any>({
    paging: {
      page: 1,
      size: 15,
      totalPage: 1,
    },
    sort: {
      field: 'createdAt',
      orderBy: DESC,
    },
    query: `customer=\"${menuSourceId}\"`,
  });

  /*===================================== HOOK ===================================== */
  //get pagelayout
  let { data: listLayoutData, isLoading: isLayoutLoading } = usePageLayoutByMenu(
    layoutMenu,
    'list',
  );

  //get list data
  let listQuerySchema =
    listLayoutData?.data && viewingFields.length > 0
      ? buildListSchema({ fields: viewingFields, configFields }) + ' content'
      : '';
  //// console.log('listQuerySchema', listQuerySchema);

  let filtersQuery: any = {
    sort: listFilter.sort,
    paging: { page: listFilter.paging.page, size: listFilter.paging.size },
    query: listFilter.query,
  };

  //// console.log('filter query', filtersQuery);
  const { data: postResult, isFetching, refetch } = useTickets(filtersQuery, listQuerySchema);
  //// console.log('postResult', postResult);

  //set viewing columns
  useEffect(() => {
    if (listLayoutData?.data) {
      const newItems: any[] = [];
      listLayoutData?.data.map((_ele: any) => {
        if (_ele.isDefault) {
          newItems.push({ ..._ele, isViewing: _ele.isDefault });
        }
      });
      setViewingFields(newItems);
    }
  }, [listLayoutData]);

  //set paging
  useEffect(() => {
    if (postResult?.paging) {
      let newFilter = {
        ...listFilter,
        paging: {
          ...listFilter.paging,
          totalPage: postResult.paging.totalPage,
          totalItems: postResult.paging.totalItems,
          itemPerPage: postResult.paging.itemPerPage,
        },
      };
      setListFilter(newFilter);
    }
  }, [postResult]);

  //body
  const PageBodyMemo = useMemo(() => {
    return (
      <PageBody
        isSplitMode={false}
        category={'ticket'}
        //isFetching={false}
        fields={viewingFields || []}
        itemsList={postResult?.data || []}
        filter={listFilter}
        setFilter={setListFilter}
      />
    );
  }, [postResult, viewingFields, listFilter]);

  return (
    <div className={classNames('pos-relative', className)}>
      <ListContainer
        isLoading={isLayoutLoading || isFetching}
        isSplitMode={false}
        isDesktop={isDesktop}
      >
        <BaseLayoutDisplay
          displayConfig={displayLayoutConfig}
          device={device}
          layout={layout}
          form="list"
          body={PageBodyMemo}
        />
      </ListContainer>
    </div>
  );
};

export default CustomerTicket;
