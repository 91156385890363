import { useEffect, useMemo, useState } from 'react';
import BillToShipTo from '@customer/customer/containers/bill-to-ship-to';

/**
 * Bill To and Ship To
 * @param props
 * @returns
 */
const AddressTo: React.FC<any> = (props: any) => {
  const { value, onChange, customer, category } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>(null);

  //init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue({ billTo: null, shipTo: null });
    }
  }, [value]);

  //bill address change
  const handleValueChange = (keyAttribute: string, newValue: any) => {
    const newAddress = { ...selectedValue };
    newAddress[keyAttribute] = newValue;
    setSelectedValue(newAddress);
    //callback
    onChange && onChange(newAddress);
  };

  //Bill to DOM
  const AddressBillTo = useMemo(() => {
    return (
      <>
        <BillToShipTo
          type={'billTo'}
          id={customer?.id}
          category={customer?.category}
          defaultOptions={customer?.addresses || null}
          value={selectedValue?.billTo}
          onChange={(newAddr: any) => handleValueChange('billTo', newAddr)}
        />
      </>
    );
  }, [customer, selectedValue]);

  //ship to address DOM
  const AddressShipTo = useMemo(() => {
    return (
      <>
        <BillToShipTo
          type={'shipTo'}
          id={customer?.id}
          category={customer?.category}
          defaultOptions={customer?.addresses || null}
          value={selectedValue?.shipTo}
          onChange={(newAddr: any) => handleValueChange('shipTo', newAddr)}
        />
      </>
    );
  }, [customer, selectedValue]);

  //main render
  return (
    <>
      <div className="pos-relative">
        {!customer && (
          <>
            <span className="badge badge-dark mg-r-5 tx-normal tx-13">Address to</span>
            <span>Empty</span>
            <span> (Please select customer to continue...)</span>
          </>
        )}
        {customer && customer?.id && (
          <>
            <div>{AddressBillTo}</div>
            <div>{AddressShipTo}</div>
          </>
        )}
      </div>
    </>
  );
};

export default AddressTo;
