import { Input } from "@base/components/form";
import { useEffect, useState } from "react";

interface SalesCommissionProps {
  value?: any;
  onChange?: any;
}

const SalesCommission = (props: SalesCommissionProps) => {
  const { value, onChange } = props;
  //state
  const [curValue, setCurValue] = useState<any>({ from: 0, to: 0 });

  //init cur value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(curValue)) {
        setCurValue(value);
      }
    } else {
      setCurValue({ from: 0, to: 0 });
    }
  }, [value]);

  //value change
  const handleValueChange = (keyName: string, keyValue: number) => {
    const newValue = { ...curValue };
    newValue[keyName] = keyValue;
    setCurValue(newValue);
    //callback
    onChange && onChange(newValue);
  }

  return (
    <div className="d-flex align-items-center">
      <Input
        type='number'
        rightIcon='%'
        value={curValue.from}
        onChange={(val: number) => handleValueChange('from', val)}
      />
      <span className="mg-x-5">~</span>
      <Input
        type='number'
        rightIcon='%'
        value={curValue.to}
        onChange={(val: number) => handleValueChange('to', val)}
      />
    </div>
  );
}

export default SalesCommission;