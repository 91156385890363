import { t } from 'i18next';
import * as keyNames from '@customer/customer/config/key-names';
import * as commonComponents from '@base/config/view-field/components';
import validators from '@base/utils/validation/field-validator'; //all validate functions
import {
  LABEL_VALUE_CUSTOM_ANNI,
  LABEL_VALUE_CUSTOM_WEB,
  LABEL_VALUE_OTHER,
  RECEIPT_TYPE_OPTIONS,
  SEND_MODE_OPTIONS,
} from '@customer/customer/config/constants';
import * as components from './components';
import { IFieldConfig } from '@base/types/interfaces/common';

const viewConfig: IFieldConfig = {
  // ================================ SUMMARY FIELDS ====================================
  [keyNames.KEY_NAME_CUSTOMER_NAME]: {
    component: commonComponents.TextView,
    schema: 'name',
    getRecoilStateValue: (value: any) => {
      return value || '';
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_CODE]: {
    component: commonComponents.TextView,
    schema: 'code',
  },
  [keyNames.KEY_NAME_CUSTOMER_EMAIL]: {
    component: commonComponents.EmailView,
    componentProps: {
      isMultiple: true,
    },
    schema: `emails {
      id
      label {
        languageKey
        label
      }
      labelValue
      email
    }`,
    validate: {
      emails: validators.emails, //'emails' --> error key
    },
    getMutationValue: (value: any) => {
      const newValue = value?.map((_ele: any, _index: number) => ({
        id: _ele?.id || '',
        label: _ele.label?.label || LABEL_VALUE_OTHER,
        labelValue: _ele.labelValue,
        email: _ele.email,
        //primary: _ele.primary
      }));
      return { [keyNames.KEY_NAME_CUSTOMER_EMAIL]: newValue };
    },
    getRecoilStateValue: (value: any) => {
      return value || null;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_PHONES]: {
    component: commonComponents.PhoneView,
    componentProps: {
      isMultiple: true,
    },
    schema: `phones {
      id
      label {
        languageKey
        label
      }
      labelValue
      country
      phoneNumber
      extension
    }`,
    getMutationValue: (value: any) => {
      const newValue = value
        ?.filter((_ele: any) => _ele.phoneNumber.length > 0)
        ?.map((_ele: any) => ({
          id: _ele?.id || '',
          label: _ele.label?.label || LABEL_VALUE_OTHER,
          labelValue: _ele.labelValue,
          country: _ele.country,
          phoneNumber: _ele.phoneNumber,
          extension: _ele.extension,
          //primary: _ele.primary
        }));
      return { [keyNames.KEY_NAME_CUSTOMER_PHONES]: newValue };
    },
    getRecoilStateValue: (value: any) => {
      return value || null;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_MOBILE]: {
    component: commonComponents.MobileView,
    componentProps: {
      isMultiple: true,
    },
    schema: `mobiles {
      id
      label {
        languageKey
        label
      }
      labelValue
      country
      mobileNumber
    }`,
    getMutationValue: (value: any) => {
      const newValue = value
        ?.filter((_ele: any) => _ele.mobileNumber.length > 0)
        ?.map((_ele: any) => ({
          id: _ele?.id || '',
          label: _ele.label?.label || LABEL_VALUE_OTHER,
          labelValue: _ele.labelValue,
          country: _ele.country,
          mobileNumber: _ele.mobileNumber,
        }));
      return { [keyNames.KEY_NAME_CUSTOMER_MOBILE]: newValue };
    },
    getRecoilStateValue: (value: any) => {
      return value || null;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_TYPE]: {
    component: commonComponents.DataSourceView,
    componentProps: {
      sourceKey: 'customer_category',
      sourceType: 'field',
      single: true,
    },
    schema: `type {
      languageKey
      keyName
    }`,
    getValueView: (value: any) => {
      return t(value?.languageKey ?? '');
    },
    getValueEdit: (value: any) => {
      return { ...value, value: value?.keyName, label: t(value?.languageKey ?? '') };
    },
    getMutationValue: (value: any) => {
      //basic api params
      return value?.keyName || 'TYPE_NONE';
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES]: {
    schema: `industries {
      id
      languageKey
    }`,
    component: commonComponents.DataSourceView,
    componentProps: {
      sourceKey: 'industry',
      sourceType: 'field', //group
      single: false,
    },
    getValueView: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => t(item?.languageKey ?? '')).join(', ')
        : '';
    },
    getValueEdit: (value: any) => {
      return value && value?.length > 0
        ? value.map((item: any) => ({ ...item, value: item?.id, label: t(item?.languageKey) }))
        : [];
    },
    getMutationValue: (value: any) => {
      const newValue = value?.map((_ele: any) => ({
        id: _ele?.id,
        name: t(_ele?.languageKey),
      }));
      return { [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_RATING]: {
    component: commonComponents.RankingView,
    componentProps: {
      sourceKey: 'ranking',
      sourceType: 'field',
      single: true,
    },
    schema: `rating {
      id
      languageKey
    }`,
    getValueView: (value: any) => {
      return { ...value, value: value?.id, label: value?.languageKey ? t(value?.languageKey) : '' };
    },
    getValueEdit: (value: any) => {
      return value ? { ...value, value: value?.id, label: t(value?.languageKey) } : '';
    },
    getMutationValue: (value: any) => {
      //// console.log('value rating', value);
      const newValue = {
        id: value?.id,
        name: value?.label,
      };
      return { [keyNames.KEY_NAME_CUSTOMER_RATING]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_PREFERRED]: {
    component: commonComponents.CheckboxSingle,
    schema: `preferred`,
  },
  //TODO
  [keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION]: {
    component: commonComponents.CurrencyInputView,
    componentProps: {
      showSymbol: true,
    },
    schema: `saleCommission`,
    getValue: (value: any) => {
      return {
        currency: value.currency?.code || '',
        value: value.saleCommission || 0,
      };
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION]: value.value };
    },
  },
  //TODO - may refer to discount / loyalty
  [keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD]: {
    component: commonComponents.CurrencyInputView,
    componentProps: {
      showSymbol: true,
    },
    schema: `referralReward`,
    getValue: (value: any) => {
      return {
        currency: value.currency?.code || '',
        value: value.referralReward || 0,
      };
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD]: value.value };
    },
  },
  //TODO
  [keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT]: {
    component: commonComponents.Selectbox, //products list
    schema: `saleProducts  {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value || '';
    },
  },
  //TODO
  [keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT]: {
    component: commonComponents.Selectbox, //products list
    schema: `relatedProducts  {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value || '';
    },
  },

  // ================================ DETAIL FIELDS ====================================
  [keyNames.KEY_NAME_CUSTOMER_FAX]: {
    component: commonComponents.FaxView,
    componentProps: {
      isMultiple: true,
    },
    schema: `faxes {
      id
      label {
        languageKey
        label
      }
      labelValue
      country
      faxNumber
    }`,
    getMutationValue: (value: any) => {
      const newValue = value
        ?.filter((_ele: any) => _ele.faxNumber.length > 0)
        ?.map((_ele: any) => ({
          id: _ele?.id || '',
          label: _ele.label?.label || LABEL_VALUE_OTHER,
          labelValue: _ele.labelValue,
          country: _ele.country,
          faxNumber: _ele.faxNumber,
          //extension: _ele.extension,
          //primary: _ele.primary
        }));
      return { [keyNames.KEY_NAME_CUSTOMER_FAX]: newValue };
    },
    // getRecoilStateValue: (value: any) => {
    //   return value || null;
    // }
  },
  //TODO
  [keyNames.KEY_NAME_CUSTOMER_PAYMENT_TERM]: {
    component: components.PaymentTermView,
    componentProps: {
      single: true,
    },
    schema: `paymentTerms {
      name
      numberDays
      isEdit
    }`,
    getValueView: (value: any) => {
      return value?.name || '';
    },
    getValueEdit: (value: any) => {
      return value ? { ...value, value: value.id, label: value.name } : null;
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_NAME_CUSTOMER_PAYMENT_TERM]: {
          name: value.name,
          numberDays: value.numberDays,
        },
      };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_TAX]: {
    component: commonComponents.DataSourceView,
    componentProps: {
      sourceType: 'setting',
      sourceKey: 'tax',
      sourceMenu: 'price_discount',
      single: true,
    },
    schema: `tax {
      id
      name
      percentage
      customers {
        id
        name
      }
      isUsed
      isDefault
    }`,
    getValue: (viewData: any) => {
      return viewData.tax ? { sourceId: viewData.id, ...viewData.tax } : { sourceId: viewData.id };
    },
    getValueView: (value: any) => {
      return value?.name || '';
    },
    getValueEdit: (value: any) => {
      //// console.log('view value', value);
      return value?.id ? { ...value, value: value.id, label: value.name } : value;
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_TAX]: { id: value.id, name: value.name } };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_CHARGE_LATE_FEE]: {
    component: commonComponents.Switch,
    schema: keyNames.KEY_NAME_CUSTOMER_CHARGE_LATE_FEE,
  },
  [keyNames.KEY_NAME_CUSTOMER_CREATED_BY]: {
    component: commonComponents.TextView,
    schema: `createdBy {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value?.name;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_CREATED_AT]: {
    component: commonComponents.DateTimeView,
    schema: 'createdAt',
  },
  [keyNames.KEY_NAME_CUSTOMER_UPDATED_BY]: {
    component: null,
    schema: `updatedBy {
      id
      name
    }`,
    getValueView: (value: any) => {
      return value?.name;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_UPDATED_AT]: {
    component: commonComponents.DateTimeView,
    schema: 'updatedAt',
  },
  [keyNames.KEY_NAME_CUSTOMER_ADDRESSES]: {
    //component: commonComponents.AddressView,
    component: components.BillShipAddressView,
    componentProps: {
      isMultiple: true,
    },
    schema: `
      shipAddresses {
        id
        country {
          isoCode2
          country
        }
        zipcode
        addrState
        city
        street
      }
      billAddresses {
        id
        country {
          isoCode2
          country
        }
        zipcode
        addrState
        city
        street
      }
    `,
    getValue: (value: any) => {
      //// console.log('customer value', value);
      return {
        shipping: value?.shipAddresses?.length > 0 ? value.shipAddresses[0] : null,
        billing: value?.billAddresses?.length > 0 ? value.billAddresses[0] : null,
      };
    },
    getValueView: (value: any) => {
      //// console.log('view address', value);
      return value;
    },
    getValueEdit: (value: any) => {
      //// console.log('edit address', value);
      return value;
    },
    getMutationValue: (value: any) => {
      //separate shipping and billing address
      const newShillingValue = {
        id: value.shipping?.id,
        country: value.shipping.country?.isoCode2,
        addrState: value.shipping.state,
        city: value.shipping.city,
        street: value.shipping.street,
        zipcode: value.shipping.zipcode,
      };
      const newBillingValue = {
        id: value.billing?.id,
        country: value.billing.country?.isoCode2,
        addrState: value.billing.state,
        city: value.billing.city,
        street: value.billing.street,
        zipcode: value.billing.zipcode,
      };
      return { shipAddresses: [newShillingValue], billAddresses: [newBillingValue] };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_WEBSITES]: {
    component: commonComponents.WebsiteView,
    componentProps: {
      isMultiple: true,
    },
    schema: `websites {
      id
      label {
        languageKey
        label
      }
      labelValue
      protocol
      website
    }`,
    getMutationValue: (value: any) => {
      const newValue = value.map((_ele: any) => ({
        id: _ele?.id,
        label: _ele.label?.label || LABEL_VALUE_CUSTOM_WEB,
        labelValue: _ele.labelValue,
        website: _ele.website,
        protocol: _ele.protocol,
      }));
      return { [keyNames.KEY_NAME_CUSTOMER_WEBSITES]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO]: {
    component: null, //commonComponents?.AssignRepView, //IN RIGHT SIDE
    // componentProps: {
    //   placement: 'top',
    // },
    schema: `assignTo {
      id
      name
    }`,
  },
  [keyNames.KEY_NAME_CUSTOMER_CURRENCY]: {
    component: commonComponents.CurrencyView,
    schema: `currency {
      code
      currencyName
    }`,
    getValueView: (value: any) => {
      return value ? [value.code, value.currencyName].join('-') : '';
    },
    getValueEdit: (value: any) => {
      return value
        ? {
          ...value,
          value: value.code,
          label: value.currencyName,
        }
        : null;
    },
    getMutationValue: (value: any) => {
      const newValue = value.code;
      return { [keyNames.KEY_NAME_CUSTOMER_CURRENCY]: newValue };
    },
  },

  [keyNames.KEY_NAME_CUSTOMER_EMPLOYEES_NUMBER]: {
    component: null,
    schema: 'employeesNumber',
    componentProps: {
      type: 'number',
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_EMPLOYEES_NUMBER]: parseInt(value) };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE]: {
    component: commonComponents.CurrencyInputView,
    componentProps: {
      showSymbol: true,
    },
    schema: keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE,
    getValue: (value: any) => {
      return {
        currency: value[keyNames.KEY_NAME_CUSTOMER_CURRENCY]?.code || '',
        value: value[keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE] || 0,
      };
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE]: value.value };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT]: {
    component: null,
    schema: keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT,
  },
  [keyNames.KEY_NAME_CUSTOMER_BUSINESS_NUMBER]: {
    component: null,
    schema: keyNames.KEY_NAME_CUSTOMER_BUSINESS_NUMBER,
  },
  [keyNames.KEY_NAME_CUSTOMER_DESCRIPTION]: {
    component: commonComponents.TextAreaView,
    schema: keyNames.KEY_NAME_CUSTOMER_DESCRIPTION,
    getValueView: (value: any) => {
      return value ?? '';
    },
    getValueEdit: (value: any) => {
      return value ?? '';
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE]: {
    schema: `contactType {
      languageKey
      keyName
    }`,
    component: commonComponents.DataSourceView,
    componentProps: {
      sourceKey: 'contact_type',
      sourceType: 'field',
      single: true,
    },
    getValueView: (value: any) => {
      return t(value?.languageKey ?? '');
    },
    getValueEdit: (value: any) => {
      return { ...value, value: value?.keyName, label: t(value?.languageKey ?? '') };
    },
    getMutationValue: (value: any) => {
      //basic api params
      return value?.keyName || 'CONTACT_TYPE_NONE';
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE]: {
    schema: `employeeRole {
      languageKey
      keyName
    }`,
    component: commonComponents.DataSourceView,
    componentProps: {
      sourceKey: 'employee_role',
      sourceType: 'field',
      single: true,
    },
    getValueView: (value: any) => {
      return t(value?.languageKey ?? '');
    },
    getValueEdit: (value: any) => {
      return { ...value, value: value?.keyName, label: t(value?.languageKey ?? '') };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_GENDER]: {
    schema: `gender {
      languageKey
      label
    }`,
    component: components.GenderView,
    getValueView: (value: any) => {
      return t(value?.languageKey ?? '');
    },
    getMutationValue: (value: any) => {
      //basic api params
      return value?.label;
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_POSITION]: {
    schema: keyNames.KEY_NAME_CUSTOMER_POSITION,
    component: commonComponents.TextView,
  },
  [keyNames.KEY_NAME_CUSTOMER_DEPARTMENT]: {
    schema: keyNames.KEY_NAME_CUSTOMER_DEPARTMENT,
    component: commonComponents.TextView,
  },
  [keyNames.KEY_NAME_CUSTOMER_ANNIVERSARIES]: {
    component: components.AnniversaryView,
    schema: `anniversaries {
      id
      label {
        languageKey
        label
      }
      labelValue
      anniversary
    }`,
    getMutationValue: (value: any) => {
      const newValue = value?.map((_ele: any) => ({
        id: _ele?.id,
        label: _ele.label?.label || LABEL_VALUE_CUSTOM_ANNI,
        labelValue: _ele.labelValue,
        anniversary: _ele.anniversary,
      }));
      return { [keyNames.KEY_NAME_CUSTOMER_ANNIVERSARIES]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_PARENT_ACCOUNT]: {
    schema: `parent {
      id
      code
      name
      category
    }`,
    component: components.CustomerView,
    componentProps: {
      category: 'account',
      single: true,
    },
    getValueView: (value: any) => {
      return value?.name || '';
    },
    getValueEdit: (value: any) => {
      return value ? { ...value, value: value.id, label: value.name } : null;
    },
    getMutationValue: (value: any) => {
      const newValue = {
        id: value?.id,
        name: value?.name,
      };
      return { [keyNames.KEY_NAME_CUSTOMER_PARENT_ACCOUNT]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_ACCOUNT]: {
    schema: `account {
      id
      code
      name
      category
    }`,
    component: components.CustomerView,
    componentProps: {
      category: 'account',
      single: true,
    },
    getValueView: (value: any) => {
      return value?.name || '';
    },
    getValueEdit: (value: any) => {
      return value ? { ...value, value: value.id, label: value.name } : null;
    },
    getMutationValue: (value: any) => {
      const newValue = {
        id: value?.id,
        name: value?.name,
      };
      return { [keyNames.KEY_NAME_CUSTOMER_ACCOUNT]: newValue };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_SLA]: {
    component: commonComponents.MenuSourceSelectView,
    componentProps: {
      single: true,
      sourceKey: 'sla',
      sourceMenu: 'desk',
      optionLabel: 'sla',
      optionValue: 'sla',
    },
    schema: `serviceLevelAgreement`,
    getValueView: (value: any) => {
      return value.sla ? value.sla : value;
    },
    getMutationValue: (value: any) => {
      return { [keyNames.KEY_NAME_CUSTOMER_SLA]: value.sla };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_OPENING_BALANCE]: {
    component: commonComponents.CurrencyInputView,
    componentProps: {
      showSymbol: true,
    },
    schema: `openingBalance {
      amount
      currency
    }`,
    getValue: (value: any) => {
      return {
        currency: value[keyNames.KEY_NAME_CUSTOMER_CURRENCY]?.code || '',
        value: value[keyNames.KEY_NAME_CUSTOMER_OPENING_BALANCE]?.amount || 0,
      };
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_NAME_CUSTOMER_OPENING_BALANCE]: {
          currency: value.currency,
          amount: value.value,
        },
      };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_CREDIT_LIMIT]: {
    component: commonComponents.CurrencyInputView,
    componentProps: {
      showSymbol: true,
    },
    schema: `creditLimit {
      amount
      currency
    }`,
    getValue: (value: any) => {
      return {
        currency: value[keyNames.KEY_NAME_CUSTOMER_CURRENCY]?.code || '',
        value: value[keyNames.KEY_NAME_CUSTOMER_CREDIT_LIMIT]?.amount || 0,
      };
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_NAME_CUSTOMER_CREDIT_LIMIT]: {
          currency: value.currency,
          amount: value.value,
        },
      };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_RECEIPT_TYPE]: {
    component: commonComponents.Selectbox,
    componentProps: {
      isMultiple: false,
      isSearchable: false,
      options: RECEIPT_TYPE_OPTIONS,
      fieldValue: 'value',
      fieldLabel: 'label',
    },
    schema: `receiptType`,
    getValueView: (value: any) => {
      return value ? t(value?.languageKey) : '';
    },
    getValueEdit: (value: any) => {
      return RECEIPT_TYPE_OPTIONS.find((_ele: any) => _ele.value === value?.keyName) || null;
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_NAME_CUSTOMER_RECEIPT_TYPE]: value?.value || 'RECEIPT_TYPE_NONE',
      };
    },
  },
  [keyNames.KEY_NAME_CUSTOMER_SEND_MODE]: {
    component: components.SendModeView,
    schema: `sendMode`,
    getValueView: (value: any) => {
      return value ? SEND_MODE_OPTIONS.find((_ele: any) => _ele.value === value)?.label || '' : '';
    },
    getMutationValue: (value: any) => {
      return {
        [keyNames.KEY_NAME_CUSTOMER_SEND_MODE]: value || 'SEND_MODE_EMAIL',
      };
    },
  },
  // [keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT]: {
  //   component: null,
  //   schema: [keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT],
  // },
  // [keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT]: {
  //   component: null,
  //   schema: keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_AMOUNT_RECEIVED]: {
  //   component: null,
  //   schema: keyNames.KEY_NAME_CUSTOMER_AMOUNT_RECEIVED,
  // },

  // [keyNames.KEY_NAME_CUSTOMER_CONNECTED_IPS]: {
  //   component: commonComponents.IpAddressView,
  //   schema: keyNames.KEY_NAME_CUSTOMER_CONNECTED_IPS,
  //   // getMutationValue: (value: any) => {
  //   //   //// console.log('value ips', value);
  //   //   const newValue = value?.map((_ele: any) => ({
  //   //     id: _ele?.id,
  //   //     ip: _ele.ip
  //   //   }));
  //   //   return { [keyNames.KEY_NAME_CUSTOMER_CONNECTED_IPS]: newValue };
  //   // }
  // },
  // [keyNames.KEY_NAME_CUSTOMER_LEAD_SOURCES]: {
  //   component: components.LeadSourceView,
  //   schema: `leadSources {
  //     id
  //     languageKey
  //   }`,
  //   getValueView: (value: any) => {
  //     return value && value?.length > 0
  //       ? value?.map((item: any) => t(item?.languageKey)).join(', ')
  //       : '';
  //   },
  //   getValueEdit: (value: any) => {
  //     return value && value?.length > 0
  //       ? value.map((item: any) => ({
  //         ...item,
  //         title: t(item?.languageKey),
  //       }))
  //       : [];
  //   },
  //   getMutationValue: (value: any) => {
  //     const newValue = value?.map((_ele: any) => ({
  //       id: _ele?.id,
  //       name: t(_ele?.languageKey)
  //     }));
  //     return { [keyNames.KEY_NAME_CUSTOMER_LEAD_SOURCES]: newValue };
  //   }
  // },
  // [keyNames.KEY_NAME_CUSTOMER_RELATED_EMPLOYEES]: {
  //   component: components.RelatedEmployeeView,
  //   schema: `relatedEmployees {
  //     id
  //     code
  //     name
  //     category
  //     type {
  //       languageKey
  //       keyName
  //     }
  //     state {
  //       languageKey
  //       keyName
  //     }
  //     photo
  //   }`,
  //   getValueView: (value: any) => {
  //     return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
  //   },
  //   getMutationValue: (value: any) => {
  //     const newValue = value?.map((_ele: any) => ({
  //       id: _ele?.id,
  //       name: _ele?.name
  //     }));
  //     return { [keyNames.KEY_NAME_CUSTOMER_RELATED_EMPLOYEES]: newValue };
  //   }
  // },
  // [keyNames.KEY_NAME_CUSTOMER_RELATED_ACCOUNTS]: {
  //   component: components.RelatedAccountView,
  //   schema: '',
  //   getValueView: (value: any) => {
  //     return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
  //   },
  //   getMutationValue: (value: any) => {
  //     const newValue = value?.map((_ele: any) => ({
  //       id: _ele?.id,
  //       name: _ele?.name
  //     }));
  //     return { [keyNames.KEY_NAME_CUSTOMER_RELATED_ACCOUNTS]: newValue };
  //   }
  // },
  // [keyNames.KEY_NAME_CUSTOMER_RELATED_CONTACTS]: {
  //   component: components.RelatedContactView,
  //   schema: '',
  //   getValueView: (value: any) => {
  //     return value && value?.length > 0 ? value.map((item: any) => item?.name).join(', ') : '';
  //   },
  //   getMutationValue: (value: any) => {
  //     const newValue = value?.map((_ele: any) => ({
  //       id: _ele?.id,
  //       name: _ele?.name
  //     }));
  //     return { [keyNames.KEY_NAME_CUSTOMER_RELATED_CONTACTS]: newValue };
  //   }
  // },
  // [keyNames.KEY_NAME_CUSTOMER_CONVERSION_DATE]: {
  //   schema: keyNames.KEY_NAME_CUSTOMER_CONVERSION_DATE,
  //   component: commonComponents.DateTimeView,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_JOB]: {
  //   schema: keyNames.KEY_NAME_CUSTOMER_JOB,
  //   component: null,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_COMPANY]: {
  //   schema: keyNames.KEY_NAME_CUSTOMER_COMPANY,
  //   component: null,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_HOBBY]: {
  //   schema: keyNames.KEY_NAME_CUSTOMER_HOBBY,
  //   component: null,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_STATE]: {
  //   component: null,
  //   schema: `state {
  //     languageKey
  //     keyName
  //   }`,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_ACTIVE]: {
  //   component: commonComponents.Switch,
  //   schema: keyNames.KEY_NAME_CUSTOMER_ACTIVE,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_GROUPS]: {
  //   component: commonComponents.DataSourceView,
  //   componentProps: {
  //     sourceKey: 'customer_group',
  //     sourceType: 'group',
  //     single: false,
  //   },
  //   schema: `groups {
  //     id
  //     languageKey
  //   }`,
  //   getValueView: (value: any) => {
  //     return value && value?.length > 0
  //       ? value.map((item: any) => t(item?.languageKey ?? '')).join(', ')
  //       : '';
  //   },
  //   getValueEdit: (value: any) => {
  //     return value ? value.map((item: any) => ({ id: item?.id, title: t(item?.languageKey) })) : [];
  //   },
  //   getMutationValue: (value: any) => {
  //     const newValue = value?.map(
  //       (_ele: any) => ({ id: _ele.id, name: _ele.title })
  //     );
  //     return { [keyNames.KEY_NAME_CUSTOMER_GROUPS]: newValue };
  //   },
  // },
  // [keyNames.KEY_NAME_CUSTOMER_LAST_ACTIVITY_DATE]: {
  //   component: commonComponents.DateTimeView,
  //   schema: keyNames.KEY_NAME_CUSTOMER_LAST_ACTIVITY_DATE,
  // },
};

export default viewConfig;
