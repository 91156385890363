import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components/form';
import { MinusSquare, PlusSquare } from 'react-feather';
import { useRecoilValue } from 'recoil';
import { defaultCountrySelector, countriesSelector } from '@base/recoil/selectors';

import { default as Item } from './address';

const Address: React.FC<any> = (props: any) => {
  const {
    isMultiple = false,
    cType = '', // The mode is display option tag
    className = '',
    value = [],
    onChange, // Callback when change data
  } = props;

  const { t } = useTranslation();

  const countries = useRecoilValue(countriesSelector);
  const defaultCountry = useRecoilValue<any>(defaultCountrySelector);

  const defaultValue = {
    // label: {
    //   languageKey: 'Primary',
    //   label: LABEL_VALUE_PRIMARY,
    // },
    // labelValue: '',
    country: defaultCountry, //country, isoCode2
    zipcode: '',
    state: '',
    city: '',
    street: '',
    //primary: false,
  };
  //state
  const [addresses, setAddresses] = useState<any[]>([]);
  //// console.log('addresses', addresses);

  //init data
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(addresses)) {
            setAddresses(value);
          }
        } else {
          setAddresses([_.cloneDeep(defaultValue)]);
        }
      } else {
        //single
        setAddresses([value]);
      }
    } else {
      setAddresses([_.cloneDeep(defaultValue)]);
    }
  }, [value]);

  //new default address
  const handeAdd = () => {
    const newAddresses = [...addresses];
    newAddresses.push(_.cloneDeep(defaultValue));
    setAddresses(newAddresses);
    //callback
    onChange && onChange(isMultiple ? newAddresses : newAddresses[0]);
  };

  //remove new address
  const handleRemove = (index: number) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
    //callback
    onChange && onChange(isMultiple ? newAddresses : newAddresses[0]);
  };

  //value change
  const handleValueChange = (index: number, keyValue: any) => {
    const newAddresses = [...addresses];
    newAddresses[index] = keyValue;
    // if (newAddresses.length > 0) {
    //   newAddresses[0].primary = true;
    // }
    setAddresses(newAddresses);
    //callback
    onChange && onChange(isMultiple ? newAddresses : newAddresses[0]);
  };

  return (
    <div>
      {addresses?.map((address, index) => (
        <div className="addresses-relative" key={index}>
          <Item
            isSingle={!isMultiple}
            className={className}
            cType={cType}
            value={address}
            onChange={(newAddress: any): void => handleValueChange(index, newAddress)}
            countries={countries}
          />
          <div className="form-row mg-b-5">
            {index != 0 && (
              <button
                type="button"
                className="btn btn-xs add-mode-btn text-danger pd-x-5 pd-y-0"
                onClick={() => handleRemove(index)}
              >
                <MinusSquare />
                <SpanLang keyLang="Delete" />
              </button>
            )}
            {index != addresses.length - 1 && (
              <div className="d-flex justify-content-center pd-b-10" style={{ width: '100%' }}>
                <div className="wd-50" style={{ border: 'gray dashed 0.1px' }}></div>
              </div>
            )}
            {index === addresses.length - 1 && !!isMultiple && (
              <button
                type="button"
                className="btn btn-xs add-mode-btn text-primary pd-x-5 pd-y-0"
                onClick={handeAdd}
              >
                <PlusSquare />
                <SpanLang keyLang="Add" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Address;
