import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SpanLang } from '@base/components/form';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
// import { phonesSelector, defaultPhoneSelector } from '@base/recoil/selectors';
import { phonesAtom } from '@base/recoil/atoms';
import {
  GENERAL_LABEL_OPTIONS,
  LABEL_VALUE_CUSTOM,
  LABEL_VALUE_PRIMARY,
  PERSONAL_LABEL_OPTIONS,
} from '@customer/customer/config/constants';

const Phone = (props: any) => {
  const { className = '', category, value, isMultiple = false, onChange } = props;
  const { t } = useTranslation();

  const defaultValue = {
    label: {
      languageKey: 'Primary',
      label: LABEL_VALUE_PRIMARY,
    },
    labelValue: '',
    country: '', //phone code
    phoneNumber: '',
    extension: '',
    primary: false,
  };

  //state
  const [phoneValue, setPhoneValue] = useState<any>(defaultValue);
  const phoneCodes = useRecoilValue(phonesAtom);

  const PHONE_OPTIONS = PERSONAL_LABEL_OPTIONS; //category === 'account' ? GENERAL_LABEL_OPTIONS : PERSONAL_LABEL_OPTIONS;

  //init data
  useEffect(() => {
    //// console.log('phone init value', value);
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(phoneValue)) {
        setPhoneValue(value);
      }
    } else {
      setPhoneValue(defaultValue);
    }
  }, [value]);

  //value change
  const handleValueChange = (keyAttribute: string, keyValue: any) => {
    const newPhoneValue = { ...phoneValue };
    newPhoneValue[keyAttribute] = keyValue;
    setPhoneValue(newPhoneValue);
    //callback
    onChange && onChange(newPhoneValue);
  };

  //// console.log('phoneValue', phoneValue);
  //// console.log('phoneCodes', phoneCodes);
  return (
    <div className={className}>
      <div className="phone-relative">
        <div className="form-row mg-b-10">
          <div className="col-3">
            <Select
              isSearchable={false}
              value={phoneCodes[phoneCodes.findIndex((opt) => opt.value === phoneValue.country)]}
              options={phoneCodes}
              onChange={(newPhoneCode: any) => handleValueChange('country', newPhoneCode.value)}
            //isLoading={isLoading}
            />
          </div>

          <div
            className={classNames({
              'col-9': !isMultiple,
              'col-5': isMultiple,
            })}
          >
            <input
              placeholder={'phone number'}
              className="form-control ht-38"
              type="number"
              value={phoneValue?.phoneNumber || ''}
              onChange={(e: any) => handleValueChange('phoneNumber', e.target.value)}
            />
          </div>
          <div className={classNames('col-4', { 'd-none': !isMultiple })}>
            <Select
              isSearchable={false}
              value={
                PHONE_OPTIONS[
                PHONE_OPTIONS.findIndex((opt) => opt.value === phoneValue.label.label)
                ]
              }
              options={PHONE_OPTIONS}
              onChange={(option: any) =>
                handleValueChange('label', {
                  ...option,
                  label: option.value,
                  languageKey: option.label,
                })
              }
            />
          </div>
        </div>
        {/* {Boolean(phoneValue?.label?.label === '3' && isMultiple) && ( */}
        <div className="form-row mg-b-10">
          <div className="col-md-6">
            <input
              name="extension"
              placeholder={'extension'}
              className="form-control ht-38"
              type="number"
              value={phoneValue?.extension || ''}
              onChange={(e: any) => handleValueChange('extension', e.target.value)}
            />
          </div>
          {Boolean(phoneValue?.label?.label === LABEL_VALUE_CUSTOM && isMultiple) && (
            <div className="col-md-6">
              <input
                placeholder={'custom label value'}
                className="form-control ht-38"
                type="text"
                value={phoneValue?.labelValue || ''}
                onChange={(e: any) => handleValueChange('labelValue', e.target.value)}
              />
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default Phone;
