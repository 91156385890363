import React, { useState } from 'react';
import { GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY } from '@base/services/graphql/setting';
import { BaseResponse } from '@base/types/interfaces/response';
import { TreeListSelect } from '@base/components/form';
import { useCustomersAssign } from '@customer/customer/services/list-service';
import usePost from '@base/hooks/usePost';

interface ICustomerSelectProps {
  category: string;
  type?: string;
  accountId?: string;
  single: boolean;
  value: any;
  onChange: any;
  //cacheOptions?: any;
  //onCache?: any;
}
const LIMIT = 15;

const CustomerSelect: React.FC<ICustomerSelectProps> = (props: ICustomerSelectProps) => {
  const {
    category, //'' ==> '' (= all), 'account', 'contact', 'rating'
    type = '', //'customer','partner', ...
    accountId,
    single = false,
    value,
    onChange,
  } = props;

  //state
  const [listFilter, setListFilter] = useState<any>({
    filter: {
      paging: {
        page: 1,
        size: LIMIT,
      },
      //keyword: ''
      query: '',
    },
  });

  //HOOK - get assign customer contacts
  const { data: customerData, isFetching: isListLoading } = useCustomersAssign(listFilter);

  //get rating data
  const { data: ratingData, isFetching: isRatingLoading } = usePost<BaseResponse<any>>(
    ['setting_selectionFieldItems', category],
    GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
    { keyName: 'ranking' },
    { enabled: category === 'rating' },
  );

  //get customer types
  const { data: custTypeData, isFetching: isTypeLoading } = usePost<BaseResponse<any>>(
    ['setting_selectionFieldItems'],
    GET_SELECTION_FIELD_ITEMS_BY_SINGLE_KEY,
    { keyName: 'customer_category' },
    { enabled: category != 'rating' },
  );

  //main render
  return (
    <TreeListSelect
      //filters
      fetchTree={null}
      formatTreeNode={null}
      category={category}
      categoryItems={category === 'rating' ? ratingData?.results : custTypeData?.results}
      isCategoryLoading={isRatingLoading || isTypeLoading}
      //list
      listFilter={listFilter}
      onFilterChange={setListFilter}
      listData={{
        items: customerData?.results || [],
        attrs: {
          total: customerData?.paging?.totalItems || 0,
          maxpage: customerData?.paging?.totalPage || 1,
          page: customerData?.paging?.currentPage || 1,
        },
        keyword: '', //TODO
      }}
      isListLoading={isListLoading}
      single={single}
      value={value || []}
      onChange={onChange}
    />
  );
};

export default CustomerSelect;
