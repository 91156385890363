import React, { useCallback, useEffect, useMemo, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import { Button, Checkbox, RadioGroup } from '@base/components/form';
import { ArrowLeft, ArrowRight, Search } from 'react-feather';
import { Select } from '@base/components/form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import classNames from 'classnames';
import {
  KEY_NAME_CUSTOMER_ADDRESSES,
  KEY_NAME_CUSTOMER_CREATED_AT,
  KEY_NAME_CUSTOMER_EMAIL,
  KEY_NAME_CUSTOMER_MOBILE,
  KEY_NAME_CUSTOMER_NAME,
  KEY_NAME_CUSTOMER_PHONES,
} from '@customer/customer/config/key-names';
import {
  CUSTOMER_CATEGORY_ACCOUNT,
  LABEL_VALUE_PRIMARY,
} from '@customer/customer/config/constants';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { formatAddress } from '@customer/main/utils';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { toast } from 'react-toastify';
import { UPDATE_CUSTOMER } from '@customer/customer/services/graphql';
import { confirmAlert, NoData, SpanLang } from '@base/components';
import { useCustomerDuplicates, useCustomersByField } from '@customer/customer/services/list-service';

import DuplicateTable from './duplicate-table';
import { getParams } from './payload';
import MergeTable from './merge-table';

const MERGE_OPTIONS = [
  { value: 'account', title: 'Account', label: 'Merge Account', icon: 'm_customer_account', iconType: 'icon' },
  { value: 'contact', title: 'Contact', label: 'Merge Contact', icon: 'contacts', iconType: 'icon' },
];

const TABS = [
  {
    value: 1,
    label: 'Find Duplicates',
    keys: [],
  },
  {
    value: 2,
    label: 'Select the Master',
    keys: [],
  },
];

interface ICustomerMergeModalProps {
  category: string;
  customer?: any; //for view case: finding duplicates with viewing customer
  defaultItems: any[];
  isOpen: boolean;
  onClose: () => void;
  onReload?: () => void;
}

const CustomerMergeModal: React.FC<ICustomerMergeModalProps> = (props) => {
  const {
    onClose,
    category, //'all', 'account', 'contact'
    customer, //default master
    defaultItems = [],
    onReload,
  } = props;
  //const { t, i18n } = useTranslation();

  //state
  const [searchCriteria, setSearchCriteria] = useState<any>(null);
  const [dupFilter, setDupFilter] = useState<any>({
    filter: {
      query: '',
      paging: {
        page: 1,
        size: 100, //all
      },
    },
  });
  const [cusFilter, setCusFilter] = useState<any>({
    filter: {
      query: '',
      paging: {
        page: 1,
        size: 100, //all
      },
      sort: { field: KEY_NAME_CUSTOMER_CREATED_AT, orderBy: 1 }, //ASC
    },
  });
  const [isMergeAll, setIsMergeAll] = useState<boolean>(false);
  const [mergeMaster, setMergeMaster] = useState<any>(null);
  const [mergeItems, setMergeItems] = useState<any>([]);
  const [mergeIds, setMergeIds] = useState<any>([]);
  const [dupOptions, setDupOptions] = useState<any>([]);
  const [dupItems, setDupItems] = useState<any>([]);
  const [selectedDup, setSelectedDup] = useState<any>(null);
  const [activeCate, setActiveCate] = useState<any>(category === 'all' ? 'account' : category); //
  const [criteriaOptions, setCriteriaOptions] = useState<any>([]);
  const [formStep, setFormStep] = useState(TABS[0].value);

  //init creiteria option
  useEffect(() => {
    if (activeCate === 'account') {
      let newOptions = [
        { value: 'CRITERIA_NAME', label: 'Account Name' },
        { value: 'CRITERIA_EMAIL', label: 'Account Email' },
        { value: 'CRITERIA_PHONE', label: 'Account Phone' },
      ];
      setCriteriaOptions(newOptions);
    }
    if (activeCate === 'contact') {
      let newOptions = [
        { value: 'CRITERIA_NAME', label: 'Contact Name' },
        { value: 'CRITERIA_EMAIL', label: 'Contact Email' },
        { value: 'CRITERIA_PHONE', label: 'Contact Phone' },
        { value: 'CRITERIA_MOBILE', label: 'Contact Mobile' },
      ];
      setCriteriaOptions(newOptions);
    }
  }, [activeCate]);

  //init array
  useEffect(() => {
    if (defaultItems) {
      if (JSON.stringify(defaultItems) !== JSON.stringify(mergeItems)) {
        //const newItems = items.map((_ele: any) => ({ ..._ele, mergeFields: [] }));
        setMergeItems(defaultItems);
        setMergeMaster(defaultItems[0]);
        //go to merge step
        setFormStep(2);
      }
    }
  }, [defaultItems]);

  //get duplicates
  const { data: duplicatePost, isFetching: isDupFetching } = useCustomerDuplicates(dupFilter);

  //get duplicated accounts/contacts
  const { data: customerPost, isFetching: isCusFetching } = useCustomersByField(cusFilter);

  //mutation merge
  const mMerge: any = useMutationPost<BaseMutationResponse>(
    UPDATE_CUSTOMER,
    'customer_updateCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        toast.success('Records Merged Successfully');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        console.log('mutation error', error);
        toast.error('There is an error during merging: ' + JSON.parse(error).message);
      },
    },
  );

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mMerge.isSuccess) {
      // refecth data
      onReload && onReload();
      onClose();
    }
  }, [mMerge.isSuccess]);

  //set duplicate options
  useEffect(() => {
    //console.log('duplicatePost', duplicatePost);
    if (duplicatePost?.data) {
      const newOptions: any[] = [];
      duplicatePost.data.map((_ele: any) => {
        newOptions.push({
          label: `${_ele.name} (${_ele.count})`,
          value: _ele.name
        });
      })
      setDupOptions(newOptions);
    } else {
      setDupOptions([]);
    }
  }, [duplicatePost]);

  //set duplicate items
  useEffect(() => {
    if (customerPost?.data) {
      setDupItems(customerPost.data);
    } else {
      setDupItems([]);
    }
  }, [customerPost]);

  //duplicate selected change --> reset items
  useEffect(() => {
    if (selectedDup) {
      //set filter for select duplicate items
      const newFilter = { ...cusFilter };
      let query = `category=${activeCate === CUSTOMER_CATEGORY_ACCOUNT ? 1 : 2}`;
      switch (searchCriteria.value) {
        case 'CRITERIA_NAME':
          query += ` name=\"${selectedDup.value}\"`;
          break;
        case 'CRITERIA_EMAIL':
          query += ` email=\"${selectedDup.value}\"`;
          break;
        case 'CRITERIA_PHONE':
          query += ` phone=\"${selectedDup.value}\"`;
          break;
        case 'CRITERIA_MOBILE':
          query += ` mobile=\"${selectedDup.value}\"`;
          break;
      }
      newFilter.filter.query = query;
      setCusFilter(newFilter);

      //set duplicate items in cache
      if (customerPost?.data) {
        setDupItems(customerPost.data);
      }
    }
  }, [selectedDup]);

  //get merge items from merge ids
  useEffect(() => {
    if (defaultItems.length === 0) {
      if (mergeIds.length > 0) {
        const newMergeItems: any = [];
        dupItems.map((_ele: any) => {
          if (mergeIds.includes(_ele.id)) {
            newMergeItems.push(_ele);
          }
        })
        setMergeItems(newMergeItems);
        setMergeMaster(newMergeItems[0]);
      } else {
        setMergeItems([]);
      }
    }
  }, [mergeIds]);

  //reset state
  const reset = () => {
    setSelectedDup(null);
    setDupOptions([]);
    setDupItems([]);
    setMergeIds([]);
    setMergeMaster(null);
  }

  //go next
  const handleGoNext = () => {
    setFormStep((cur) => cur + 1);
  }

  //set filter for search
  const handleSearch = () => {
    if (searchCriteria) {
      const newFilter = { ...dupFilter };
      let query = `category=${activeCate === CUSTOMER_CATEGORY_ACCOUNT ? 1 : 2}`;
      query += ` criteria="${searchCriteria.value}"`;
      if (customer) {
        const primaryEmail = customer?.emails?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
        const primaryPhone = customer?.phones?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
        const primaryMobile = customer?.mobiles?.find((_ele: any) => _ele.label.label === 'LABEL_PRIMARY');
        //merge from a specific customer, find duplicates
        switch (searchCriteria.value) {
          case 'CRITERIA_NAME':
            query += ` name=\"${customer.name}\"`;
            break;
          case 'CRITERIA_EMAIL':
            query += ` email=\"${primaryEmail?.email || ''}\"`;
            break;
          case 'CRITERIA_PHONE':
            query += ` phone=\"${primaryPhone?.phoneNumber || ''}\"`;
            break;
          case 'CRITERIA_MOBILE':
            query += ` mobile=\"${primaryMobile?.mobile || ''}\"`;
            break;
        }
      }
      newFilter.filter.query = query;
      setDupFilter(newFilter);
    }
  };

  //start merge
  const handleMerge = () => {
    confirmAlert({
      title: 'Merge Customer',
      message: 'Merge is an irreversible process! Are you sure you want to continue merge ?',
      buttons: [
        {
          label: 'No',
          className: 'btn-secondary',
        },
        {
          label: 'Yes',
          className: 'btn-primary',
          onClick: () => {
            const params: any = getParams(mergeMaster, mergeItems, isMergeAll);
            mMerge.mutate({ customer: params });
          },
        },
      ],
    });
  };

  //render steps
  const renderFindStep = () => {
    return (
      <div className={classNames('pos-relative', { 'd-none': formStep !== 1 })}>
        <RadioGroup
          disabled={customer?.id}
          options={MERGE_OPTIONS}
          value={MERGE_OPTIONS.find((_ele: any) => _ele.value === activeCate)}
          onChange={(newValue: any) => {
            setActiveCate(newValue.value);
            setSearchCriteria(null);
            reset();
          }}
        />
        <div className="form-group">
          <label className="form-item-title">Select Criteria</label>
          <div className="d-flex align-items-center">
            <Select
              isSearchable={false}
              className="wd-300-f"
              options={criteriaOptions}
              value={searchCriteria}
              onChange={(option: any) => {
                setSearchCriteria(option);
                reset();
              }}
            />
            <Button
              color="primary"
              className="mg-l-10"
              onClick={handleSearch}
              loading={isDupFetching}
            >
              <Search className="mg-r-5" /> Search
            </Button>
          </div>
        </div>
        <div className="form-group">
          <label className="form-item-title">
            Duplicates
          </label>
          <Select
            isClearable
            isSearchable={false}
            isLoading={isDupFetching}
            options={dupOptions}
            value={selectedDup}
            onChange={(item: any) => setSelectedDup(item)}
          />
        </div>
        <div className="form-group">
          <label className="form-item-title">Select the duplicates to merge</label>
          <DuplicateTable
            category={activeCate}
            isLoading={isCusFetching}
            items={dupItems}
            selectedIds={mergeIds}
            setSelectedIds={setMergeIds}
          />
        </div>
      </div>
    );
  }

  //merge step
  const renderMasterStep = () => {
    return (
      <div className="form-group">
        <label className="form-item-title">Select Master {activeCate === 'account' ? 'Account' : 'Contact'}</label>
        <div className="custom-control custom-checkbox mg-b-10">
          <input
            type="checkbox"
            className="custom-control-input"
            id="mergeAll"
            checked={isMergeAll}
            onChange={() => setIsMergeAll(!isMergeAll)}
          />
          <label className="custom-control-label" htmlFor="mergeAll">
            Merge all of following
          </label>
        </div>
        <MergeTable
          category={activeCate}
          isMergeAll={isMergeAll}
          value={mergeItems}
          master={mergeMaster}
          onMasterChange={(item: any) => setMergeMaster(item)}
          onChange={(items: any) => setMergeItems(items)}
        />
      </div>
    );
  }

  //step tabs
  const renderFormSteps = () => {
    return (
      <div className="w-100">
        <ul className="steps steps-justified steps-tab w-100">
          {TABS.map((_item: any, index: number) => (
            <li
              key={index}
              className={classNames('step-item', {
                complete: _item.value < formStep,
                active: _item.value == formStep,
                disabed: _item.value > formStep,
              })}
            >
              <button
                type="button"
                className="btn step-link rounded-0"
              // onClick={(e) => setFormStep(_item.value)}
              >
                <span className="step-number">{_item.value}</span>
                <span className="step-title">{_item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-x-15 pd-y-10 bd-t">
        <Button color="link" className="link-03" style={{ textDecoration: 'underline' }} onClick={onClose}>
          Close
        </Button>
        <div className="mg-l-auto">
          {formStep > 1 && defaultItems.length === 0 && (
            <button
              type="button"
              className="btn btn-light mg-r-5"
              onClick={() => setFormStep((cur) => cur - 1)}
            >
              <ArrowLeft />
              Previous
            </button>
          )}
          {formStep < 2 && (
            <button
              disabled={mergeIds.length < 2}
              type="button"
              className="btn btn-primary"
              onClick={handleGoNext}
            >
              Next
              <ArrowRight />
            </button>
          )}
          {formStep === 2 &&
            <Button
              color="success"
              onClick={handleMerge}
              loading={mMerge.isLoading}
              disabled={mMerge.isLoading || mergeItems.length === 1}
            >
              Merge
            </Button>
          }
        </div>
      </div>
    );
  };

  //console.log('mergeIds', mergeIds);
  //console.log('mergeItems', mergeItems);

  return (
    <div className='pos-relative'>
      {defaultItems.length === 0 && renderFormSteps()}
      <div className="pd-15 scroll-box" style={{ maxHeight: 'calc(100vh - 200px)' }}>
        {formStep >= 1 && defaultItems.length === 0 && renderFindStep()}
        {formStep === 2 && renderMasterStep()}
      </div>
      {renderFooter()}
    </div>
  );
};

export default withMiModal(CustomerMergeModal);
