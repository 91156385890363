import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { MinusSquare, PlusSquare } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { SpanLang } from '@base/components/form';
import { defaultPhoneAtom } from '@base/recoil/atoms';
import { useRecoilValue } from 'recoil';
import { LABEL_VALUE_CUSTOM, LABEL_VALUE_PRIMARY } from '@customer/customer/config/constants';
import { default as Item } from './phone';

const Phone: React.FC<any> = (props: any) => {
  const { className = '', value, category = 'account', isMultiple = false, onChange } = props;

  const { t } = useTranslation();

  const defaultPhone = useRecoilValue(defaultPhoneAtom);

  const defaultValue = {
    label: {
      languageKey: 'Primary',
      label: LABEL_VALUE_PRIMARY,
    },
    labelValue: '',
    country: defaultPhone?.value, //phone code
    phoneNumber: '',
    extension: '',
    primary: false,
  };

  //state
  const [phones, setPhones] = useState<any[]>([]);

  //init value
  useEffect(() => {
    //// console.log('PHONE INIT VALUE', value);
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(phones)) {
            setPhones(value);
          }
        } else {
          setPhones([_.cloneDeep(defaultValue)]);
        }
      } else {
        setPhones([value]);
      }
    } else {
      setPhones([_.cloneDeep(defaultValue)]);
    }
  }, [value]);

  //add new
  const handeAdd = () => {
    const newPhones = [...phones];
    const newDefaultValue = {
      ...defaultValue,
      label: {
        languageKey: 'Custom',
        label: LABEL_VALUE_CUSTOM,
      },
    };
    newPhones.push(_.cloneDeep(newDefaultValue));
    setPhones(newPhones);
    //callback
    onChange && onChange(isMultiple ? newPhones : newPhones[0]);
  };

  //remove
  const handleRemove = (index: number) => {
    const newPhones = [...phones];
    newPhones.splice(index, 1);
    let existPrimary = false;
    newPhones.map((_ele: any) => {
      if (_ele.label.label === LABEL_VALUE_PRIMARY) {
        existPrimary = true;
      }
    });
    if (!existPrimary) {
      newPhones[0].label = { languageKey: 'Primary', label: LABEL_VALUE_PRIMARY };
      //newPhones[0].primary = true;
    }
    setPhones(newPhones);
    //callback
    onChange && onChange(isMultiple ? newPhones : newPhones[0]);
  };

  //value change
  const handleValueChange = (index: number, keyValue: any) => {
    const newPhones = [...phones];
    newPhones[index] = keyValue;
    //// console.log('keyValue', keyValue);
    if (keyValue.label.label === LABEL_VALUE_PRIMARY) {
      //primary
      newPhones[index].primary = true;
      //reset to other to false
      for (let i = 0; i < newPhones.length; i++) {
        if (i !== index) {
          if (newPhones[i].label.label === LABEL_VALUE_PRIMARY) {
            newPhones[i].label = { languageKey: 'Custom', label: LABEL_VALUE_CUSTOM };
            //newPhones[i].primary = false;
          }
        }
      }
    }
    setPhones(newPhones);
    //callback
    onChange && onChange(isMultiple ? newPhones : newPhones[0]);
  };

  return (
    <>
      {phones?.map((phone: any, index: number) => (
        <div key={index}>
          <Item
            isMultiple={isMultiple}
            className={className}
            category={category}
            value={phone}
            onChange={(newPhone: any) => handleValueChange(index, newPhone)}
          />
          <div className="form-row mg-b-5">
            {isMultiple && phones.length > 1 && index != 0 && (
              <button
                type="button"
                className="btn btn-xs add-mode-btn text-danger pd-x-5 pd-y-0"
                onClick={() => handleRemove(index)}
              >
                <MinusSquare />
                <SpanLang keyLang="Delete" />
              </button>
            )}
            {isMultiple && index != phones.length - 1 && (
              <div className="d-flex justify-content-center pd-b-10" style={{ width: '100%' }}>
                <div className="wd-50" style={{ border: 'gray dashed 0.1px' }}></div>
              </div>
            )}
            {index === phones.length - 1 && isMultiple && (
              <button
                type="button"
                className="btn btn-xs add-mode-btn text-primary pd-x-5 pd-y-0"
                onClick={handeAdd}
              >
                <PlusSquare />
                <SpanLang keyLang="Add" />
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default Phone;
