import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AssignCustomerContainer } from '@base/containers';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import useMutationPost from '@base/hooks/useMutationPost';
import { MENU_CUSTOMER } from '@base/config/menus';
import { useCustomersAssign } from '@customer/customer/services/list-service';
import {
  CUSTOMER_CATEGORY_ACCOUNT,
  CUSTOMER_CATEGORY_CONTACT,
  CUSTOMER_CATEGORY_CONTACT_NUM,
} from '@customer/customer/config/constants';
import {
  ADD_CUSTOMER_EMPLOYEE_CONTACT_ASSIGN,
  DELETE_CUSTOMER_EMPLOYEE_CONTACT_DELETE,
} from '@customer/customer/services/graphql';

const LIMIT = 100;

interface IAssignCustomerProps {
  placement?: string;
  id: string;
  name?: string;
  category?: string;
}

/**
 *
 * @param {*} props
 * @returns
 */
const AssignCustomer: React.FC<IAssignCustomerProps> = (props: IAssignCustomerProps) => {
  const { id, name, category, placement } = props;
  //state
  const [contactItems, setContactItems] = useState<any>([]);
  const [newContact, setNewContact] = useState<any>(null);

  //get assign customer contacts
  const paramsGet = {
    filter: {
      query: `category=${CUSTOMER_CATEGORY_CONTACT_NUM} contactType=1 accounts=\"${id}\"`,
      paging: {
        page: 1,
        size: LIMIT,
      },
    },
  };
  const { data: contactsData, isLoading, refetch } = useCustomersAssign(paramsGet);

  //create mutation
  const mutationCreate: any = useMutationPost<BaseMutationResponse>(
    ADD_CUSTOMER_EMPLOYEE_CONTACT_ASSIGN,
    'customer_updateCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Create sales order successfully!');
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('Create assign contact failed: ' + JSON.parse(error).message);
      },
    },
  );

  //delete mutation
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    DELETE_CUSTOMER_EMPLOYEE_CONTACT_DELETE,
    'customer_deleteCustomer',
    {
      onSuccess: (res: any) => {
        //fine
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        toast.error('There is an error during adding contact: ' + JSON.parse(error).message);
      },
    },
  );

  //init contact items
  useEffect(() => {
    if (contactsData?.results) {
      setContactItems(contactsData.results);
    } else {
      setContactItems([]);
    }
  }, [contactsData]);

  //check success
  useEffect(() => {
    if (mutationCreate.isSuccess) {
      //refetch();
      if (newContact) {
        const newItems = [...contactItems];
        newItems.push(newContact);
        setContactItems(newItems);
      }
    }
  }, [mutationCreate.isSuccess]);

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      //refetch();
      const newItems = [...contactItems];
      const fIdx = newItems.findIndex((_ele: any) => _ele.id === mutationDelete.data.ids[0]);
      if (fIdx > -1) {
        newItems.splice(fIdx, 1);
        setContactItems(newItems);
      }
    }
  }, [mutationDelete.isSuccess]);

  //add new assign
  const handleCreateAssign = (item: any) => {
    //check exist before add
    const foundIdx = contactItems.findIndex((_ele: any) => _ele.id === item.id) || -1;
    if (foundIdx > -1) return;
    //state
    setNewContact({ ...item, account: { id, name } });
    //save to db
    const params: any = {
      customer: {
        id: item.id,
        account: {
          id: id,
          name: name,
        },
      },
    };
    mutationCreate.mutate(params);
  };

  //delete customer contact
  const handleDeleteAssign = (item: any, cb: any) => {
    let params: any = {
      ids: [item.id]
    };
    mutationDelete.mutate(params);
    //callback
    cb && cb();
  };

  return (
    <AssignCustomerContainer
      items={contactItems}
      placement={placement}
      isLoading={isLoading || mutationCreate.isLoading || mutationDelete.isLoading}
      menuSource={MENU_CUSTOMER}
      menuSourceId={id}
      menuSourceName={name}
      menuTab={category}
      menuType={CUSTOMER_CATEGORY_CONTACT} // = employee contact
      onAssign={handleCreateAssign}
      onDelete={handleDeleteAssign}
      onCallback={refetch}
    />
  );
};

AssignCustomer.defaultProps = {
  id: '',
  name: '',
  category: CUSTOMER_CATEGORY_ACCOUNT,
  placement: 'right',
};

export default AssignCustomer;
