import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Button from '@base/components/form/button';
import { ChevronUp } from 'react-feather';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import Loading from '@base/components/loading';
//import { MARKETING_CAMPAIGN_ADD_NEW } from '@marketing/campaign/services/graphql';

import { finaliseParams } from './payload';
import Item from './item';

interface WriteFormProps {
  onClose: () => void;
  onReload?: () => void;
}

/**
 * write form - use react-hook-form
 * @param {*} props
 * @returns
 */
const WriteForm: React.FC<WriteFormProps> = (props: WriteFormProps) => {
  const { onClose, onReload } = props;
  //// console.log('config defaultValues', defaultValues);

  //state
  const [isNext, setIsNext] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      items: [{ name: '', website: null }],
    },
    criteriaMode: 'firstError',
    mode: 'onChange',
  });

  //create mutation
  const mutationAdd: any = useMutationPost<BaseMutationResponse>('', 'marketing_createCampaign', {
    onSuccess: (data: any, variables: any, context: any) => {
      //// console.log('after save', context);
      toast.success('Created Campaign Marketing successfully!');
      // refecth data
      //onReload && onReload();
    },
    onError: (error: any, variables: any, context: any) => {
      // An error happened!
      //// console.log('mutation error', error);
      toast.error('Created Campaign Marketing failed: ' + JSON.parse(error).message);
    },
  });

  //check success
  useEffect(() => {
    //// console.log('<<< completed useEffect >>>', mutationAdd);
    if (mutationAdd.isSuccess) {
      if (isNext) {
        //apply
      } else {
        onClose();
      }
      // refecth data
      //onReload && onReload();
    }
  }, [mutationAdd.isSuccess]);

  //watching

  //submit form
  const onSubmit = (formData: any) => {
    // const configParams = getParams(formData); //generate api params by fields
    // let defaultParams = finaliseParams(configParams);
    // mutationAdd.mutate({ campaign: defaultParams });
  };

  //when submit error, call this
  const onError = (errors: any, e: any) => {
    //// console.log('error', errors, e);
    toast.error('Created Campaign Marketing failed: ' + JSON.stringify(errors));
  };

  /** ============================== HANDLER ==================================== */
  // //step ...
  // const eventRuleIsRequired = (data: any) => {
  //   if (!data) return false;
  //   let isValid = true;
  //   //TODO
  //   return isValid;
  // }

  //======================== RENDER FORM ========================//
  //form view
  const renderForm = () => {
    return (
      <>
        <Controller
          name={'items'}
          control={control}
          //rules={{ required: true }}
          render={({ field: { value, onChange } }) => <Item value={value} onChange={onChange} />}
        />
      </>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="d-flex pd-15 bd-t">
        <div className="mg-l-auto">
          <button type="button" className="btn btn-light mg-r-5" onClick={() => onClose()}>
            Close
          </button>
          <div className="btn-group dropup">
            <Button
              color="success"
              onClick={() => {
                setIsNext(false);
                handleSubmit((data) => onSubmit(data), onError)();
              }}
              disabled={mutationAdd.isLoading || !isValid}
              loading={mutationAdd.isLoading}
              name="Save"
            />
            <button
              type="button"
              className="btn btn-success dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <ChevronUp />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsNext(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Apply"
              />
              <Button
                className="dropdown-item"
                color="primary"
                onClick={() => {
                  setIsNext(true);
                  handleSubmit((data) => onSubmit(data), onError)();
                }}
                disabled={mutationAdd.isLoading || !isValid}
                loading={mutationAdd.isLoading}
                name="Save and Create New"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  //======================== Debug ========================//
  // console.log('form values', watch()); //get form values when inputing
  //// console.log('form api values', getParams(getValues())); //get form values when inputing
  //// console.log('form errors', errors);
  //// console.log('form fields', fields); //All fields from pagelayout
  //======================== End Debug ========================//

  //render
  let formHeight = 'calc(100vh - 135px)';

  return (
    <div className="pos-relative wd-450">
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
        <div
          className="pos-relative scroll-box pd-10"
          style={{ minHeight: '300px', height: formHeight, overflowY: 'auto', overflowX: 'hidden' }}
        >
          <React.Suspense fallback={<Loading />}>{renderForm()}</React.Suspense>
        </div>
        {renderFooter()}
      </form>
    </div>
  );
};

export default withMiModal(WriteForm);
