import React, { useState } from 'react';
import WriteForm from '@customer/customer/containers/write-form';
import withMiModal from '@base/hooks/hocs/withMiModal';
import Tabs from '@base/components/form/tabs';
import { MENU_CUSTOMER } from '@base/config/menus';

const TABS = [
  { value: 'account', title: 'Account', description: 'New Customer Account', icon: 'm_customer_account', iconType: 'icon' },
  { value: 'contact', title: 'Contact', description: 'New Customer Contact', icon: 'contacts', iconType: 'icon' },
];

const WriteCustomerFormModal: React.FC<any> = (props) => {
  //state
  const [activeTab, setActiveTab] = useState<any>(TABS[0]);
  return (
    <div className='pos-relative'>
      <Tabs
        className='pd-b-0-f'
        tabs={TABS}
        tab={activeTab}
        onChange={(newTab: any) => setActiveTab(newTab)}
      />
      <WriteForm
        showCategory={true}
        menuApi={`${MENU_CUSTOMER}_${activeTab.value}`}
        {...props}
      />
    </div>
  );
}

export default withMiModal(WriteCustomerFormModal);