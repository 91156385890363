import React, { useEffect, useState } from 'react';
import { PlusSquare, MinusSquare } from 'react-feather';
import i18next from 'i18next';
import { Select, SpanLang } from '@base/components/form';
import { DatePicker } from '@base/components/date-picker';
import {
  ANNIVERSARY_LABEL_OPTIONS,
  LABEL_VALUE_BIRTHDAY,
  LABEL_VALUE_CUSTOM_ANNI,
} from '@customer/customer/config/constants';

/**
 *
 * @param {*} props
 */
const Anniversary: React.FC = (props: any) => {
  const { isMultiple = true, value, onChange } = props;

  //initial
  const defaultRow = {
    label: {
      label: LABEL_VALUE_BIRTHDAY,
      languageKey: 'Birthday',
    },
    labelValue: '',
    anniversary: '',
  };
  //state
  const [rows, setRows] = useState<any[]>([defaultRow]);

  //init rows
  useEffect(() => {
    //// console.log('init value', value);
    if (value) {
      if (Array.isArray(value) && value?.length > 0) {
        if (JSON.stringify(value) !== JSON.stringify(rows)) {
          setRows(value);
        }
      }
    } else {
      setRows([defaultRow]);
    }
  }, [value]);

  //add more email
  const handleAddMore = () => {
    let newRows = [...rows, defaultRow];
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //remove email
  const handleRemove = (i: number) => {
    let newRows = [...rows];
    newRows.splice(i, 1);
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //value change
  const handleValueChange = (index: number, keyAttribute: string, keyValue: any) => {
    const newRows = [...rows];
    newRows[index][keyAttribute] = keyValue;
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //main render
  return (
    <div>
      {rows.map((row, idx) => {
        return (
          <div key={idx} className="pos-relative">
            <div className="form-row mg-b-5">
              <div className="col-md-8 mg-b-5">
                <DatePicker
                  selected={row.anniversary ? new Date(row.anniversary) : new Date()}
                  onChange={(date: Date) => {
                    handleValueChange(idx, 'anniversary', date.toISOString());
                  }}
                />
              </div>
              <div className="col-md-4">
                <Select
                  isSearchable={false}
                  //getOptionLabel={(option: any) => <SpanLang keyLang={option.title} />}
                  //getOptionValue={(option: any) => option.id}
                  outSide={true}
                  options={ANNIVERSARY_LABEL_OPTIONS}
                  onChange={(option: any) =>
                    handleValueChange(idx, 'label', {
                      label: option.value,
                      languageKey: option.label,
                    })
                  }
                  value={
                    row.label
                      ? ANNIVERSARY_LABEL_OPTIONS[
                          ANNIVERSARY_LABEL_OPTIONS.findIndex(
                            (opt: any) => opt.value === row.label?.label,
                          )
                        ]
                      : null
                  }
                />
              </div>
            </div>

            {row.label?.label === LABEL_VALUE_CUSTOM_ANNI && (
              <div className="form-row mg-b-5">
                <div className="col-md-12">
                  <input
                    //name="label_value"
                    placeholder={i18next.t('description')}
                    className="form-control ht-38"
                    type="text"
                    value={row.labelValue}
                    onChange={(e: any) => handleValueChange(idx, 'labelValue', e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="form-row mg-b-5">
              {idx !== 0 && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-danger pd-x-5 pd-y-0"
                  onClick={() => handleRemove(idx)}
                >
                  <MinusSquare />
                  <SpanLang keyLang="Delete" />
                </button>
              )}
              {idx != rows.length - 1 && (
                <div className="d-flex justify-content-center mg-y-10" style={{ width: '100%' }}>
                  <div className="wd-50" style={{ border: 'gray dashed 0.1px' }}></div>
                </div>
              )}
              {idx === rows.length - 1 && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-primary pd-x-5 pd-y-0"
                  onClick={handleAddMore}
                >
                  <PlusSquare />
                  <SpanLang keyLang="Add" />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Anniversary;
