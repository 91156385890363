import { Button, Input } from "@base/components/form";
import Website from "@customer/customer/components/website";
import { Fragment, useEffect, useState } from "react";

const Item: React.FC<any> = (props) => {
  const { value = [], onChange } = props;

  //state
  const defaultItem = {
    name: '',
    website: null
  };
  const [items, setItems] = useState<any>([defaultItem]);

  //init value
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(items)) {
        setItems(value);
      }
    } else {
      setItems([defaultItem]);
    }
  }, [value]);

  //new item
  const handleAdd = () => {
    const newItems = [...items];
    newItems.push(defaultItem);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  }

  //remove
  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  }

  //value change
  const handleValueChange = (index: number, keyName: string, keyValue: any) => {
    const newItems = [...items];
    newItems[index][keyName] = keyValue;
    setItems(newItems);
    //callback
    onChange && onChange(newItems);
  }

  return (
    <>
      {items.map((_item: any, index: number) => (
        <Fragment key={index}>
          <div className="form-group">
            <label className="form-item-title">Name<span className="tx-danger">*</span></label>
            <Input
              type={'text'}
              value={_item?.name}
              onChange={(newText: string) => handleValueChange(index, 'name', newText)}
            />
          </div>
          <div className="form-group">
            <label className="form-item-title">Website</label>
            <Website
              //value={Array.isArray(_item?.website) ? _item?.website : (_item?.website ? [_item?.website] : [])}
              value={_item?.website}
              onChange={(newWeb: any) => handleValueChange(index, 'website', newWeb)}
            />
          </div>
          {items.length > 1 &&
            <Button
              color='link'
              className='tx-danger'
              size='sm'
              icon='Minus'
              name='Delete'
              onClick={() => handleRemove(index)}
            />
          }
          <Button
            color='link'
            size='sm'
            icon='Plus'
            name='Add'
            onClick={handleAdd}
          />
          {items.length > 1 && (index < items.length - 1) && <hr />}
        </Fragment>
      ))}
    </>
  );
}

export default Item;