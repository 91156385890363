import React, { useState, Suspense, useEffect, useRef, useMemo } from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deviceAtom } from '@base/recoil/atoms/app';
import SplitView, { EmptySplitViewFC } from '@base/components/split-view';
import { useLanguageByMenu } from '@base/services/i18n'; //getLanguageByMenu
import { MENU_CUSTOMER } from '@base/config/menus';
import Loading from '@base/components/loading';
import { ListType } from '@base/types/enums';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import ListPage from '@customer/customer/pages/list';
import ViewPage from '@customer/customer/pages/view';
import { useUserSettings } from '@base/services';

const CustomerMain: React.FC = () => {
  //state
  const [{ innerHeight, isDesktop }] = useRecoilState(deviceAtom);
  const [listFilter, setListFilter] = useRecoilState(customerListFilterState);
  const { listType } = listFilter;
  const isSplitMode = listType === ListType.SPLIT;

  //router params
  const matchAll = useMatch('/customer/all/*');

  // load menu language
  useLanguageByMenu([MENU_CUSTOMER]);

  // get user settings
  const { data: pageData, isLoading } = useUserSettings(MENU_CUSTOMER);

  //get setting set to recoil: TODO - only change for setting columns
  useEffect(() => {
    if (pageData?.results) {
      if (pageData.results?.length > 0) {
        pageData.results.map((setting: any) => {
          if (setting.key === 'list' && setting?.value) {
            try {
              const listSetting: any = JSON.parse(setting.value);
              setListFilter(listSetting);
            } catch (e) {
              //error
            }
          }
        });
      }
    }
  }, [pageData]);

  //props
  const splitViewProps = {
    isSplitMode,
    styles: {
      height: innerHeight !== 0 ? innerHeight - 60 : 0,
    },
  };

  //props
  const listProps = {
    isSplitMode,
  };

  //content
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path=""
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={<EmptySplitViewFC />}
                />
              ) : (
                <ListPage {...listProps} />
              )
            }
          />
          <Route
            path={matchAll ? ':id/:category/*' : ':id/*'}
            element={
              isSplitMode && isDesktop ? (
                <SplitView
                  {...splitViewProps}
                  leftPane={<ListPage {...listProps} />}
                  rightPane={
                    <div className="pane-content" style={{ flex: 1 }}>
                      <Suspense fallback={<Loading />}>
                        <ViewPage isSplitMode={isSplitMode} />
                      </Suspense>
                    </div>
                  }
                />
              ) : (
                <ViewPage />
              )
            }
          />
          {/* <Route path="/" element={<Navigate replace to="/customer/account" />} /> */}
        </Routes>
      )}
    </>
  );

};

export default CustomerMain;
