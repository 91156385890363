import Icon from '@base/assets/icons/svg-icons';

export const CATEGORY_OPTIONS = {
  all: 'Customers',
  account: 'Account',
  contact: 'Contact',
  // segment: 'Segment',
  // marketing: 'Marketing List',
  // persona: 'Buyer Persona',
  // ideal: 'Ideal Customer Profile',
  // block: 'Blocklist',
};

export const CUSTOMER_ADD_OPTIONS = {
  account: {
    name: 'Account',
    icon: Icon('m_customer_account'),
  },
  contact: {
    name: 'Contact',
    icon: Icon('contacts'),
  },
  // segment: {
  //   name: 'Segment',
  //   icon: Icon('contacts'),
  // },
  // marketing: {
  //   name: 'Marketing List',
  //   icon: Icon('contacts'),
  // },
  // persona: {
  //   name: 'Buyer Persona',
  //   icon: Icon('contacts'),
  // },
  // ideal: {
  //   name: 'Ideal Customer Profile',
  //   icon: Icon('contacts'),
  // },
};
