import { lazy } from 'react';

const MainPage = lazy(() => import('@customer/main/pages'));

export default [
  {
    path: '/customer/*',
    component: MainPage,
  },
];
