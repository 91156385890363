//Paging
export const PAGING_ITEMS_PER_PAGE = 15;

//api values
export const CUSTOMER_CATEGORY_NONE = 'none';
export const CUSTOMER_CATEGORY_ALL = 'all';
export const CUSTOMER_CATEGORY_ACCOUNT = 'account'; //1
export const CUSTOMER_CATEGORY_CONTACT = 'contact'; //2
export const CUSTOMER_CATEGORY_EMPLOYEE = 'employee'; //3
export const CUSTOMER_CATEGORY_SUBSIDIARY = 'subsidiary'; //4

export const CUSTOMER_CATEGORY_ACCOUNT_NUM = 1; //1
export const CUSTOMER_CATEGORY_CONTACT_NUM = 2; //2

export const CUSTOMER_TYPE_NONE = 'none'; //0
export const CUSTOMER_TYPE_CUSTOMER = 'customer'; //1
export const CUSTOMER_TYPE_POTENTIAL = 'potential'; //2
export const CUSTOMER_TYPE_SALES_AGENT = 'sales_agent'; //3
export const CUSTOMER_TYPE_VENDOR = 'vendor'; //4
export const CUSTOMER_TYPE_PARTNER = 'partner'; //5

export const CUSTOMER_STATE_NONE = 'none';
export const CUSTOMER_STATE_GENERAL = 'general';
export const CUSTOMER_STATE_FAILED = 'failed';
export const CUSTOMER_STATE_HOLD = 'hold';
export const CUSTOMER_STATE_TERMINATED = 'terminated';

// export const CUSTOMER_RANKING_NONE = 0;
// export const CUSTOMER_RANKING_GOLD = 1;
// export const CUSTOMER_RANKING_SILVER = 2;
// export const CUSTOMER_RANKING_COPPER = 3;

export const CUSTOMER_CONTACT_TYPE_NONE = 'none';
export const CUSTOMER_CONTACT_TYPE_EMPLOYEE = 'employee';
export const CUSTOMER_CONTACT_TYPE_INFLUENCER = 'influencer';
export const CUSTOMER_CONTACT_TYPE_CHAMPION = 'champion';
export const CUSTOMER_CONTACT_TYPE_BUDGET_HOLDER = 'budget_holder';
export const CUSTOMER_CONTACT_TYPE_DECISION_MAKER = 'decision_maker';
export const CUSTOMER_CONTACT_TYPE_END_USER = 'end_user';

//options mapping keyName and value
// export const CUSTOMER_CATEGORIES = {
//     [CUSTOMER_CATEGORY_NONE]: 'CATEGORY_NONE',
//     [CUSTOMER_CATEGORY_ACCOUNT]: 'CATEGORY_ACCOUNT',
//     [CUSTOMER_CATEGORY_CONTACT]: 'CATEGORY_CONTACT',
//     [CUSTOMER_CATEGORY_EMPLOYEE]: 'CATEGORY_EMPLOYEE',
//     [CUSTOMER_CATEGORY_SUBSIDIARY]: 'CATEGORY_SUBSIDIARY'
// };

export const CUSTOMER_TYPES = {
  [CUSTOMER_TYPE_NONE]: 'TYPE_NONE',
  [CUSTOMER_TYPE_CUSTOMER]: 'TYPE_CUSTOMER',
  [CUSTOMER_TYPE_POTENTIAL]: 'TYPE_POTENTIAL',
  [CUSTOMER_TYPE_SALES_AGENT]: 'TYPE_SALES_AGENT',
  [CUSTOMER_TYPE_VENDOR]: 'TYPE_VENDOR',
  [CUSTOMER_TYPE_PARTNER]: 'TYPE_PARTNER',
};

export const CUSTOMER_TYPE_ENUM: any = {
  TYPE_NONE: 0,
  TYPE_CUSTOMER: 1,
  TYPE_POTENTIAL: 2,
  TYPE_SALES_AGENT: 3,
  TYPE_VENDOR: 4,
  TYPE_PARTNER: 5,
};

export const CUSTOMER_CONTACT_TYPE_ENUM: any = {
  CONTACT_TYPE_NONE: 0,
  CONTACT_TYPE_EMPLOYEE: 1,
  CONTACT_TYPE_INFLUENCER: 2,
  CONTACT_TYPE_CHAMPION: 3,
  CONTACT_TYPE_BUDGET_HOLDER: 4,
  CONTACT_TYPE_DECISION_MAKER: 5,
  CONTACT_TYPE_END_USER: 6,
};

// export const CUSTOMER_STATES = {
//     [CUSTOMER_STATE_NONE]: 'STATE_NONE',
//     [CUSTOMER_STATE_GENERAL]: 'STATE_GENERAL',
//     [CUSTOMER_STATE_FAILED]: 'STATE_FAILED',
//     [CUSTOMER_STATE_HOLD]: 'STATE_HOLD',
//     [CUSTOMER_STATE_TERMINATED]: 'STATE_TERMINATED',
// }

export const SEND_MODE_OPTIONS = [
  {
    label: 'Email',
    value: 'SEND_MODE_EMAIL',
  },
  {
    label: 'SMS',
    value: 'SEND_MODE_SMS',
  },
];

export const LABEL_VALUE_PRIMARY = 'LABEL_PRIMARY';
export const LABEL_VALUE_OTHER = 'LABEL_OTHER';
export const LABEL_VALUE_CUSTOM = 'LABEL_CUSTOM';
export const LABEL_VALUE_WORK = 'LABEL_WORK';
export const LABEL_VALUE_PERSONAL = 'LABEL_PERSONAL';

export const LABEL_VALUE_BIRTHDAY = 'ANNIVERSARY_LABEL_BIRTHDAY';
export const LABEL_VALUE_WEDDING = 'ANNIVERSARY_LABEL_WEDDING';
export const LABEL_VALUE_CUSTOM_ANNI = 'ANNIVERSARY_LABEL_CUSTOM';

export const LABEL_VALUE_HOME = 'WEBSITE_LABEL_PERSONAL';
export const LABEL_VALUE_COMPANY = 'WEBSITE_LABEL_COMPANY';
export const LABEL_VALUE_CUSTOM_WEB = 'WEBSITE_LABEL_CUSTOM';

export const GENERAL_LABEL_OPTIONS = [
  {
    label: 'Primary',
    value: LABEL_VALUE_PRIMARY,
  },
  {
    label: 'Other',
    value: LABEL_VALUE_OTHER,
  },
  {
    label: 'Custom',
    value: LABEL_VALUE_CUSTOM,
  },
];

export const PERSONAL_LABEL_OPTIONS = [
  {
    label: 'Primary',
    value: LABEL_VALUE_PRIMARY,
  },
  {
    label: 'Work',
    value: LABEL_VALUE_WORK,
  },
  {
    label: 'Personal',
    value: LABEL_VALUE_PERSONAL,
  },
  {
    label: 'Custom',
    value: LABEL_VALUE_CUSTOM,
  },
];

export const ANNIVERSARY_LABEL_OPTIONS = [
  {
    label: 'Birthday',
    value: LABEL_VALUE_BIRTHDAY,
  },
  {
    label: 'Wedding',
    value: LABEL_VALUE_WEDDING,
  },
  {
    label: 'Custom',
    value: LABEL_VALUE_CUSTOM_ANNI,
  },
];

export const WEBSITE_LABEL_OPTIONS = [
  {
    label: 'Home',
    value: LABEL_VALUE_HOME,
  },
  {
    label: 'Company',
    value: LABEL_VALUE_COMPANY,
  },
  {
    label: 'Custom',
    value: LABEL_VALUE_CUSTOM_WEB,
  },
];

//receipt types
//Non Tax Invoice,Tax Invoice, Cash Receipt, Simple Receipt, Credit Card Sales Slips
export const RECEIPT_TYPE_NONE = 'RECEIPT_TYPE_NONE';
export const RECEIPT_TYPE_NONE_TAX_INVOICE = 'RECEIPT_TYPE_NONE_TAX_INVOICE';
export const RECEIPT_TYPE_TAX_INVOICE = 'RECEIPT_TYPE_TAX_INVOICE';
export const RECEIPT_TYPE_CASH_RECEIPT = 'RECEIPT_TYPE_CASH_RECEIPT';
export const RECEIPT_TYPE_SIMPLE_RECEIPT = 'RECEIPT_TYPE_SIMPLE_RECEIPT';
export const RECEIPT_TYPE_CC_SALES_SLIP = 'RECEIPT_TYPE_CC_SALES_SLIP';

export const RECEIPT_TYPE_OPTIONS = [
  {
    label: 'Non Tax Invoice',
    value: RECEIPT_TYPE_NONE_TAX_INVOICE,
  },
  {
    label: 'Tax Invoice',
    value: RECEIPT_TYPE_TAX_INVOICE,
  },
  {
    label: 'Cash Receipt',
    value: RECEIPT_TYPE_CASH_RECEIPT,
  },
  {
    label: 'Simple Receipt',
    value: RECEIPT_TYPE_SIMPLE_RECEIPT,
  },
  {
    label: 'Credit Card Sales Slips',
    value: RECEIPT_TYPE_CC_SALES_SLIP,
  },
];
