import { CUSTOMER_CATEGORY_ACCOUNT } from '@customer/customer/config/constants';
import { CustomerCategory } from '@customer/customer/types/enums/customer-enums';

type CustomerCategoryStrings = keyof typeof CustomerCategory;

//api write params
export const generateCustomerParams = (formData: any, menuApi: string) => {
  const newParams: any = {};

  newParams.photo = formData.photo; //TODO
  //required fields
  newParams.code = formData.code;
  newParams.name = formData.name;

  const cateKey: CustomerCategoryStrings = (menuApi.split('_')[1] ||
    CUSTOMER_CATEGORY_ACCOUNT) as CustomerCategoryStrings;

  newParams.category = CustomerCategory[cateKey];
  if (formData.type) {
    newParams.type = formData.type.value || 'TYPE_NONE';
  }
  if (formData.industries) {
    newParams.industries = formData.industries.map((_ele: any) => ({
      industryId: _ele.id,
      name: _ele.title,
    }));
  }
  newParams.emails = formData.emails.map((_ele: any) => ({
    label: _ele.label,
    labelValue: _ele.label_value,
    email: _ele.value,
  }));
  newParams.phones = formData.phones
    .filter((_ele: any) => _ele.phoneNumber.length > 0)
    .map((_ele: any) => ({
      label: _ele.phoneOption.value,
      labelValue: _ele.phoneOptionDescription,
      country: _ele.phoneCode.phone_code,
      phoneNumber: _ele.phoneNumber,
      extension: _ele.phoneOptionExtension,
    }));
  //optional fields
  if (formData.memo && formData.memo.length > 0) {
    newParams.memo = formData.memo;
  }
  if (formData.addresses && formData.addresses.length > 0) {
    newParams.addresses = formData.addresses
      .filter((_ele: any) => _ele.address.length > 0)
      .map((_ele: any) => ({
        label: _ele.option?.value,
        labelValue: _ele.optionDescription,
        country: _ele.country?.value,
        state: _ele.state?.value,
        city: _ele.city?.value,
        street: _ele.address,
        zipcode: _ele.zipcode,
      }));
  }
  if (formData.websites && formData.websites.length > 0) {
    newParams.websites = formData.websites.map((_ele: any) => ({
      label: _ele.label,
      labelValue: _ele.label_value,
      website: _ele.value,
      protocol: _ele.protocol,
    }));
  }
  if (formData.currency) {
    newParams.currency = formData.currency.code || '';
  }
  if (formData.ranking) {
    newParams.rankingId = formData.ranking.value || '';
    newParams.rankingName = formData.ranking.label || '';
  }
  if (formData.staffs) {
    newParams.staffs = Array.isArray(formData.staffs)
      ? formData.staffs.map((_ele: any) => ({
          id: _ele.uuid,
          name: _ele.name,
          group: {
            id: _ele.groups.length > 0 ? _ele.groups[0].id : '',
            name: _ele.groups.length > 0 ? _ele.groups[0].name : '', //_ele.crmBaseGroup || "",
          },
        }))
      : [
          {
            id: formData.staffs.uuid,
            name: formData.staffs.name,
            group: {
              id: formData.staffs.groups.length > 0 ? formData.staffs.groups[0].id : '',
              name: formData.staffs.groups.length > 0 ? formData.staffs.groups[0].name : '', //_ele.crmBaseGroup || "",
            },
          },
        ];
  }
  if (formData.connectedIps && formData.connectedIps.length > 0) {
    newParams.connectedIps = formData.connectedIps.map((_ele: any) => {
      if (_ele !== '...') {
        return { ip: _ele };
      }
    });
  }
  //TODO: customFields: [{ id: "121", value: "test" }]
  //TODO: lastActivityDate

  if (cateKey === CUSTOMER_CATEGORY_ACCOUNT) {
    //newParams.state = 'STATE_GENERAL';
    //optional
    if (formData.groups) {
      newParams.groups = formData.groups.map((_ele: any) => ({
        groupId: _ele.id,
        name: _ele.title,
      }));
    }
    if (formData.leadSources) {
      newParams.leadSources = formData.leadSources.map((_ele: any) => ({
        sourceId: _ele.id,
        name: _ele.title,
      }));
    }
    //related employees
    if (formData.relatedEmployees) {
      newParams.links = formData.relatedEmployees.map((_ele: any) => ({
        type: 'CATEGORY_EMPLOYEE',
        linkId: _ele.value,
      }));
    }
    if (formData.employeesNumber) {
      newParams.employeesNumber = parseInt(formData.employeesNumber);
    }
    if (formData.annualRevenue) {
      newParams.annualRevenue = parseFloat(formData.annualRevenue.realValue || 0);
    }
    if (formData.flagshipProduct) {
      newParams.flagshipProduct = formData.flagshipProduct;
    }
    if (formData.businessNumber) {
      newParams.businessNumber = formData.businessNumber;
    }
    //TODO: businessField: "Bussiness Field"
    //TODO: businessName: "Bussiness Name"
    //TODO: businessIndustry: "Busisness industry"
    //TODO: businessType: "Bussiness type"
    //TODO: businessAgent: "Bussiness agent"
  }
  if (cateKey !== CUSTOMER_CATEGORY_ACCOUNT) {
    //required
    if (formData.contactType) {
      newParams.contactType = formData.contactType.keyName;
    }
    if (formData.contactType.keyName === 'CONTACT_TYPE_EMPLOYEE') {
      if (formData.employeeRole) {
        newParams.employeeRole = formData.employeeRole.keyName;
      }
      if (formData.accountId) {
        newParams.accountId = formData.accountId.id;
        newParams.accountName = formData.accountId.name;
      }
    }
    //optional
    if (formData.company) {
      newParams.company = formData.company;
    }
    //newParams.department = formData.department;
    if (formData.job) {
      newParams.job = formData.job;
    }
    if (formData.position) {
      newParams.position = formData.position;
    }
    if (formData.hobby) {
      newParams.hobby = formData.hobby;
    }
    if (formData.gender) {
      newParams.gender = formData.gender.value;
    }
    if (formData.anniversaries) {
      newParams.anniversaries = formData.anniversaries.map((_ele: any) => ({
        label: _ele.label,
        labelValue: _ele.label_value,
        anniversary: new Date(_ele.value).toISOString(), //format: 2021-12-09T09:30:15Z  new Date().toISOString()
      }));
    }
    if (formData.conversionDate) {
      newParams.conversionDate = new Date(formData.conversionDate).toISOString(); //format: 2021-12-09T09:30:15Z
    }
  }

  return newParams;
};

export const finalizeParams = (configParams: any, menuApi: string) => {
  const newParams = { ...configParams };

  const cateKey: CustomerCategoryStrings = (menuApi.split('_')[1] ||
    CUSTOMER_CATEGORY_ACCOUNT) as CustomerCategoryStrings;

  newParams.category = CustomerCategory[cateKey];

  return newParams;
};
