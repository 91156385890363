import React, { useMemo, useState } from 'react';
// import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ListBody, ListPagination } from '@base/components/list';
import { TableNothing } from '@base/components/list/list-loading';
// import { deviceAtom } from '@base/recoil/atoms/app';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import { DESC, ASC } from '@base/config/constant';
import { ListPageConfig } from '@desk/ticket/config/pages/list-page';
import { ColumnDef } from '@tanstack/react-table';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { ITicket } from '@desk/ticket/types/interfaces/ticket';
// import { Button } from '@base/components/form';
// import InfoModal from '@base/containers/info-modal';
// import { MAIN_MENU } from '@desk/main/config';
import { TicketTextView } from '@base/containers/quick-view';

const PageBody: React.FC<any> = (props) => {
  const {
    category, //router category
    isFetching,
    fields,
    itemsList,
    filter,
    setFilter
  } = props;
  const { t } = useTranslation();
  //state
  //const [{ isDesktop, device, layout }] = useRecoilState(deviceAtom);
  //const [selectedTicket, setSelectedTicket] = useState<any>(null);
  //const [isOpenInfo, setIsOpenInfo] = useState(false);

  //config disable sortBy columns
  const disableSortByColumns: any = [];

  //render columns components

  //set filter sort param
  const setSort = (sort: any) => {
    let newFilter = {
      ...filter,
      sort: sort
    };
    setFilter(newFilter);
  };

  //table props
  const newFields = fields.map((_ele: any) => ({
    ..._ele,
    enableSorting: true,
    disableSortBy: disableSortByColumns.includes(_ele.keyName),
  }));

  const getColumnRender = (extraParams: any) => {
    const { category } = extraParams;
    return {
      ...ListPageConfig.getColumnRenderRemap({ category }),
      subject(col: string, row: ITicket) {
        return (
          // <Button color='link' className='tx-14' onClick={() => {
          //   setIsOpenInfo(true);
          //   setSelectedTicket(row);
          // }}>
          //   {row.subject}
          // </Button>
          <TicketTextView value={{ id: row.id, name: row.subject }} />
        );
      }
    };
  };

  /** ================================== React Table 8 ================================== */
  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      ...makeTable8Columns(
        newFields,
        getColumnRender({ category }),
        { category },
        [],
      ),
    ],
    [newFields],
  );

  const listTableProps = {
    nodata: <TableNothing />,
    data: itemsList,
    // loading: isFetching,
    columns: columns, //tableHideColumns
    initialState: {
      pageSize: filter.paging?.itemPerPage || 15,
    },
    // rowSelected: selectedIds,
    onSortBy: (clName: any, isSorted: any) => {
      // refetch();
      //let orderBy = isSortedDesc ? DESC : ASC;
      //setSort({ field: clName, orderBy: orderBy });
      if (isSorted !== false) {
        let orderBy = isSorted === 'desc' ? DESC : ASC;
        setSort({ field: clName, orderBy: orderBy });
      }
    },
    className: '',
  };

  //table paging
  const pagingProps = {
    totalPage: filter.paging?.totalPage || 1,
    totalItems: filter.paging?.totalItems || 0,
    currentPage: filter.paging?.page || 1,
    itemPerPage: filter.paging?.itemPerPage || 15,
    nextPage: filter.paging?.nextPage || null,
    previousPage: filter.paging?.previousPage || null,
    onChange: (page: number, size: number) => {
      let newFilter = {
        ...filter,
        paging: {
          ...filter.paging,
          page,
          size
        },
      };
      setFilter(newFilter);
    },
  };

  //list
  const ListBodyMemo = useMemo(() => {
    return (
      <ListBody isScrollY={false}>
        <ListReactTable8 {...listTableProps} />

        <ListPagination isSplitMode={false} type={'full'} {...pagingProps} />
      </ListBody>
    );
  }, [itemsList, filter]);

  //main
  return (
    <>
      {ListBodyMemo}
      {/* {isOpenInfo &&
        <InfoModal
          title='Ticket Info'
          isOpen={isOpenInfo}
          onClose={() => setIsOpenInfo(false)}
          size='md'
          subject={selectedTicket.subject}
          content={selectedTicket.content}
          viewUrl={`/${MAIN_MENU}/ticket/${selectedTicket.id}`}
        />
      } */}
    </>
  );
};

export default PageBody;
