import React, { Fragment, useEffect, useState, VFC } from 'react';
import { clickOutSide, generateUUID } from '@base/utils/helpers';
//import { usePopper } from 'react-popper';
import { ChevronDown } from 'react-feather';
import Portal from '@base/components/portal';
import Icon from '@base/assets/icons/svg-icons';
import classNames from 'classnames';
import { DateRangeSelection } from '@base/components/form/calendar';
import { keys } from 'lodash';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

interface IListHeaderFilterSplitMode {
  groupByOptions?: any[];
  filterByOptions?: any[];
  dateByOptions?: any[];
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any, type: string) => void;
  sort?: React.ReactNode;
}

const DateFilter = (props: any) => {
  const { fieldLabel, fieldKey, onSelect } = props;

  const rangeOptions: { [key: string]: string } = {
    this_year: 'This Year',
    this_quarter: 'This Quarter',
    this_month: 'This Month',
    this_week: 'This Week',
    today: 'Today',
  };

  const getRange = (index: number) => {
    let start, end;
    switch (index) {
      case 0:
        start = dayjs().startOf('year');
        end = dayjs().endOf('year');
        break;
      case 1:
        start = dayjs().startOf('quarter');
        end = dayjs().endOf('quarter');
        break;
      case 2:
        start = dayjs().startOf('month');
        end = dayjs().endOf('month');
        break;
      case 3:
        start = dayjs().startOf('week');
        end = dayjs().endOf('week');
        break;
      case 4:
        start = dayjs().startOf('day');
        end = dayjs().endOf('day');
        break;
    }

    return [start, end];
  };

  const componentId = generateUUID();

  return (
    <>
      <button
        type="button"
        className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
        data-toggle="collapse"
        data-target={`#dateBy_${componentId}`}
      >
        <span className="mg-r-5">{fieldLabel}</span>
        <ChevronDown className="mg-l-auto" />
      </button>
      <div id={`dateBy_${componentId}`} className="collapse">
        {keys(rangeOptions).map((k, i) => (
          <button
            key={i}
            type="button"
            className="dropdown-item"
            onClick={() => onSelect && onSelect({ [fieldKey]: getRange(i) })}
          >
            {rangeOptions[k]}
          </button>
        ))}
        <button
          type="button"
          className="dropdown-item btn d-flex align-items-center"
          data-toggle="collapse"
          data-target={`#dateBy_${componentId}_custom`}
        >
          <span className="mg-r-5">Custom</span>
          <ChevronDown className="mg-l-auto" />
        </button>
        <div id={`dateBy_${componentId}_custom`} className="collapse input-group pd-x-25 pd-y-5">
          <DateRangeSelection
            onChange={(data) => {
              onSelect && onSelect({ [fieldKey]: [data?.startDate, data?.endDate] });
            }}
            isInline={false}
          />
        </div>
        {/*<div id="dateBy1-custom" className="collapse show input-group pd-x-25 pd-y-5">
          <input className="form-control" defaultValue={'2021-12-20 ~ 2021-12-21'} />
          <div className="input-group-append">
            <span className="input-group-text">
              <Calendar />
            </span>
          </div>
        </div>*/}
      </div>
    </>
  );
};

const ListHeaderFilterSplitMode: VFC<IListHeaderFilterSplitMode> = (
  props: IListHeaderFilterSplitMode,
) => {
  const { groupByOptions, filterByOptions, dateByOptions, filterData, onFilterDataChanged, sort } =
    props;

  const [isShow, showDropdown] = useState(false);

  const [buttonElement, setButtonElement] = useState<any | undefined>(null);
  const [popperElement, setPopperElement] = useState<any | undefined>(null);

  useEffect(() => {
    if (isShow) {
      clickOutSide('listHeaderFilterSplitMode', () => showDropdown(false));
    }
  }, [isShow]);

  //const { styles, attributes } = usePopper(buttonElement, popperElement);

  return (
    <div className="dropdown mg-l-auto" id="listHeaderFilterSplitMode">
      <button
        type="button"
        className="btn"
        ref={setButtonElement}
        onClick={() => showDropdown(!isShow)}
      >
        {Icon('filter')}
      </button>

      {isShow && (
        <Portal>
          <div
            ref={setPopperElement}
            //style={styles.popper}
            //{...attributes.popper}
            className="popper show"
          >
            <div className="filter-settings">
              <div className="filter-settings-title">Filters</div>
              <div className="filter-settings-body">
                {groupByOptions && groupByOptions.length && (
                  <div className="form-group">
                    <label className="form-item-title">
                      Group by
                      <button
                        type="button"
                        className="btn btn-link link-03 pd-0 float-right"
                        data-toggle="collapse"
                        data-target="#groupBy"
                      >
                        <ChevronDown />
                      </button>
                    </label>
                    <div id="groupBy" className="collapse">
                      {groupByOptions.map((groupBy, idx) => {
                        return (
                          <button
                            key={idx}
                            type="button"
                            className={classNames('dropdown-item', {
                              active: (filterData?.groupBy ?? '') === groupBy.value,
                            })}
                            onClick={() => onFilterDataChanged(groupBy.value, 'group_by')}
                          >
                            {groupBy.label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {dateByOptions && dateByOptions.length && (
                  <>
                    <div className="dropdown-divider" />
                    <div className="form-group">
                      <label className="form-item-title">
                        Date by
                        <button
                          type="button"
                          className="btn btn-link link-03 pd-0 float-right"
                          data-toggle="collapse"
                          data-target="#dateBy"
                        >
                          <ChevronDown />
                        </button>
                      </label>
                      <div id="dateBy" className="collapse">
                        <button
                          className="dropdown-item"
                          onClick={() => onFilterDataChanged({}, 'date')}
                        >
                          All
                        </button>
                        {dateByOptions.map((option, i) => (
                          <DateFilter
                            key={i}
                            fieldLabel={option.label}
                            fieldKey={option.value}
                            onSelect={(data: any) => onFilterDataChanged(data, 'date')}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )}
                {filterByOptions && filterByOptions?.length ? (
                  <>
                    <div className="dropdown-divider" />
                    <div className="form-group">
                      <label className="form-item-title">
                        Filters
                        <button
                          type="button"
                          className="btn btn-link link-03 pd-0 float-right"
                          data-toggle="collapse"
                          data-target="#filters"
                        >
                          <ChevronDown />
                        </button>
                      </label>
                      <div id="filters" className="collapse">
                        {filterByOptions.map((option, i) => {
                          return (
                            <Fragment key={i}>
                              <button
                                key={i}
                                type="button"
                                className="btn d-flex wd-100p align-items-center tx-12 tx-semibold"
                                data-toggle="collapse"
                                data-target={`#filters_field_${i}`}
                              >
                                <span className="mg-r-5">{option.label}</span>
                                <ChevronDown className="mg-l-auto" />
                              </button>
                              <div id={`filters_field_${i}`} className="filter-component collapse">
                                <input
                                  autoFocus
                                  className="form-control"
                                  name={option.value}
                                  type="text"
                                  value={filterData?.[option.value] || ''}
                                  onChange={(e) =>
                                    onFilterDataChanged(
                                      { [option.value]: e.target.value },
                                      'filter',
                                    )
                                  }
                                />
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : null}

                {!!sort && (
                  <>
                    <div className="dropdown-divider" />
                    <div className="filter-settings-title">Sorting</div>
                    <div className="filter-settings-body">
                      <div className="form-group">{sort}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ListHeaderFilterSplitMode;
