import React, { useState, useEffect, useRef } from 'react';
//import _ from 'lodash';
import { Button } from '@base/components/form';
//import { ModalHTML } from '@base/components';
import Address from '@customer/customer/components/address';
//import { UPDATE_CUSTOMER_FIELD } from 'Customer/Modules/Main/stores/Query';

// export const AddressType = {
//   billTo: 'billTo',
//   shipTo: 'shipTo',
// };

export const Category = {
  account: 'account',
  contact: 'contact',
};

/**
 * Add new address
 * @param props
 * @returns
 */
const AddressAddModal: React.FC<any> = (props: any) => {
  const {
    id = '', //cusomter id
    value = null,
    labelId, //4: bill to, 5: ship to
    category = Category.contact,
    onClose,
    onSuccess,
  } = props;

  //state
  const [address, setAddress] = useState<any>(null); //selected address
  const [isSaving, setIsSaving] = useState(false);
  //const modal = useRef<any>(null);

  //init value
  useEffect(() => {
    if (value) {
      if (value?.id !== address?.id) {
        setAddress(value); //EDIT
      }
    } else {
      setAddress(null); //NEW
    }
  }, [value]);

  //select address to edit
  const handleAddressChange = (item: any) => {
    setAddress(item[0]);
  };

  //update editing address
  const handeSaveEdit = async () => {
    setIsSaving(true);
    const item = {
      id: address?.id,
      street: address?.address,
      city: address?.city?.value,
      state: address?.state?.value,
      country: address?.country?.value,
      zipcode: address?.zipcode,
    };
    //api params
    const params = {
      input: {
        fields: [
          {
            keyName: 'addresses',
            value: JSON.stringify([
              {
                ...item,
                label: labelId,
                mode: item.id ? 'update' : 'insert',
              },
            ]),
          },
        ],
        id: id,
        menu: `customer_${category}`,
      },
    };
    // const result = await request(UPDATE_CUSTOMER_FIELD, params).then(
    //   (res) => res?.customerFieldsModify,
    // );
    if (item.id) {
      // EDIT CASE
      onSuccess(item);
      // if (result?.success) {
      //   setAddresses(
      //     addresses.map((address) => (item?.id == address?.id ? { ...address, ...item } : address)),
      //   );
      //   closeModal();
      // }
    } else {
      // NEW CASE
      //const rows = JSON.parse(result?.rows);
      //onSuccess(rows?.[0]);
      // let address = {};
      // try {
      //   const rows = JSON.parse(result?.rows);
      //   address = rows?.[0];
      // } catch (error) { }
      // if (result?.success) {
      //   setAddresses(addresses.concat({ ...item, ...address }));
      //   closeModal();
      // }
    }
    setIsSaving(false);
  };

  return (
    <div className="card card-open mg-b-20">
      <div className="card-body">
        <Address isMultiple={false} address={[address]} onChange={handleAddressChange} />
      </div>
      <div className="card-footer bg-gray-100" style={{ textAlign: 'end' }}>
        <Button color="secondary" className="btn btn-secondary" onClick={onClose} name="Cancel" />
        <Button
          className="mg-l-5"
          color="success"
          onClick={handeSaveEdit}
          loading={isSaving}
          disabled={isSaving}
          name="Save"
        />
      </div>
    </div>
  );
  //render
  // return (
  //   <ModalHTML
  //     title={address?.id ? 'Edit' : 'Add'}
  //     onModalInit={modal}
  //     onHide={onClose}
  //     classDialogSize="modal-md"
  //     className="modal-zindex-1099"
  //   >
  //     <div className="modal-body">
  //       <Address isMultiple={false} address={[address]} onChange={handleAddressChange} />
  //     </div>
  //     <div className="modal-footer">
  //       <Button color="secondary" className="btn btn-secondary" onClick={onClose} name="Close" />
  //       <Button
  //         className="mg-l-5"
  //         color="success"
  //         onClick={handeSaveEdit}
  //         loading={isSaving}
  //         disabled={isSaving}
  //         name="Save"
  //       />
  //     </div>
  //   </ModalHTML>
  // );
};

export default AddressAddModal;
