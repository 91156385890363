import React, { useEffect, useState } from 'react';
import { PlusSquare, MinusSquare } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { SpanLang, Select } from '@base/components/form';
import {
  LABEL_VALUE_COMPANY,
  LABEL_VALUE_CUSTOM_WEB,
  WEBSITE_LABEL_OPTIONS,
} from '@customer/customer/config/constants';

const Website: React.FC<any> = (props: any) => {
  const { value, onChange, isMultiple = false } = props;

  const { t } = useTranslation();

  const protocolOptions = [
    {
      value: 'http://',
      label: 'http://',
    },
    {
      value: 'https://',
      label: 'https://',
    },
  ];

  //intial
  const defaultRow = {
    label: {
      languageKey: 'Company',
      label: LABEL_VALUE_COMPANY,
    },
    labelValue: '',
    protocol: 'http://',
    website: '',
  };

  //state
  const [rows, setRows] = useState<any[]>([defaultRow]);

  //init rows
  useEffect(() => {
    //// console.log('init value', value);
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(rows)) {
            setRows(value);
          }
        } else {
          setRows([defaultRow]);
        }
      } else {
        //single object
        setRows([value]);
      }
    } else {
      setRows([defaultRow]);
    }
  }, [value]);

  //new row
  const handleAddMore = () => {
    let newRows = [...rows, defaultRow];
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //remove email
  const handleRemove = (i: number) => {
    let newRows = [...rows];
    newRows.splice(i, 1);
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //set value change
  const handleValueChange = (idx: number, keyAttribute: string, keyValue: any) => {
    const newRows = [...rows];
    newRows[idx][keyAttribute] = keyValue;
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //main render
  return (
    <div>
      {rows.map((row, idx) => {
        return (
          <div className="website-relative" key={idx}>
            <div className="form-row mg-b-5">
              <div className="col-md-3 mg-b-5">
                <Select
                  outSide={true}
                  isSearchable={false}
                  options={protocolOptions}
                  onChange={(item: any) => handleValueChange(idx, 'protocol', item.value)}
                  value={
                    row.protocol ? protocolOptions.find((opt) => opt.value === row.protocol) : null
                  }
                />
              </div>
              <div className="col-md-6 mg-b-5">
                <input
                  //name="value"
                  placeholder="n.hanbiro.com"
                  maxLength={1024}
                  className="form-control ht-38"
                  type="text"
                  value={row.website}
                  onChange={(e: any) => handleValueChange(idx, 'website', e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <Select
                  outSide={true}
                  isSearchable={false}
                  options={WEBSITE_LABEL_OPTIONS}
                  onChange={(option: any) =>
                    handleValueChange(idx, 'label', {
                      label: option.value,
                      languageKey: option.label,
                    })
                  }
                  value={
                    row.label
                      ? WEBSITE_LABEL_OPTIONS.find((opt: any) => opt.value === row.label?.label)
                      : null
                  }
                />
              </div>
            </div>
            {row.label?.label === LABEL_VALUE_CUSTOM_WEB && (
              <div className="form-row mg-b-5">
                <div className="col-md-12">
                  <input
                    //name="label_value"
                    placeholder={t('description')}
                    className="form-control ht-38"
                    type="text"
                    value={row.labelValue}
                    onChange={(e: any) => handleValueChange(idx, 'labelValue', e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="form-row mg-b-5">
              {isMultiple && idx !== 0 && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-danger pd-x-5 pd-y-0"
                  onClick={() => handleRemove(idx)}
                >
                  <MinusSquare />
                  <SpanLang keyLang="Delete" />
                </button>
              )}
              {isMultiple && idx != rows.length - 1 && (
                <div className="d-flex justify-content-center mg-y-10" style={{ width: '100%' }}>
                  <div className="wd-50" style={{ border: 'gray dashed 0.1px' }}></div>
                </div>
              )}
              {isMultiple && idx === rows.length - 1 && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-primary pd-x-5 pd-y-0"
                  onClick={handleAddMore}
                >
                  <PlusSquare />
                  <SpanLang keyLang="Add" />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Website;
