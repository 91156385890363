import React, { useState } from 'react';
import { AsyncSelect } from '@base/components/form';

/**
 *
 * @param {*} props
 * @returns
 */
const Subsidiary = (props: any) => {
  const {
    value, //initial data: [{}, {}]
    onChange,
  } = props;

  //state
  const [options, setOptions] = useState<any[]>([]);

  //fetch data
  const fetchOptions = async (inputValue: string) => {
    let options: any[] = [];

    options.push({
      value: 'red',
      label: 'Red',
    });

    options.push({
      value: 'blue',
      label: 'Blue',
    });

    setOptions(options);
    return options;
  };

  //value change
  const handleSelectChange = (newValue: any) => {
    onChange && onChange(newValue);
  };

  //main render
  return (
    <div className="pos-relative w-100">
      <AsyncSelect
        isMulti={true}
        cacheOptions
        defaultOptions={options}
        value={value ? value : []} //[{label: "Customer Name 32", value: "Cust-ID-32"}]
        loadOptions={fetchOptions}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default Subsidiary;
