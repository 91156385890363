import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeAttachment from '@base/containers/attachment/time-attachment';
import DesktopLayout1ViewRight from '@base/layouts/desktop/layout1/view-right';

import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { MENU_CUSTOMER, MENU_SOURCE } from '@base/config/menus';

import CompanyInfo from '@customer/customer/containers/company-info';
import AssignCustomer from '@customer/customer/containers/assign-customer';
import AssignRep from '@customer/customer/containers/assign-rep';

// import Loyalty from '@marketing/loyalty/components/customer-loyalty';
import RelatedProduct from '@customer/customer/containers/related-product';

import * as keyNames from '@customer/customer/config/key-names';

interface IRightProps {
  id: string;
  category: string;
  name?: string;
  isShowCompanyInfo?: boolean;
}

const ViewRight: React.FC<IRightProps> = (props: IRightProps) => {
  const { id, name, category, isShowCompanyInfo = false } = props;
  const { t } = useTranslation();
  //recoil
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_CUSTOMER));
  //get account info if contact type is employee
  const basicFields = viewData?.layout?.data?.[0]?.children || [];
  const acountField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_ACCOUNT,
  );
  // const customerTypeField = basicFields?.find(
  //   (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_TYPE,
  // );
  const contactTypeField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE,
  );

  let rightConfig: any[] = [];
  // company info show condition: Contact && contact type Employee.
  if (isShowCompanyInfo) {
    rightConfig.push({
      title: 'Company Info',
      key: 'company',
      component: <CompanyInfo data={acountField?.data} />,
    });
  }

  if (category === 'account') {
    rightConfig.push({
      title: t('common_section_contact'),
      key: 'contact',
      component: <AssignCustomer placement="left" id={id} name={name} category={category} />,
    });
  }

  //general
  rightConfig.push(
    ...[
      {
        title: t('common_section_assigned_rep'),
        key: 'rep',
        component: <AssignRep placement="left" id={id} />,
      },

    ],
  );
  //custom
  if (contactTypeField?.data?.keyName != 'CONTACT_TYPE_EMPLOYEE') {
    rightConfig.push({
      title: 'Related Products',
      key: 'related_product',
      component: (
        <RelatedProduct id={id} placement="left" />
      ),
    });
  }
  rightConfig.push({
    title: t('common_section_attachment'),
    key: 'attachment',
    component: <TimeAttachment menuSource={MENU_SOURCE[MENU_CUSTOMER]} menuSourceId={id} listType="list" />,
  });

  //custom
  // if (contactTypeField?.data?.keyName != 'CONTACT_TYPE_EMPLOYEE' &&
  //   customerTypeField?.data?.keyName != 'TYPE_VENDOR'
  // ) {
  //   rightConfig.push({
  //     title: t('common_section_loyalty'),
  //     key: 'loyalty',
  //     component: <Loyalty />,
  //   });
  // }

  return <DesktopLayout1ViewRight rightConfig={rightConfig} />;
};

export default ViewRight;
