//v2
export const KEY_NAME_CUSTOMER_ID = 'id';
export const KEY_NAME_CUSTOMER_CODE = 'code';
export const KEY_NAME_CUSTOMER_NAME = 'name';
export const KEY_NAME_CUSTOMER_PHOTO = 'photo';
export const KEY_NAME_CUSTOMER_CATEGORY = 'category';
export const KEY_NAME_CUSTOMER_TYPE = 'type';
export const KEY_NAME_CUSTOMER_INDUSTRIES = 'industries';
export const KEY_NAME_CUSTOMER_PHONES = 'phones';
export const KEY_NAME_CUSTOMER_EMAIL = 'emails';
export const KEY_NAME_CUSTOMER_DESCRIPTION = 'memo';
export const KEY_NAME_CUSTOMER_PREFERRED = 'preferred';
export const KEY_NAME_CUSTOMER_BUSINESS_NUMBER = 'businessNumber';
export const KEY_NAME_CUSTOMER_SALES_COMMISSION = 'saleCommission';
export const KEY_NAME_CUSTOMER_FAX = 'faxes';
export const KEY_NAME_CUSTOMER_WEBSITES = 'websites';
export const KEY_NAME_CUSTOMER_ADDRESSES = 'addresses';
export const KEY_NAME_CUSTOMER_BILL_ADDRESSES = 'billAddresses';
export const KEY_NAME_CUSTOMER_SHIP_ADDRESSES = 'shipAddresses';
export const KEY_NAME_CUSTOMER_PARENT_ACCOUNT = 'parent';
export const KEY_NAME_CUSTOMER_MAIN_PRODUCT = 'flagshipProduct';
export const KEY_NAME_CUSTOMER_ANNUAL_REVENUE = 'annualRevenue';
export const KEY_NAME_CUSTOMER_EMPLOYEES_NUMBER = 'employeesNumber';
export const KEY_NAME_CUSTOMER_SLA = 'serviceLevelAgreement';
export const KEY_NAME_CUSTOMER_ASSIGN_TO = 'assignTo';

//Contact
export const KEY_NAME_CUSTOMER_CONTACT_TYPE = 'contactType';
export const KEY_NAME_CUSTOMER_EMPLOYEE_ROLE = 'employeeRole';
export const KEY_NAME_CUSTOMER_ACCOUNT = 'account'; //parent
export const KEY_NAME_CUSTOMER_MOBILE = 'mobiles';
export const KEY_NAME_CUSTOMER_GENDER = 'gender';
export const KEY_NAME_CUSTOMER_DEPARTMENT = 'department';
export const KEY_NAME_CUSTOMER_POSITION = 'position';
export const KEY_NAME_CUSTOMER_ANNIVERSARIES = 'anniversaries';

//system
export const KEY_NAME_CUSTOMER_CREATED_BY = 'createdBy';
export const KEY_NAME_CUSTOMER_CREATED_AT = 'createdAt';
export const KEY_NAME_CUSTOMER_UPDATED_BY = 'updatedBy';
export const KEY_NAME_CUSTOMER_UPDATED_AT = 'updatedAt';

export const KEY_NAME_CUSTOMER_CUSTOM_FIELDS = 'customFields'; //custom fields

//NEXT VERSION
export const KEY_NAME_CUSTOMER_CURRENT_DEBIT = 'currentDebit';
export const KEY_NAME_CUSTOMER_UNUSED_CREDIT = 'unusedCredit';
export const KEY_NAME_CUSTOMER_AMOUNT_RECEIVED = 'amountReceived';
export const KEY_NAME_CUSTOMER_OPENING_BALANCE = 'openingBalance';
export const KEY_NAME_CUSTOMER_CREDIT_LIMIT = 'creditLimit';
export const KEY_NAME_CUSTOMER_RECEIPT_TYPE = 'receiptType';
export const KEY_NAME_CUSTOMER_SEND_MODE = 'sendMode';
export const KEY_NAME_CUSTOMER_PAYMENT_TERM = 'paymentTerms';
export const KEY_NAME_CUSTOMER_CURRENCY = 'currency';
export const KEY_NAME_CUSTOMER_TAX = 'tax';
export const KEY_NAME_CUSTOMER_CHARGE_LATE_FEE = 'chargeLate';
export const KEY_NAME_CUSTOMER_REFERRAL_REWARD = 'referralReward';
export const KEY_NAME_CUSTOMER_SALES_PRODUCT = 'saleProducts';
export const KEY_NAME_CUSTOMER_RELATED_PRODUCT = 'relatedProducts';
export const KEY_NAME_CUSTOMER_RATING = 'rating';

//NOT USE
//export const KEY_NAME_CUSTOMER_ROLE = 'role';
//export const KEY_NAME_CUSTOMER_GROUPS = 'groups';
