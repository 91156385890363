import React from 'react';
import classnames from 'classnames';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';

const Edit: React.FC = (props: any) => {
  const { value = [], onChange = (params: any) => {}, errors = {}, componentProps } = props;

  return (
    <div className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <CustomerAutoComplete {...componentProps} value={value} onChange={onChange} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
