export const categoryToEnum = (category: string) => {
  switch (category) {
    case 'account':
      return 'CATEGORY_ACCOUNT';
    case 'contact':
      return 'CATEGORY_CONTACT';
    case 'employee':
      return 'CATEGORY_EMPLOYEE';
    default:
      return 'CATEGORY_NONE';
  }
};

export const formatAddress = (address: any) => {
  let strAddr = [];
  address?.street && strAddr.push(address?.street);
  address?.city && strAddr.push(address?.city);
  address?.state && strAddr.push(address?.state);
  address?.country?.country && strAddr.push(address?.country?.country);
  return `${strAddr.join(', ')} ${address?.zipcode || ''}`;
};
