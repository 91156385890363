import Timeline from '@base/containers/timeline';
import CustomerViewActivity from '@base/containers/activity';
import Tickets from '@customer/customer/containers/ticket';
// import TeamChannel from '@customer/customer/containers/teamchannel';
import Notes from '@base/containers/note';
import DetailView from '@customer/customer/containers/detail-view';

//return TABS
export const getAccountTabs = () => {
  return [
    {
      order: 1,
      default: true,
      label: 'Details',
      path: 'detail',
      tabComponent: DetailView,
      tabComponentProps: {
        category: 'account'
      }
    },
    {
      order: 2,
      label: 'Timeline',
      path: 'timeline',
      tabComponent: Timeline,
    },
    {
      order: 3,
      label: 'Activities',
      path: 'activity',
      tabComponent: CustomerViewActivity,
    },
    {
      order: 4,
      label: 'Tickets',
      path: 'tickets',
      tabComponent: Tickets,
    },
    {
      order: 5,
      label: 'Notes',
      path: 'note',
      tabComponent: Notes,
    },
    // {
    //   order: 8,
    //   label: 'TeamChannel',
    //   path: 'teamchannel',
    //   tabComponent: TeamChannel,
    // },
    // {
    //   order: 5,
    //   label: 'Transaction',
    //   path: 'transaction',
    //   tabComponent: CustomerViewTransaction,
    // },
    // {
    //   order: 6,
    //   label: 'Statement',
    //   path: 'statement',
    //   tabComponent: CustomerViewStatement,
    // },
    // {
    //   order: 7,
    //   label: 'Subscription',
    //   path: 'subscription',
    //   tabComponent: CustomerViewTradeAgreement, //TODO
    // },
    // {
    //   order: 10,
    //   label: 'Profile',
    //   path: 'profile',
    //   tabComponent: CustomerViewProfile,
    // },
    // {
    //   order: 11,
    //   label: 'Purchase',
    //   path: 'purchase',
    //   tabComponent: CustomerPurchase,
    // },
  ];
};

export const getContactTabs = () => {
  return [
    {
      order: 1,
      default: true,
      label: 'Details',
      path: 'detail',
      tabComponent: DetailView,
      tabComponentProps: {
        category: 'contact'
      }
    },
    {
      order: 2,
      label: 'Timeline',
      path: 'timeline',
      tabComponent: Timeline,
    },
    {
      order: 3,
      label: 'Activities',
      path: 'activity',
      tabComponent: CustomerViewActivity,
    },
    {
      order: 4,
      label: 'Tickets',
      path: 'tickets',
      tabComponent: Tickets,
    },
    {
      order: 5,
      label: 'Notes',
      path: 'note',
      tabComponent: Notes,
    },
    // {
    //   order: 5,
    //   label: 'TeamChannel',
    //   path: 'teamchannel',
    //   tabComponent: TeamChannel,
    // },
    // {
    //   order: 5,
    //   label: 'Transaction',
    //   path: 'transaction',
    //   tabComponent: CustomerViewTransaction,
    // },
    // {
    //   order: 6,
    //   label: 'Statement',
    //   path: 'statement',
    //   tabComponent: CustomerViewStatement,
    // },
    // {
    //   order: 7,
    //   label: 'Subscription',
    //   path: 'subscription',
    //   tabComponent: CustomerViewTradeAgreement, //TODO
    // },
    // {
    //   order: 10,
    //   label: 'Profile',
    //   path: 'profile',
    //   tabComponent: CustomerViewProfile,
    // },
    // {
    //   order: 11,
    //   label: 'Purchase',
    //   path: 'purchase',
    //   tabComponent: CustomerPurchase,
    // },
  ];
};