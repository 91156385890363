import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from '@base/components/form';
import { Canvas, Portal, NoData } from '@base/components';
import { getCountryCode, getAddressesBaseType } from './utils';
import { formatAddress } from '@customer/main/utils';
import AddressItem from './address-item';
import AddressAddModal from '../address-add-modal';
//import { UPDATE_CUSTOMER_FIELD } from 'Customer/Modules/Main/stores/Query';
//import { getCustomerInfo, getCustomerInfoByContact } from 'Crm/Modules/Main/store/api';
import { useCustomer } from '@customer/customer/services/view-service';

export const AddressType = {
  billTo: 'billTo',
  shipTo: 'shipTo',
};

export const Category = {
  account: 'account',
  contact: 'contact',
};

export const DataType: any = {
  billTo: {
    label: 'Bill To',
    idType: '4', //Test '1'
  },
  shipTo: {
    label: 'Ship To',
    idType: '5',
  },
};

const BillToOrShipTo: React.FC<any> = (props: any) => {
  const {
    defaultOptions = null, //addresses list
    id = '', //cusomter id
    value = null,
    type = AddressType.billTo,
    category = Category.contact,
    onChange,
  } = props;

  //state
  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [addresses, setAddresses] = useState<any>([]); //customer addresses
  const [selectedAddress, setSelectedAddress] = useState<any>({}); //selected address
  const [checkedAddress, setCheckedAddress] = useState<any>({}); //checked address
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [addressEdit, setAddressEdit] = useState<any>({});
  const { idType, label } = useMemo(() => DataType?.[type] ?? {}, [type]);

  //hook get data
  const postResult = useCustomer(id, defaultOptions);
  //// console.log('customerPost', postResult);

  //init value
  useEffect(() => {
    if (value) {
      if (value?.id !== selectedAddress?.id) {
        setSelectedAddress(value);
        setCheckedAddress(value);
      }
    }
  }, [value]);

  //get addresses
  const getAddresses = () => {
    if (id) {
      let addresses: any = [];
      if (defaultOptions) {
        addresses = defaultOptions;
      } else {
        addresses = postResult?.data?.addresses?.map((item) => ({
          ...item,
          country: getCountryCode(item),
        }));
      }
      const data = getAddressesBaseType(addresses, idType);
      setAddresses(data?.addresses || []);
    }
  };

  //change address
  const handleChange = () => {
    setSelectedAddress(checkedAddress);
    //callback
    onChange && onChange(checkedAddress);
    //close
    setIsOpenCanvas(false);
  };

  //new/edit selected address
  const handleAddOrEdit = (item: any) => {
    if (item) {
      //EDIT
      setAddressEdit(item); //convertItemToAddress(item)
    } else {
      setAddressEdit(null);
    }
    setIsOpenEdit(true);
  };

  //update editing address
  const handeSaveSuccess = (item: any) => {
    if (item.id) {
      // EDIT CASE
      // if (result?.success) {
      //   setAddresses(
      //     addresses.map((address) => (item?.id == address?.id ? { ...address, ...item } : address)),
      //   );
      //   closeModal();
      // }
    } else {
      // NEW CASE
      // let address = {};
      // try {
      //   const rows = JSON.parse(result?.rows);
      //   address = rows?.[0];
      // } catch (error) { }
      // if (result?.success) {
      //   setAddresses(addresses.concat({ ...item, ...address }));
      //   closeModal();
      // }
    }
  };

  //open canvas to select select
  const handleSelect = () => {
    setIsOpenCanvas(true);
    if (id) {
      getAddresses();
    }
  };

  //render
  return (
    <div className="pos-relative">
      <div className="form-group col-12">
        <div className="row row-xs">
          <div className="col-12">
            {selectedAddress?.id ? (
              <>
                <span className="badge badge-dark mg-r-5 tx-normal tx-13">{label}</span>
                {formatAddress(selectedAddress)}
              </>
            ) : (
              <span>Empty</span>
            )}
            <Button color="link" className="mg-l-5" onClick={() => handleSelect()}>
              Change
            </Button>
          </div>
        </div>
      </div>

      {isOpenCanvas && (
        <Portal>
          <Canvas
            key={'billtoshipto'}
            isVisible={isOpenCanvas}
            onCloseSideBar={() => setIsOpenCanvas(false)}
            className={'page-sidebar-container-wrap'}
            customStyles={{ zIndex: '1052' }}
            backdropStyles={{ zIndex: '1051' }}
          >
            <Canvas.Header
              title={label}
              closeBtn={
                <Button
                  onClick={() => handleAddOrEdit(null)}
                  className="pd-0"
                  color=""
                  icon="Plus"
                  iconSize={18}
                />
              }
            />
            <Canvas.Body className="wd-400">
              {isOpenEdit && (
                <AddressAddModal
                  id={id}
                  value={addressEdit}
                  labelId={idType}
                  category={category}
                  onClose={() => setIsOpenEdit(false)}
                  onSuccess={handeSaveSuccess}
                />
              )}
              {postResult.isFetching && (
                <div className="wrap-hanloading">
                  <div className="han-loading" />
                </div>
              )}
              {!postResult.isFetching && addresses.length === 0 && (
                <NoData label="No address available." />
              )}
              {!postResult.isFetching &&
                addresses.length > 0 &&
                addresses.map((item: any) => (
                  <AddressItem
                    key={item.id}
                    address={item}
                    checked={checkedAddress?.id == item.id}
                    onChoose={() => {
                      setCheckedAddress(item);
                      setIsOpenEdit(false);
                    }}
                    onEdit={() => handleAddOrEdit(item)}
                  />
                ))}
            </Canvas.Body>
            <Canvas.Footer
              onClose={() => setIsOpenCanvas(false)}
              componentRight={
                <Button className="mg-l-5" color="success" onClick={() => handleChange()}>
                  Change
                </Button>
              }
            />
          </Canvas>
        </Portal>
      )}
    </div>
  );
};

export default BillToOrShipTo;
