import * as keyNames from '@customer/customer/config/key-names';
import * as components from '@customer/customer/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import BaseConfigs from '@customer/customer/config/write-field';

export default {
  ...BaseConfigs,
  //contact fields
  [keyNames.KEY_NAME_CUSTOMER_CODE]: {
    component: components.InputCodeGenerator,
    componentProps: {
      menu: 'contact',
      showType: 'popover',
    },
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE]: {
    component: components.DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'contact_type',
      sourceType: 'field',
    },
    showFullRow: true,
    validate: {
      required: validators.required,
    },
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value?.keyName || 'CONTACT_TYPE_NONE',
  },
  [keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE]: {
    component: components.DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'employee_role',
      sourceType: 'field',
    },
    showFullRow: true,
    validate: {},
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value?.keyName,
  },
  [keyNames.KEY_NAME_CUSTOMER_ACCOUNT]: {
    component: components.CustomerAutoComplete,
    componentProps: {
      single: true,
      type: 'account',
    },
    showFullRow: true,
    validate: {},
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => ({
      id: value.id,
      name: value.name,
    }),
  },
  [keyNames.KEY_NAME_CUSTOMER_POSITION]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_DEPARTMENT]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
};
