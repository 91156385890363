import React, { useEffect, useState } from 'react';
import { RadioGroup, SpanLang } from '@base/components/form';
import { SEND_MODE_OPTIONS } from '@customer/customer/config/constants';

const SendMode: React.FC<any> = (props: any) => {
  const { value, onChange } = props;

  //state
  const [selectedValue, setSelectedValue] = useState<any>('');

  //init data
  useEffect(() => {
    if (value) {
      if (value !== selectedValue) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(SEND_MODE_OPTIONS[0].value);
    }
  }, [value]);

  const handleValueChange = (newOption: any) => {
    setSelectedValue(newOption.value);
    //callback
    onChange && onChange(newOption.value);
  };

  return (
    <RadioGroup
      // getOptionLabel={(option: any) => <SpanLang keyLang={option?.label} isHtml={true} />}
      isVertical={true}
      options={SEND_MODE_OPTIONS}
      value={SEND_MODE_OPTIONS.find((_ele: any) => _ele.value === selectedValue)}
      onChange={handleValueChange}
    />
  );
};

export default SendMode;
