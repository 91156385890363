import React, { useEffect, useState } from 'react';
import { CustomerAutoComplete } from '@customer/customer/config/write-field/components';
import { CUSTOMER_CATEGORY_CONTACT } from '@customer/customer/config/constants';

/**
 *
 * @param {*} props
 * @returns
 */
const RelatedContact = (props: any) => {
  const {
    value, //initial data: [{}, {}]
    onChange,
  } = props;

  //state
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  //set options
  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value]);

  //add new item to list
  const handleAfterSave = (data: any) => {
    const newValue: any = { value: data.id, label: data.name };

    setSelectedOptions(newValue);
    onChange && onChange(newValue);
  };

  //value change
  const handleSelectChange = (newValue: React.SetStateAction<any[]>) => {
    setSelectedOptions(newValue);
    onChange && onChange(newValue);
  };

  //main render
  return (
    <div className="pos-relative w-100">
      <CustomerAutoComplete
        category={CUSTOMER_CATEGORY_CONTACT}
        placement="top"
        single={false}
        value={selectedOptions}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default RelatedContact;
