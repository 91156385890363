import React from 'react';
import { DataSourceSelect } from '@base/containers';

const Industry: React.FC = (props: any) => {
  const { value, onChange } = props;

  return (
    <DataSourceSelect
      sourceKey="industry"
      sourceType="field" //group
      single={false}
      value={value}
      onChange={onChange}
    />
  );
};

export default Industry;
