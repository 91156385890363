import React from 'react';
import { formatAddress } from './utils';
import { Button } from '@base/components/form';

const AddressItem: React.FC<any> = (props: any) => {
  const { address, checked = false, onChoose, onEdit } = props;
  return (
    <div className="card mg-b-20">
      <div className="card-header" style={{ cursor: 'pointer' }}>
        <div className="d-flex wd-100p">
          <div className="custom-control custom-radio" style={{ flex: 1 }}>
            <input
              type="radio"
              id={`addressRadio_${address.id}`}
              className="custom-control-input"
              checked={checked}
              onChange={onChoose}
            />
            <label className="custom-control-label" htmlFor={`addressRadio_${address.id}`}>
              Billing
            </label>
          </div>
          <Button className="pd-0" color="" icon="Edit2" iconSize={14} onClick={onEdit} />
        </div>
      </div>
      <div className="card-body bg-gray-100">
        <span>{formatAddress(address)}</span>
      </div>
    </div>
  );
};

export default AddressItem;
