import React, { useEffect, useState } from 'react';
import AddressItem from '@customer/customer/components/address/address';
import { Button } from '@base/components/form';

const ShipBillAddress: React.FC<any> = (props) => {
  const { value, onChange } = props;

  //state
  const [shippingAddress, setShippingAddress] = useState<any>(null);
  const [billingAddress, setBillingAddress] = useState<any>(null);

  //init value
  useEffect(() => {
    if (value) {
      if (value?.shipping) {
        setShippingAddress(value.shipping);
      }
      if (value?.billing) {
        setBillingAddress(value.billing);
      }
    } else {
      setShippingAddress(null);
      setBillingAddress(null);
    }
  }, [value]);

  //value change
  const handleAddressChange = (type: string, newValue: any) => {
    if (type === 'shipping') {
      setShippingAddress(newValue);
      //callback
      onChange && onChange({ shipping: newValue, billing: billingAddress });
    }
    if (type === 'billing') {
      setBillingAddress(newValue);
      //callback
      onChange && onChange({ shipping: shippingAddress, billing: newValue });
    }
  }

  //copy
  const handleAddressCopy = () => {
    setShippingAddress(billingAddress);
    //callback
    onChange && onChange({ shipping: billingAddress, billing: billingAddress });
  }

  return (
    <div className='d-flex flex-wrap'>
      <div className='d-flex flex-1 flex-column mg-5' style={{ minWidth: "350px" }}>
        <div className="form-item-title mg-b-10">
          <span className="pd-l-5">Billing Address</span>
        </div>
        <AddressItem
          key={'billing'}
          value={billingAddress}
          onChange={(newValue: any) => handleAddressChange('billing', newValue)}
        />
      </div>
      <div className='d-flex flex-grow-1 flex-column mg-5' style={{ minWidth: "350px" }}>
        <div className="form-item-title mg-b-10">
          <span className="pd-l-5">Shipping Address</span>
          <Button
            color='link'
            className="link-03"
            icon='Copy'
            tooltip='Copy Billing Address'
            onClick={handleAddressCopy}
          />
        </div>
        <AddressItem
          key={'shipping'}
          value={shippingAddress}
          onChange={(newValue: any) => handleAddressChange('shipping', newValue)}
        />
      </div>
    </div>
  );
}

export default ShipBillAddress;