import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { CUSTOMER_MENUS } from '@base/config/route-menus';
import { IDestopLayout1ViewProps } from '@base/layouts/desktop/layout1/view';
import { deviceAtom, viewDataByMenuAtom } from '@base/recoil/atoms';
import { mergeLayoutData } from '@base/utils/helpers/page-layout-utils';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { buildViewSchema } from '@base/utils/helpers/schema';
import { useGetView } from '@base/services/view';
import BaseLayoutDisplay from '@base/containers/base-layout-display';
import displayLayoutConfig from '@customer/customer/config/display-layout';
import { useTranslation } from 'react-i18next';
import { usePageLayoutByMenu } from '@base/hooks/usePageLayout';
import { MENU_CUSTOMER } from '@base/config/menus';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import Title from '@base/containers/view-field/title';
import {
  KEY_NAME_CUSTOMER_CONTACT_TYPE,
  KEY_NAME_CUSTOMER_NAME,
} from '@customer/customer/config/key-names';
import ViewLeft from '@customer/customer/layouts/desktop/layout1/view/view-left';
import ViewRight from '@customer/customer/layouts/desktop/layout1/view/view-right';
import { default as viewConfig } from '@customer/customer/config/view-field';
import {
  customerListFilterState,
  customerWriteOptionAtom,
} from '@customer/customer/recoil/atom/list-atom'; //for write
import { getAccountTabs, getContactTabs } from './tabs';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { CUSTOMER_CLONE, CUSTOMER_DELETE } from '@customer/customer/services/graphql';
import CustomerMergeModal from '@customer/customer/containers/customer-merge-modal';
import PrintPreview from '@customer/customer/containers/print-preview';
import WriteModal from '../write/write-modal';

interface ICustomerViewProps {
  isSplitMode?: boolean;
}

/**
 * Pages: detect layout, device,  init props
 */
const ViewPage: React.FC<ICustomerViewProps> = (props: ICustomerViewProps) => {
  const { t } = useTranslation();
  const { isSplitMode } = props;
  const params = useParams();
  const navigate = useNavigate();
  //get category
  let routerCategory = 'all';
  let customerCategory = 'account';
  const matchAll = useMatch('/customer/all/*');
  const matchAccount = useMatch('/customer/account/*');
  const matchContact = useMatch('/customer/contact/*');
  if (matchAll) {
    routerCategory = 'all';
    customerCategory = params.category || '';
  }
  if (matchAccount) {
    routerCategory = 'account';
    customerCategory = 'account';
  }
  if (matchContact) {
    routerCategory = 'contact';
    customerCategory = 'contact';
  }
  //state
  const [{ device, layout = 'layout1' }] = useRecoilState(deviceAtom);
  //const [selectedCustType, setSelectedCustType] = useState<any>(null);
  //recoil
  const listFilter = useRecoilValue(customerListFilterState);
  const { activeId, activeTab } = listFilter;

  let customerId = activeId != params.id ? params.id ?? '' : activeId ?? '';
  let currentTab = activeTab != params['*'] ? params['*'] : activeTab ?? '';

  const [viewData, setViewData] = useRecoilState(viewDataByMenuAtom(MENU_CUSTOMER));
  const setWriteOption = useSetRecoilState(customerWriteOptionAtom); //for write page
  const [showMerge, setShowMerge] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  /** HOOK: delete */
  const mutationDelete: any = useMutationPost<BaseMutationResponse>(
    CUSTOMER_DELETE,
    'customer_deleteCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        //// console.log('after save', context);
        //toast.success('Deleted customer successfully!');
        //go to list
        navigate(`/${MENU_CUSTOMER}/${customerCategory}`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('Deleted customer failed: ' + JSON.parse(error).message);
      },
    },
  );

  /** HOOK: clone */
  const mutationClone: any = useMutationPost<BaseMutationResponse>(
    CUSTOMER_CLONE,
    'customer_copyCustomer',
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // console.log('success data', data);
        //go to view
        navigate(`/${MENU_CUSTOMER}/${customerCategory}/${data.id}`);
      },
      onError: (error: any, variables: any, context: any) => {
        // An error happened!
        //// console.log('mutation error', error);
        toast.error('There is an error during cloning: ' + JSON.parse(error).message);
      },
    },
  );

  /** HOOK Get data */
  const layoutMenu = `${MENU_CUSTOMER}_${customerCategory}`; //for view: just account or contact
  const { data: layoutView, isLoading: isLayoutLoading } = usePageLayoutByMenu(layoutMenu, 'view');
  let viewSchema = buildViewSchema({ sections: layoutView?.data, configFields: viewConfig });
  const { data: customerData, isFetching: isDataLoading, refetch } = useGetView<any>({
    menu: 'customer_customer',
    schemas: viewSchema,
    params: { id: customerId },
  });

  //save recoil data
  useEffect(() => {
    if (!isLayoutLoading && !isDataLoading && customerData) {
      const layoutData = mergeLayoutData(layoutView, customerData, viewConfig);
      let newLayoutData: any = { ...layoutData };
      newLayoutData.menuSourceId = customerId;
      newLayoutData.menuSource = MENU_CUSTOMER;
      setViewData(newLayoutData);
    }
  }, [isLayoutLoading, isDataLoading, customerData]);

  //open add customer modal
  // const handleOpenAddCustomer = (customerType: string) => {
  //   switch (customerType) {
  //     case 'TYPE_CUSTOMER':
  //       setSelectedCustType({
  //         value: 'TYPE_CUSTOMER',
  //         label: t('options_items__TYPE_CUSTOMER_23372166_9847_4696_95fb_c4c90c5beafb'),
  //       });
  //       break;
  //     case 'TYPE_VENDOR':
  //       setSelectedCustType({
  //         value: 'TYPE_VENDOR',
  //         label: t('options_items__TYPE_VENDOR_23372167_e73c_44a6_bce6_79c04a00ebd3'),
  //       });
  //       break;
  //     case 'TYPE_SALES_AGENT':
  //       setSelectedCustType({
  //         value: 'TYPE_SALES_AGENT',
  //         label: t('options_items__TYPE_SALES_AGENT_23372168_871a_4575_a5a7_c3ec577f37d1'),
  //       });
  //       break;
  //     case 'TYPE_PARTNER':
  //       setSelectedCustType({
  //         value: 'TYPE_PARTNER',
  //         label: t('options_items__TYPE_PARTNER_23372169_2c85_4529_9b4b_86569cd32603'),
  //       });
  //       break;
  //   }
  //   setWriteOption((filter) => ({
  //     ...filter,
  //     isOpenWrite: true,
  //     writeType: customerCategory,
  //     writeMenu: 'view',
  //   }));
  // };

  //render
  const ViewRender = useMemo(() => {
    const basicFields = viewData?.layout?.data?.[0]?.children || [];
    const nameField = basicFields?.find(
      (_field: any) => _field?.keyName === KEY_NAME_CUSTOMER_NAME,
    );
    const pageTitle = nameField?.data || '';
    // const ratingField = basicFields?.find(
    //   (_field: any) => _field?.keyName === KEY_NAME_CUSTOMER_RATING,
    // );
    // const customerTypeField = basicFields?.find(
    //   (_field: any) => _field?.keyName === KEY_NAME_CUSTOMER_TYPE,
    // );
    const contactTypeField = basicFields?.find(
      (_field: any) => _field?.keyName === KEY_NAME_CUSTOMER_CONTACT_TYPE,
    );
    let isEmployee =
      customerCategory === 'contact' && contactTypeField?.data?.keyName === 'CONTACT_TYPE_EMPLOYEE'
        ? true
        : false;

    //actions on more buttons
    // let addActions: any[] = [
    //   {
    //     icon: 'User',
    //     label: 'Add as Customer',
    //     type: 'TYPE_CUSTOMER',
    //     onClick: () => handleOpenAddCustomer('TYPE_CUSTOMER'),
    //   },
    //   {
    //     icon: 'User',
    //     label: 'Add as Vendor',
    //     type: 'TYPE_VENDOR',
    //     onClick: () => handleOpenAddCustomer('TYPE_VENDOR'),
    //   },
    //   {
    //     icon: 'User',
    //     label: 'Add as Sales Agent',
    //     type: 'TYPE_SALES_AGENT',
    //     onClick: () => handleOpenAddCustomer('TYPE_SALES_AGENT'),
    //   },
    //   {
    //     icon: 'User',
    //     label: 'Add as Partner',
    //     type: 'TYPE_PARTNER',
    //     onClick: () => handleOpenAddCustomer('TYPE_PARTNER'),
    //   },
    // ];
    // let moreActions: any[] = addActions.filter(
    //   (_ele: any) => _ele.type != customerTypeField?.data?.keyName,
    // );
    let moreActions: any = [];
    moreActions.push({
      icon: 'merge',
      iconType: 'common',
      label: 'Merge',
      onClick: () => setShowMerge(true),
    });
    const buttonActionProps = {
      //onExportToPDF: () => null, //TODO
      onPrint: () => setShowPrint(true),
      onDelete: () => mutationDelete.mutate({ ids: [customerId] }),
      isDeleting: mutationDelete.isLoading || mutationClone.isLoading,
      onClone: () => mutationClone.mutate({ id: customerId }),
      moreActions: moreActions, //isEmployee ? [] : moreActions,
      onNew: (category: string | undefined) => {
        //setSelectedCustType(null);
        setWriteOption((filter) => ({
          ...filter,
          isOpenWrite: true,
          writeType: customerCategory,
          writeMenu: 'view',
        }));
      },
    };

    //page props
    let pageProps: IDestopLayout1ViewProps = {
      isLoading: isDataLoading,
      headerProps: {
        splitViewMode: isSplitMode,
        menu: routerCategory,
        menus: CUSTOMER_MENUS,
        category: customerCategory,
        ...buttonActionProps,
        componentLeft: (
          <>
            {/* {ratingField ? (
              <LogoRanking
                className="mg-l-10"
                value={{ label: ratingField ? t(ratingField.languageKey) : '' }}
              />
            ) : (
              ''
            )} */}
            <Title
              value={pageTitle}
              userPermission={{ isEdit: true }}
              keyName={KEY_NAME_CUSTOMER_NAME}
              menuSourceId={customerId}
              menuSource={MENU_CUSTOMER}
              viewConfig={viewConfig}
            />
          </>
        ),
        onRefresh: refetch
      },
      centerProps: {
        category: customerCategory,
        menuSource: MENU_CUSTOMER,
        menuSourceId: customerId,
        tabs: customerCategory == 'account' ? getAccountTabs() : getContactTabs(),
        currentTab: currentTab,
        showTabs: 10
      },
      componentLeft: <ViewLeft id={customerId} category={customerCategory} />,
      leftProps: {},
      componentRight: (
        <ViewRight
          id={customerId}
          name={pageTitle}
          category={customerCategory}
          isShowCompanyInfo={isEmployee} //just show component info for employee
        />
      ),
      rightProps: {},
    };

    return (
      <BaseLayoutDisplay
        displayConfig={displayLayoutConfig}
        device={device}
        layout={layout}
        form="view"
        {...pageProps}
      />
    );
  }, [viewData, isSplitMode, currentTab]);

  //render
  return (
    <>
      {ViewRender}
      <WriteModal
        writeMenu='view'
        //customerType={selectedCustType}
        refetch={refetch}
      />
      {showMerge && (
        <CustomerMergeModal
          title='Merge Customer'
          isOpen={showMerge}
          defaultItems={[]}
          category={customerCategory}
          customer={customerData}
          onReload={refetch}
          onClose={() => setShowMerge(false)}
        />
      )}
      {showPrint && (
        <PrintPreview
          title='Customer Print Preview'
          isOpen={showPrint}
          data={viewData}
          onClose={() => setShowPrint(false)}
        />
      )}
    </>
  );
};

export default ViewPage;
