import React, { useEffect, useState } from "react";
import {
  KEY_NAME_CUSTOMER_ADDRESSES,
  KEY_NAME_CUSTOMER_EMAIL,
  KEY_NAME_CUSTOMER_NAME,
  KEY_NAME_CUSTOMER_PHONES,
} from '@customer/customer/config/key-names';
import { LABEL_VALUE_PRIMARY } from "@customer/customer/config/constants";
import { NoData } from "@base/components";
import ListTableCellDroplist from "@base/components/list/list-table-cell-droplist";
import { formatAddress } from '@customer/main/utils';

interface MergeTableProps {
  category: string;
  isMergeAll?: boolean;
  value: any;
  master: any;
  onChange?: (val: any) => void;
  onMasterChange?: (val: any) => void;
}

const MergeTable = (props: MergeTableProps) => {
  const {
    category,
    isMergeAll = false,
    value = [],
    master,
    onChange,
    onMasterChange
  } = props;
  //state
  const [mergeMaster, setMergeMaster] = useState<any>(null);
  const [mergeItems, setMergeItems] = useState<any>([]);

  //init data
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(mergeItems)) {
        const newItems = value.map((_ele: any) => ({ ..._ele, mergeFields: [] }));
        setMergeItems(newItems);
      }
    } else {
      setMergeItems([]);
    }
  }, [value]);

  useEffect(() => {
    if (master) {
      if (JSON.stringify(master) !== JSON.stringify(mergeMaster)) {
        setMergeMaster(master);
      }
    } else {
      setMergeMaster(null);
    }
  }, [master]);

  //check change
  const handleMergeCheckChange = (index: number, keyName: string) => {
    const newMergeItems = [...mergeItems];
    const isExisted = newMergeItems[index].mergeFields.indexOf(keyName);
    if (isExisted > -1) {
      newMergeItems[index].mergeFields.splice(isExisted, 1);
    } else {
      newMergeItems[index].mergeFields.push(keyName);
    }
    setMergeItems(newMergeItems);
    //callback
    onChange && onChange(newMergeItems);
  };

  return (
    <div className="form-group">
      <div className="table-responsive bd rounded">
        <table className="table table-bordered bd-0 mg-b-0">
          <thead>
            <tr>
              <th scope="col" className="bd-t-0-f text-nowrap">
                Master
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap">
                {category === 'account' ? 'Account ' : 'Contact '} Name
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap">
                Email
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap">
                Phone
              </th>
              <th scope="col" className="bd-t-0-f text-nowrap">
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            {mergeItems.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <NoData />
                </td>
              </tr>
            )}
            {mergeItems.length > 0 &&
              mergeItems.map((_item: any, index: number) => {
                let emails: any = [];
                _item[KEY_NAME_CUSTOMER_EMAIL]?.map((_ele: any) => {
                  if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
                    emails.unshift({ ..._ele, name: _ele.email });
                  } else {
                    emails.push({ ..._ele, name: _ele.email });
                  }
                });
                let phones: any = [];
                _item[KEY_NAME_CUSTOMER_PHONES]?.map((_ele: any) => {
                  if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
                    phones.unshift({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
                  } else {
                    phones.push({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
                  }
                });

                const billAddress =
                  _item?.billAddresses?.length > 0 ? _item.billAddresses[0] : null;
                const shipAddress =
                  _item?.shipAddresses?.length > 0 ? _item.shipAddresses[0] : null;

                return (
                  <tr key={index}>
                    <td className="text-center">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="mergeMasterRadio"
                          id={`mergeMasterRadio${index}`}
                          //value="master1"
                          checked={mergeMaster.id === _item.id}
                          onChange={(e) => {
                            setMergeMaster(_item);
                            onMasterChange && onMasterChange(_item);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`mergeMasterRadio${index}`}
                        />
                      </div>
                    </td>
                    <td className="text-nowrap">{_item[KEY_NAME_CUSTOMER_NAME]}</td>
                    <td className="text-nowrap">
                      {!(isMergeAll || mergeMaster.id === _item.id) && (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`emailCheck_${index}`}
                            checked={_item.mergeFields.includes(KEY_NAME_CUSTOMER_EMAIL)}
                            onChange={() =>
                              handleMergeCheckChange(index, KEY_NAME_CUSTOMER_EMAIL)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`emailCheck_${index}`}
                          >
                            <ListTableCellDroplist
                              className="no-padding cursor-default"
                              //showAvatar={true}
                              values={emails}
                            />
                          </label>
                        </div>
                      )}
                      {(isMergeAll || mergeMaster.id === _item.id) && (
                        <ListTableCellDroplist
                          className="no-padding cursor-default"
                          //showAvatar={true}
                          values={emails}
                        />
                      )}
                    </td>
                    <td className="text-nowrap">
                      {!(isMergeAll || mergeMaster.id === _item.id) && (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`phoneCheck_${index}`}
                            checked={_item.mergeFields.includes(KEY_NAME_CUSTOMER_PHONES)}
                            onChange={() =>
                              handleMergeCheckChange(index, KEY_NAME_CUSTOMER_PHONES)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`phoneCheck_${index}`}
                          >
                            <ListTableCellDroplist
                              className="no-padding cursor-default"
                              //showAvatar={true}
                              values={phones}
                            />
                          </label>
                        </div>
                      )}
                      {(isMergeAll || mergeMaster.id === _item.id) && (
                        <ListTableCellDroplist
                          className="no-padding cursor-default"
                          //showAvatar={true}
                          values={phones}
                        />
                      )}
                    </td>
                    <td className="text-nowrap">
                      {!(isMergeAll || mergeMaster.id === _item.id) && (
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`addressCheck_${index}`}
                            checked={_item.mergeFields.includes(KEY_NAME_CUSTOMER_ADDRESSES)}
                            onChange={() =>
                              handleMergeCheckChange(index, KEY_NAME_CUSTOMER_ADDRESSES)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`addressCheck_${index}`}
                          >
                            <div>
                              <span className="tx-gray-500">Billing Address: </span>{' '}
                              {billAddress ? formatAddress(billAddress) : '(none)'}
                            </div>
                            <div>
                              <span className="tx-gray-500">Shipping Address: </span>{' '}
                              {shipAddress ? formatAddress(shipAddress) : '(none)'}
                            </div>
                          </label>
                        </div>
                      )}
                      {(isMergeAll || mergeMaster.id === _item.id) && (
                        <>
                          <div>
                            <span className="tx-gray-500">Billing Address: </span>{' '}
                            {billAddress ? formatAddress(billAddress) : '(none)'}
                          </div>
                          <div>
                            <span className="tx-gray-500">Shipping Address: </span>{' '}
                            {shipAddress ? formatAddress(shipAddress) : '(none)'}
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ul className="list-unstyled mg-t-10 mg-b-0 pd-0">
        <li className="mg-b-5 text-muted">
          - Select the master account to whom the account(s) should be merged and check the
          records to merge to the master.
        </li>
        <li className="text-muted">
          - Once merged, all the Activities, Notes, Attachments, Contact, Opportunities,
          Tickets and transactions are transrered to the master account.
        </li>
      </ul>
    </div>
  );
}

export default MergeTable;