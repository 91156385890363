import { atom } from 'recoil';
//import { ICustomerModel } from '@customer/customer/types/interface/customer';
import { DESC } from '@base/config/constant';
import { ListType } from '@base/types/enums';
import { PAGING_ITEMS_PER_PAGE } from '@customer/customer/config/constants';

export const customerListState = atom({
  key: 'CustomerList',
  default: [],
});

export const customerListFilterState = atom({
  key: 'CustomerListFilter',
  default: {
    isSplitMode: false,
    activeMenu: 'account',
    activeId: '',
    activeTab: '',
    listType: ListType.LIST,
    settingColumns: {
      all: [],
      account: [],
      contact: [],
    } as any, //for viewing on table
    filter: {
      /* query definition:  
          ONE PARAMETER: { [keyName]: { value: '', operator: ''} }
            *with operator is : (like), = (equal), ...
          MANY PARAMETERS: { condition: OR | AND, criteria: [ { [keyName]: { value: '', operator: ''} }, ... ] } 
        */
      all: {
        //groupByFilters: {}, //NOT USE
        //dateFilters: {}, //NOT USE
        //headerFilters: {}, //NOT USE
        //searchFilters: {},
        //keyword: '',
        query: {} as any,
        paging: {
          page: 1,
          size: PAGING_ITEMS_PER_PAGE,
        },
        sort: {
          field: 'createdAt',
          orderBy: DESC,
        },
      },
      account: {
        //groupByFilters: {}, //NOT USE
        //dateFilters: {}, //NOT USE
        //headerFilters: {}, //NOT USE
        //searchFilters: {},
        //keyword: '',
        query: {} as any,
        paging: {
          page: 1,
          size: 15,
        },
        sort: {
          field: 'createdAt',
          orderBy: DESC,
        },
      },
      contact: {
        //groupByFilters: {}, //NOT USE
        //dateFilters: {}, //NOT USE
        //headerFilters: {}, //NOT USE
        //searchFilters: {},
        //keyword: '',
        query: {} as any,
        paging: {
          page: 1,
          size: 15,
        },
        sort: {
          field: 'createdAt',
          orderBy: DESC,
        },
      },
    } as any,
  },
});

export const customerWriteOptionAtom = atom({
  key: 'customerWriteOptionAtom',
  default: {
    writeType: '', //'acount', 'contact'
    writeMenu: 'list', //'list', 'view', ....
    isOpenWrite: false,
  },
});
