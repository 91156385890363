import * as keyNames from '@customer/customer/config/key-names';
import * as components from '@customer/customer/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import BaseConfigs from '@customer/customer/config/write-field';

export default {
  ...BaseConfigs,
  //account fields
  [keyNames.KEY_NAME_CUSTOMER_CODE]: {
    component: components.InputCodeGenerator,
    componentProps: {
      menu: 'account',
      showType: 'popover',
    },
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    //parseValue: (valueApi: string) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_EMPLOYEES_NUMBER]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: string) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_BUSINESS_NUMBER]: {
    component: components.Input,
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: string) => value,
  },

  // TODO - not on WRITE FORM - FOR later-On
  [keyNames.KEY_NAME_CUSTOMER_PREFERRED]: {
    component: components.Input, //--> Checkbox
    validate: {},
    defaultValue: false,
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: boolean) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION]: {
    component: components.Input, //--> Select
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_PARENT_ACCOUNT]: {
    component: components.Input, //--> CustomerAutoComplete (category=account)
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_SLA]: {
    component: components.Input, //--> select
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_FAX]: {
    component: components.Input, //--> Select
    validate: {},
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },

  // [keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_PAYMENT_TERM]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_CURRENCY]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_TAX]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_CHARGE_LATE_FEE]: {
  //   component: components.Input, //--> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },

  // [keyNames.KEY_NAME_CUSTOMER_OPENING_BALANCE]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_CREDIT_LIMIT]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_RECEIPT_TYPE]: {
  //   component: components.Input, // ---> Select
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_SEND_MODE]: {
  //   component: components.Input, // ---> radio
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: any) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_BUSINESS_FIELD]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: string) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_BUSINESS_NAME]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: string) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_BUSINESS_INDUSTRY]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: string) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_BUSINESS_TYPE]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: string) => value,
  // },
  // [keyNames.KEY_NAME_CUSTOMER_BUSINESS_AGENT]: {
  //   component: components.Input,
  //   validate: {},
  //   defaultValue: '',
  //   //parseValue: (valueApi: any) => valueApi || '',
  //   parseParam: (value: string) => value,
  // },
};
