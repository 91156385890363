import { useCallback, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { makeTable8Columns } from '@base/components/utils/helpers/react-table';
import * as keyNames from '@customer/customer/config/key-names';
import ListReactTable8 from '@base/components/list/list-react-table-v8';
import { Checkbox, SpanLang } from '@base/components/form';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import { LABEL_VALUE_PRIMARY } from '@customer/customer/config/constants';
import Loading from '@base/components/loading';

const DuplicateTable = (props: any) => {
  const {
    category,
    isLoading,
    items = [],
    selectedIds,
    setSelectedIds
  } = props;

  //table props
  const fields = useMemo(() => {
    if (category === 'account') {
      return [
        { name: 'Account Name', keyName: keyNames.KEY_NAME_CUSTOMER_NAME, enableSorting: false, width: 'auto' },
        { name: 'Customer Type', keyName: keyNames.KEY_NAME_CUSTOMER_TYPE, enableSorting: false, width: 'auto' },
        { name: 'Email', keyName: keyNames.KEY_NAME_CUSTOMER_EMAIL, enableSorting: false, width: 'auto' },
        { name: 'Phone', keyName: keyNames.KEY_NAME_CUSTOMER_PHONES, enableSorting: false, width: 'auto' },
      ]
    } else {
      return [
        { name: 'Contact Name', keyName: keyNames.KEY_NAME_CUSTOMER_NAME, enableSorting: false, width: 'auto' },
        { name: 'Customer Type', keyName: keyNames.KEY_NAME_CUSTOMER_TYPE, enableSorting: false, width: 'auto' },
        { name: 'Email', keyName: keyNames.KEY_NAME_CUSTOMER_EMAIL, enableSorting: false, width: 'auto' },
        { name: 'Phone', keyName: keyNames.KEY_NAME_CUSTOMER_PHONES, enableSorting: false, width: 'auto' },
        { name: 'Related Account', keyName: keyNames.KEY_NAME_CUSTOMER_ACCOUNT, enableSorting: false, width: 'auto' },
      ];
    }
  }, [category])

  //render columns components
  const getMapColumns = () => {
    return {
      [keyNames.KEY_NAME_CUSTOMER_NAME](col: string, data: any) {
        let custName = data[col] ? data[col] : '';
        return custName;
      },
      [keyNames.KEY_NAME_CUSTOMER_TYPE](col: string, data: any) {
        return <SpanLang keyLang={data?.[col]?.languageKey} />;
      },
      [keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE](col: string, data: any) {
        return <SpanLang keyLang={data?.[col]?.languageKey} />;
      },
      [keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE](col: string, data: any) {
        return <SpanLang keyLang={data?.[col]?.languageKey} />;
      },
      [keyNames.KEY_NAME_CUSTOMER_EMAIL](col: string, data: any) {
        let emails: any = [];
        data[col]?.map((_ele: any) => {
          if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
            emails.unshift({ ..._ele, name: _ele.email });
          } else {
            emails.push({ ..._ele, name: _ele.email });
          }
        });

        return (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            //showAvatar={true}
            values={emails}
          />
        );
      },
      [keyNames.KEY_NAME_CUSTOMER_PHONES](col: string, data: any) {
        let phones: any = [];
        data[col]?.map((_ele: any) => {
          if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
            phones.unshift({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
          } else {
            phones.push({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
          }
        });

        return (
          phones.length > 0 && (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={phones}
            />
          )
        );
      },
      [keyNames.KEY_NAME_CUSTOMER_MOBILE](col: string, data: any) {
        let mobiles: any = [];
        data[col]?.map((_ele: any) => {
          if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
            mobiles.unshift({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
          } else {
            mobiles.push({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
          }
        });

        return mobiles.length > 0 ? (
          <ListTableCellDroplist
            className="no-padding cursor-default"
            //showAvatar={true}
            values={mobiles}
          />
        ) : (
          '(none)'
        );
      },
      [keyNames.KEY_NAME_CUSTOMER_ACCOUNT](col: string, data: any) {
        const account = data?.[col];
        return account ? account.name : '(none)';
      },
    };
  };

  //build columns for table v8
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        width: '45px',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="pd-x-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...makeTable8Columns(fields, getMapColumns(), { category }, []),
    ],
    [fields, selectedIds],
  );

  //for react-table v8
  const handleCheckTableItem = useCallback((checkedValue: any[]) => {
    setSelectedIds(checkedValue);
  }, [selectedIds]);

  const listTable8Props = {
    className: '',
    columns,
    data: items,
    initialState: {
      //pageCount: paging?.totalItems || 0,
      pageSize: 15, //paging?.itemPerPage ||
      //pageIndex: paging?.currentPage || 1,
      //sorts: [{ field: 'createdAt', orderBy: DESC }],
    },
    rowSelected: selectedIds,
    onRowSelect: handleCheckTableItem,
    // onSortBy: (clName: any, isSorted: any) => {
    //   //isSorted from react-table-v8 that is false, 'asc' or 'desc'
    //   //// console.log('isSorted', isSorted);
    //   if (isSorted !== false) {
    //     let orderBy = isSorted === 'desc' ? DESC : ASC;
    //     setSort({ field: clName, orderBy: orderBy });
    //   }
    // },
  };

  return (
    <div className='pos-relative'>
      {isLoading && <Loading />}
      <ListReactTable8 {...listTable8Props} />
    </div>
  );
}

export default DuplicateTable;