import React from 'react';
import WriteForm from '@customer/customer/containers/write-form';
import withMiModal from '@base/hooks/hocs/withMiModal';

const WriteFormModal: React.FC<any> = (props) => {
  return (
    <>
      <WriteForm
        {...props}
      />
    </>
  );
}

export default withMiModal(WriteFormModal);