import React, { useMemo, useState } from 'react';
import {
  Calendar,
  ChevronDown,
  CreditCard,
  Mail,
  Printer,
  X,
} from 'react-feather';
import Icon from '@base/assets/icons/svg-icons';
import ListReactTable from '@base/components/list/list-react-table';
import { makeTableColumns } from '@base/components/utils/helpers/react-table';
// import { ASC, DESC } from '@base/config/constant';
import { Link } from 'react-router-dom';
import { Avatar } from '@base/components';
import { moneyFormat } from '@base/utils/helpers';
import SelectDropdown from '@base/components/form/select-dropdown';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';

const CustomerDebit: React.FC<any> = (props) => {
  const TEST_ITEMS = [{
    id: 'INV-001',
    totalAmount: '1000',
    currency: 'KRW',
    date: '2022-01-01',
    receivedAmount: '2000',
    balanceDue: '2000',
    referenceId: 'SO-001',
    assignedRep: { photo: '', name: 'Sales 001' }
  }];
  //state
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [items, setItems] = useState<any>(TEST_ITEMS);
  const [filter, setFilter] = useState({
    filters: {
      groupBy: '',
    },
    keyword: "",
    sort: { field: "createdAt", orderBy: 2 },
    paging: { page: 1, size: 20 }
  });

  // column fields
  const fields = [
    {
      keyName: 'id',
      //name: keyNames.KEY_PRODUCT_GROUP,
      title: 'ID',
      languageKey: 'ID',
      //width: 50,
      disableSortBy: true,
    },
    {
      keyName: 'totalAmount',
      //name: keyNames.KEY_PRODUCT_NAME,
      title: 'Total Amount',
      languageKey: 'Total Amount',
      disableSortBy: true,
    },
    {
      keyName: 'date',
      //name: keyNames.KEY_PRODUCT_TYPE,
      title: 'Date',
      languageKey: 'Date',
      disableSortBy: true,
    },
    {
      keyName: 'receivedAmount',
      //name: keyNames.KEY_PRODUCT_BASE_UNIT,
      title: 'Amount Received',
      languageKey: 'Amount Received',
      disableSortBy: true,
    },
    {
      keyName: 'balanceDue',
      //name: keyNames.KEY_PRODUCT_ITEMS_COUNT,
      title: 'Balance Due',
      languageKey: 'Balance Due',
      disableSortBy: true,
    },
    {
      keyName: 'referenceId',
      //name: keyNames.KEY_PRODUCT_STOCK_QTY,
      title: 'Refenece ID',
      languageKey: 'Refenece ID',
      disableSortBy: true,
    },
    {
      keyName: 'assignedRep',
      //name: keyNames.KEY_PRODUCT_STATUS,
      title: 'Assigned Rep',
      languageKey: 'Assigned Rep'
    },
  ];

  //render data for columns
  const columnsData = {
    id(col: string, data: any) {
      return <Link to={``}>{data?.[col]}</Link>;
    },
    totalAmount(col: string, data: any) {
      return `${data?.currency} ${moneyFormat(data?.[col])}`;
    },
    date(col: string, data: any) {
      return data?.[col];
    },
    receivedAmount(col: string, data: any) {
      return `${data?.currency} ${moneyFormat(data?.[col])}`;
    },
    balanceDue(col: string, data: any) {
      return `${data?.currency} ${moneyFormat(data?.[col])}`;
    },
    referenceId(col: string, data: any) {
      return data?.[col];
    },
    assignedRep(col: string, data: any) {
      return <Avatar url={data?.[col]?.photo} alt={data?.[col]?.name} height={32} width={32} />;
    },
  };

  //handle check a row
  const onCheckedRow = (checkedValue: string | number) => {
    const checkedValueStr = checkedValue as string;
    let newSelectedIds = [...selectedIds];
    switch (checkedValueStr) {
      case 'all':
        newSelectedIds = items.map((_item: any) => _item.id);
        break;
      case 'notAll':
        newSelectedIds = [];
        break;
      default:
        //id
        if (newSelectedIds.indexOf(checkedValueStr) !== -1) {
          newSelectedIds.splice(newSelectedIds.indexOf(checkedValueStr), 1);
        } else {
          newSelectedIds.push(checkedValueStr);
        }
    }
    setSelectedIds(newSelectedIds);
  };

  //react table
  const DebitTable = useMemo(() => {
    //table props
    const tableProps = {
      data: items || [],
      columns: makeTableColumns(fields, columnsData, { category: 'debit' }, []),
      initialState: {
        pageSize: 20, //data?.paging?.totalPage,
        pageIndex: 1, //data?.paging?.currentPage,
        selectedIds: selectedIds,
      },
      onCheckedRow: onCheckedRow,
      // onRowClick: (row: any, e: any) => {
      //   e && e.preventEvent && e.preventDefault();
      //   // goToView(row);
      // },
      // onSortBy: (clName: any, isSortedDesc: boolean) => {
      //   let orderBy = isSortedDesc ? DESC : ASC;
      //   //handleSort({ field: clName, orderBy: orderBy });
      // },
    };

    //paging props
    // const pagingProps = {
    //   totalPage: data?.paging?.totalPage || 1,
    //   totalItems: data?.paging?.totalItems || 0,
    //   currentPage: data?.paging?.currentPage || 1,
    //   itemPerPage: data?.paging?.itemPerPage || LIMIT,
    //   nextPage: data?.paging?.nextPage || null,
    //   previousPage: data?.paging?.previousPage || null,
    //   //isPreviousData, //--> in hook
    //   onChange: (page: number, size: number) => {
    //     let newFilter = {
    //       ...filter,
    //       paging: {
    //         page,
    //         size,
    //       },
    //     };
    //     setFilter(newFilter);
    //   },
    // };

    return (
      <>
        <ListReactTable {...tableProps} />
        {/* <ListPagination isSplitMode={false} type={'full'} {...pagingProps} /> */}
      </>
    );
  }, [items, selectedIds]);

  //header more actions
  const MoreActionButtons = useMemo(() => {
    return (
      <>
        {selectedIds.length > 0 &&
          <>
            <button
              type="button"
              className="btn btn-primary rounded-30 mg-l-auto"
              data-toggle="modal"
              data-target="#registerPayment"
            >
              <CreditCard className="mg-r-5" />
              Register Payment
            </button>
            <button type="button" className="btn">
              <Mail className="mg-r-5" />
              Send Email
            </button>
            <button type="button" className="btn">
              <span className="mg-r-5">{Icon('export_pdf')}</span>Export to PDF
            </button>
            <button type="button" className="btn">
              <Printer className="mg-r-5" />
              Print
            </button>
          </>
        }
      </>
    );
  }, [selectedIds]);

  //filters
  const FilterOptions = useMemo(() => {

    const groupByOptions = [
      { label: 'All Debit', value: 'allDebit' },
      { label: 'My Debit', value: 'myDebit' },
      { label: 'My Group Debit', value: 'myGroupDebit' },
      { label: 'Debit per Invoice', value: 'debitInvoice' },
      { label: 'Debit per POS', value: 'debitPos' },
      { label: 'Debit per Debit Note', value: 'debitNote' },
    ];

    const dateByOptions = [
      { label: 'Created', value: 'createdAt' },
      { label: 'Updated', value: 'updatedAt' },
      { label: 'Conversion Date', value: 'conversionDate' },
    ];

    return (
      <>
        <SelectDropdown
          options={groupByOptions}
          selected={filter.filters?.groupBy ?? groupByOptions[0].value}
          placeholder="Group By"
        //onChange={(data: any) => onFilterDataChanged(data.value, 'group_by')}
        />
        <SelectDropdownWithDates
          placeholder="Select Date"
          options={dateByOptions}
        //onChange={(data: any) => onFilterDataChanged(data, 'date') }
        />
      </>
    );
  }, [filter]);

  //main render
  return (
    <div className="d-flex flex-column pd-b-15-f overflow-hidden">
      <div className="d-flex align-items-center mg-b-10">
        {FilterOptions}
        {MoreActionButtons}
      </div>
      <div className='list-body'>
        {DebitTable}
      </div>
    </div>
  );
}

export default CustomerDebit;