import React, { useMemo, useState } from 'react';
import * as keyNames from '@customer/customer/config/key-names';
import { PhotoViewField } from '@base/containers/view-field';
import { useRecoilValue } from 'recoil';
import { viewDataByMenuAtom } from '@base/recoil/atoms';
import { MENU_CUSTOMER } from '@base/config/menus';
//import ViewSummary from '@base/containers/view-summary';
import { CreditCard, Search } from 'react-feather';
import { Canvas, SpanLang } from '@base/components';
import CustomerDebit from '@customer/customer/containers/customer-debit';
//import RegisterPaymentForm from '@customer/customer/containers/register-payment-form';
import { moneyFormat } from '@base/utils/helpers';
import Loading from '@base/components/loading';
import { ViewFields } from '@base/components/hook-form/view';
import { Button } from '@base/components/form';
import UploaderImage from '@base/components/form/image-uploader';

interface IViewLeftProps {
  id: string;
  category: string;
  showTop?: boolean;
}

const ViewLeft: React.FC<IViewLeftProps> = (props: IViewLeftProps) => {
  const { showTop, category, id } = props;
  //recoil
  const viewData = useRecoilValue(viewDataByMenuAtom(MENU_CUSTOMER));
  //// console.log('viewData', viewData);
  //state
  const [isOpenDebit, setIsOpenDebit] = useState(false);
  const [isOpenRegisterPayment, setIsOpenRegisterPayment] = useState(false);
  //const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page

  //check if type = 'contact' and is employee
  let hiddenFields: string[] = [];
  const basicFields = viewData?.layout?.data?.[0]?.children || [];
  //photo
  const photoField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_PHOTO,
  );
  //type
  const customerTypeField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_TYPE,
  );
  const contactTypeField = basicFields?.find(
    (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE,
  );
  //sales agent type
  const isSaleAgent = customerTypeField?.data?.keyName === 'TYPE_SALES_AGENT';
  const isVendor = customerTypeField?.data?.keyName === 'TYPE_VENDOR';
  const isPartner = customerTypeField?.data?.keyName === 'TYPE_PARTNER';

  //employee type
  // const isEmployee = category === 'contact'
  //   && contactTypeField?.data?.keyName === 'CONTACT_TYPE_EMPLOYEE' ? true : false;

  if (category === 'contact' && contactTypeField?.data?.keyName != 'CONTACT_TYPE_EMPLOYEE') {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_ACCOUNT);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_DEPARTMENT);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_POSITION);
  }
  if (category === 'contact' && contactTypeField?.data?.keyName === 'CONTACT_TYPE_EMPLOYEE') {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_TYPE);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD);
  }
  if (!isSaleAgent) {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_SALES_PRODUCT);
  }
  if (isVendor) {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_RATING);
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD);
  }
  if (!isVendor) {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_PREFERRED);
  }
  if (!isPartner) {
    hiddenFields.push(keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT);
  }

  //render debit/credit - NEXT VERSION
  const renderDebitCredit = () => {
    const moreFields = viewData?.layout?.data?.[1]?.children || [];
    const currentDebitField = moreFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT,
    );
    const unusedCreditField = moreFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT,
    );
    const amountReceivedField = moreFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_AMOUNT_RECEIVED,
    );
    const currencyField = moreFields?.find(
      (_field: any) => _field?.keyName === keyNames.KEY_NAME_CUSTOMER_CURRENCY,
    );

    return (
      <div className="mg-t-15">
        <div className="d-flex flex-column pd-10">
          <div className="d-flex">
            <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-pink">Current Debit</div>
            <strong className="mg-l-auto tx-15">
              {currencyField?.data?.code || ''} {moneyFormat(currentDebitField?.data || 0)}
            </strong>
          </div>
          <div className="mg-t-5 text-right">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary rounded-30 mg-r-5"
              //data-toggle="modal"
              //data-target="#debitList"
              onClick={() => setIsOpenDebit(true)}
            >
              <Search className="mg-r-5" />
              Detail
            </button>
            <button
              type="button"
              className="btn btn-sm btn-info rounded-30"
              //data-toggle="modal"
              //data-target="#registerPayment"
              onClick={() => setIsOpenRegisterPayment(true)}
            >
              <CreditCard className="mg-r-5" />
              Pay
            </button>
          </div>
        </div>
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-purple">
            {isVendor ? 'Unused Vendor Credit' : 'Unused Credits'}
          </div>
          <div className="mg-l-auto tx-15 tx-semibold">
            {currencyField?.data?.code || ''} {moneyFormat(unusedCreditField?.data || 0)}
          </div>
        </div>
        <div className="d-flex pd-10 bd-t bd-x-0 bd-b-0 bd-dashed">
          <div className="mg-r-5 tx-12 tx-spacing-1 tx-uppercase tx-success">
            {isVendor ? 'Amount Paid' : 'Amount Recevied'}
          </div>
          <div className="mg-l-auto tx-15 tx-semibold">
            {currencyField?.data?.code || ''} {moneyFormat(amountReceivedField?.data || 0)}
          </div>
        </div>
      </div>
    );
  };

  //debit detail in canvas - NEXT VERSION
  const renderDebitDetail = () => {
    return (
      <Canvas
        isVisible={isOpenDebit}
        onCloseSideBar={() => setIsOpenDebit(false)}
        width={'100'}
      //className={'page-sidebar-container-wrap'}
      //customStyles={{ zIndex: '1052' }}
      //backdropStyles={{ zIndex: '1051' }}
      >
        <Canvas.Header title={<SpanLang keyLang="Debit Detail" />} />
        <Canvas.Body style={{ height: 'calc(100vh - 130px)' }}>
          <CustomerDebit />
        </Canvas.Body>
        <Canvas.Footer onClose={() => setIsOpenDebit(false)} />
      </Canvas>
    );
  };

  //render sections
  const SummarySection = useMemo(() => {
    const newBasicFields = basicFields.filter(
      (_field: any) => !hiddenFields.includes(_field.keyName),
    );
    return (
      <div className="view-aside-section">
        <div className="view-aside-section-header">
          {/* <Button icon="move" iconType="custom" className="view-aside-section-move mg-r-5" /> */}
          <span className="view-aside-section-header-title">Summary</span>
          {/* <Button
            color="link link-03"
            className="mg-l-auto pd-0"
            icon="ChevronDown"
            data-toggle="collapse"
            data-target="#summary"
          /> */}
        </div>
        <div id="summary" className="view-aside-section-body collapse fade show">
          <div className="form-row mg-x-0 mg-t-10">
            <ViewFields
              fields={newBasicFields}
              ignoreFields={[keyNames.KEY_NAME_CUSTOMER_PHOTO]}
              menu={viewData?.menu ?? ''}
              menuSource={viewData?.menuSource ?? ''}
              menuSourceId={viewData?.menuSourceId ?? ''}
            />
          </div>
        </div>
      </div>
    );
  }, [id, basicFields]);

  //main render
  return (
    <div
      className="pos-relative scroll-box"
      style={{ height: showTop ? 'calc(100vh - 180px)' : 'calc(100vh - 100px)' }}
    >
      {photoField && (
        <div className="view-aside-section pd-15">
          <div className="d-flex align-items-center justify-content-center">
            <div className="">
              <PhotoViewField
                menuSource={MENU_CUSTOMER}
                menuSourceId={id}
                value={photoField?.data || ''}
              />
            </div>
          </div>

          {/* {!isEmployee && renderDebitCredit()} */}
          {/* {!isEmployee && renderDebitDetail()} */}
        </div>
      )}

      <React.Suspense fallback={<Loading />}>
        {SummarySection}
        {/* <ViewSummary
          showTop={showTop}
          menuSource={MENU_CUSTOMER} //category: 'account', 'contact'
          menuSourceId={id}
          data={viewData}
          hiddenFields={hiddenFields} //FOR hiding display
          ignoreFields={[keyNames.KEY_NAME_CUSTOMER_PHOTO]} //FOR displaying TOP part such as photo, ...
        /> */}
      </React.Suspense>

      {/* {isOpenRegisterPayment &&
        <RegisterPaymentForm
          title={'Register Payment'}
          size='lg'
          isOpen={isOpenRegisterPayment}
          onClose={() => setIsOpenRegisterPayment(false)}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        //onReload={refetch}
        />
      } */}
    </div>
  );
};

export default ViewLeft;
