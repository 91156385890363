import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
//import { X } from 'react-feather';
import CustomerSelect from '@customer/customer/containers/customer-select';
import { Overlay, Popover } from 'react-bootstrap';
import { Button } from '@base/components/form';
import { FormIcon } from '@base/components/form/icon';
import $ from 'jquery';

interface ICustomerPopover {
  single?: boolean;
  isOpen: boolean;
  placement?: any;
  category?: string; //'account', 'contact'
  type?: string; //'customer', 'partner', ...
  accountId?: string;
  onClose: any;
  onApply: any;
  data: any; //initial data
  visibleTab: boolean;
  containerRef?: any;
  target?: any;
}

const CustomerPopover: React.FC<ICustomerPopover> = (props: ICustomerPopover) => {
  const {
    single = false,
    isOpen = false,
    placement = 'right',
    category = '', //'account', 'contact'
    type = '', //'customer', 'partner', ...
    accountId,
    onClose = null,
    onApply = null,
    data, //initial data
    visibleTab = false,
    containerRef,
    target,
  } = props;

  //state
  const [idPop] = useState(uniqueId('customer-popover-'));
  const [customers, setCustomers] = useState(data || []);
  //const defaultTab = 'customer';
  const [activeTab, setActiveTab] = useState('customer');
  //const [cacheOptions, setCacheOptions] = useState(null);

  //init data
  useEffect(() => {
    setCustomers(data);
  }, [data]);

  //reset data and close popover
  const handleClose = () => {
    setCustomers([]);
    onClose && onClose();
  };

  const renderVerticalTabs = () => {
    return (
      <div className="popover-tabs">
        <ul className="popover-tab-ul">
          <li
            data-toggle="tooltip"
            data-placement="bottom"
            className={activeTab === 'customer' ? 'active' : ''}
            title="Customer"
            onClick={() => setActiveTab('customer')}
          >
            <FormIcon icon="m_customer" iconType="icon" />
          </li>
          <li
            data-toggle="tooltip"
            data-placement="bottom"
            className={activeTab === 'rating' ? 'active' : ''}
            title="Rating"
            onClick={() => setActiveTab('rating')}
          >
            <FormIcon icon="Star" iconType="feather" />
          </li>
        </ul>
      </div>
    );
  };

  let $target: any = $(target?.current);
  let $body: any = $(document.body);
  //// console.log('$target', $target?.offset());
  //// console.log('$body outerHeight', $body.outerHeight());
  // // console.log('$outerWidth', $target?.outerWidth());
  let posLeft = $target?.offset()?.left;
  let posTop = $target?.offset()?.top;
  if (placement === 'left') {
    posLeft = posLeft - $target?.outerWidth() * 1.5;
  }
  if (posTop > $body.outerHeight() / 2) {
    posTop = posTop - $body.outerHeight() / 2;
    if (posTop > 250) {
      posTop = 250;
    }
  } else {
    posTop = posTop - 100;
  }

  return (
    <Overlay
      show={isOpen}
      target={target}
      placement={placement}
      container={containerRef}
      containerPadding={20}
    >
      <Popover id={idPop}>
        {/* <Popover.Header as="h3">Select a customer</Popover.Header> */}
        {/* <div className={'d-flex justify-content-end align-items-center  pd-t-10 pd-r-10'}>
            <X size={16} style={{ cursor: 'pointer' }} onClick={handleClose} />
        </div> */}
        <div
          className="pos-absolute bg-gray-100 rounded"
          style={{ top: `${posTop}px`, left: `${posLeft}px` }}
        >
          {visibleTab && renderVerticalTabs()}
          <Popover.Body>
            <div className={'ht-100p wd-100p d-flex flex-column'}>
              {activeTab === 'customer' && (
                <CustomerSelect
                  single={single}
                  category={category}
                  type={type}
                  accountId={accountId}
                  value={customers}
                  onChange={(items: any) => setCustomers(items)}
                  //cacheOptions={cacheOptions}
                  //onCache={(data: any) => setCacheOptions(data)}
                />
              )}
              {activeTab === 'rating' && (
                <CustomerSelect
                  single={single}
                  category={'rating'}
                  type={type}
                  accountId={accountId}
                  value={customers}
                  onChange={(items: any) => setCustomers(items)}
                  //cacheOptions={cacheOptions}
                  //onCache={(data: any) => setCacheOptions(data)}
                />
              )}
            </div>
          </Popover.Body>
          <div className={'ht-60 d-flex justify-content-end align-items-center pd-r-10'}>
            <Button
              name={'Close'}
              color={'secondary'}
              outline
              className="mg-r-5"
              onClick={handleClose}
            />
            <Button
              name={'Apply'}
              color={'primary'}
              outline
              onClick={() => onApply && onApply(customers)}
            />
          </div>
        </div>
      </Popover>
    </Overlay>
  );
};

export default CustomerPopover;
