import React from 'react';
//import WriteFrom from '@customer/customer/containers/write-form';
import WriteFromModal from '@customer/customer/containers/write-form-modal';

const WriteFormDesktopLayout1: React.FC<any> = (props: any) => {
  return (
    <>
      <WriteFromModal {...props} />
    </>
  );
};

export default WriteFormDesktopLayout1;
