import * as keyNames from '@customer/customer/config/key-names';
import * as components from '@customer/customer/config/write-field/components';
import _ from 'lodash';
import { CUSTOMER_CONTACT_TYPE_ENUM } from '../constants';
import { Link } from 'react-router-dom';
import { SpanLang } from '@base/components/form';
import { MENU_CUSTOMER } from '@base/config/menus';
import { convertDateTimeServerToClient, moneyFormat } from '@base/utils/helpers';
import { Avatar } from '@base/components';
import ListTableCellDroplist from '@base/components/list/list-table-cell-droplist';
import {
  LABEL_VALUE_CUSTOM_ANNI,
  LABEL_VALUE_PRIMARY,
} from '@customer/customer/config/constants';
import { formatAddress } from '@customer/main/utils';
import TextView from '@base/containers/view-field/text/view';
import { t } from 'i18next';
import { Mail, Phone } from 'react-feather';

export const groupByCategoryOptions: { [key: string]: any } = {
  all: [
    { label: 'All Customers', value: 'allCustomer' },
    { label: 'My Customers', value: 'myCustomer' },
    { label: 'My Group Customers', value: 'myGroupCustomer' },
    { label: 'Unassigned Customers', value: 'unassignedTo' },
    // { label: 'Deleted Customers', value: 'deletedCustomer' },
    { label: 'All Accounts', value: 'allAccount' },
    { label: 'All Contacts', value: 'allContact' },
    { label: 'My Accounts', value: 'myAccount' },
    { label: 'My Group Accounts', value: 'myGroupAccount' },
    { label: 'My Contacts', value: 'myContact' },
    { label: 'My Group Contacts', value: 'myGroupContact' },
  ],
  account: [
    { label: 'All Accounts', value: 'allAccount' },
    { label: 'My Accounts', value: 'myAccount' },
    { label: 'My Group Accounts', value: 'myGroupAccount' },
    { label: 'Unassigned Accounts', value: 'unassignedTo' },
    { label: 'Deleted Accounts', value: 'deletedAccount' },
  ],
  contact: [
    { label: 'All Contacts', value: 'allContact' },
    { label: 'My Contacts', value: 'myContact' },
    { label: 'My Group Contacts', value: 'myGroupContact' },
    { label: 'Unassigned Contacts', value: 'unassignedTo' },
    { label: 'Deleted Contacts', value: 'deletedContact' },
    { label: 'All Employees', value: 'typeEmployee' },
  ],
  general: [
    //{ label: 'Overdue Customers', value: 'typeCustomerOverdue' },
    //{ label: 'Closed Lost Customers', value: 'typeCustomerTerminated' },
    //{ label: 'Expired Customers', value: 'typeCustomerExpired' },
    { label: 'All Customers', value: 'typeCustomer' },
    { label: 'All Vendors', value: 'typeVendor' },
    { label: 'Preferred Vendors', value: 'preferredVendor' },
    { label: 'All Sales Agents', value: 'typeSalesAgent' },
    { label: 'All Partners', value: 'typePartner' },
  ],
};

export const dateByOptions = [
  { label: 'Created', value: keyNames.KEY_NAME_CUSTOMER_CREATED_AT },
  { label: 'Updated', value: keyNames.KEY_NAME_CUSTOMER_UPDATED_AT },
  // { label: 'Conversion Date', value: 'conversionDate' },
];

export const filterByCategoryOptions: { [key: string]: any } = {
  account: [],
  contact: [
    {
      label: 'Contact Type',
      value: keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE,
      component: components.DataSourceSelect,
      componentProps: {
        //single: true,
        sourceKey: 'contact_type',
        sourceType: 'field',
      },
      getValue: (value: any) => {
        return value.length > 0
          ? value?.map((v: any) => CUSTOMER_CONTACT_TYPE_ENUM[v?.keyName])
          : [];
      },
      setValue: (value: any) => {
        const keyValues: string[] = [];
        if (value && value.length > 0) {
          Object.keys(CUSTOMER_CONTACT_TYPE_ENUM).map((_key: string) => {
            if (value.includes(CUSTOMER_CONTACT_TYPE_ENUM[_key])) {
              keyValues.push(_key);
            }
          });
        }
        return keyValues;
      },
    },
    {
      label: 'Related Account',
      value: 'accounts',
      component: components.CustomerAutoComplete,
      componentProps: {
        //single: true,
        type: 'account',
      },
      getValue: (value: any) => {
        return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
      },
      setValue: (value: string) => {
        return value ? value.split(',') : [];
      },
    },
    { label: 'Mobile', value: 'mobile' },
  ],
  general: [
    {
      label: 'Customer Type',
      value: keyNames.KEY_NAME_CUSTOMER_TYPE,
      component: components.DataSourceSelect,
      componentProps: {
        single: true,
        sourceKey: 'customer_category',
        sourceType: 'field',
      },
      getValue: (value: any) => {
        return value?.value || '';
      },
      setValue: (value: string) => {
        return value;
      },
    },
    {
      label: 'Assigned Rep',
      value: keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO,
      component: components.UserAutoComplete,
      getValue: (value: any) => {
        return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : '';
      },
      setValue: (value: string) => {
        return value ? value.split(',') : [];
      },
    },
    // { label: 'Main Product', value: keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT },
    {
      label: 'Related Product',
      value: keyNames.KEY_NAME_CUSTOMER_RELATED_PRODUCT,
      component: components.ProductAutoComplete,
      componentProps: {
        showAllOption: false,
        hiddenAdd: true,
      },
      getValue: (value: any) => {
        return value?.length > 0 ? value?.map((v: any) => v?.id).join(',') : ''; //TODO
      },
      // setValue: (value: string) => {
      //   return value;
      // },
    },
    // { label: 'Rating', value: 'rating' },
    {
      label: 'Owner',
      value: keyNames.KEY_NAME_CUSTOMER_CREATED_BY,
      component: components.UserAutoComplete,
      componentProps: {
        single: true,
      },
      getValue: (value: any) => {
        return value?.id || '';
      },
      setValue: (value: string) => {
        return value;
      },
    },
    {
      label: 'Industry',
      value: 'industry',
      component: components.DataSourceSelect,
      componentProps: {
        single: true,
        sourceKey: 'industry',
        sourceType: 'field',
      },
      getValue: (value: any) => {
        return value?.value || '';
      },
      setValue: (value: string) => {
        return value;
      },
    },
    {
      label: 'Rating',
      value: 'rating',
      component: components.DataSourceSelect,
      componentProps: {
        single: true,
        sourceKey: 'customer_rating',
        sourceType: 'field',
      },
      getValue: (value: any) => {
        return value?.value || ''; //TODO
      },
      setValue: (value: string) => {
        return value;
      },
    },
    {
      label: 'Sales Commission',
      value: 'salesCommission',
      component: components.SalesCommission,
      componentProps: {
        //showSymbol: true,
        //currencyDisabled: true,
      },
      getValue: (value: any) => {
        return value ? `${value.from},${value.to}` : ''; //TODO
      },
      setValue: (value: string) => {
        const arr = value?.split(',') || []; //TODO
        return arr.length > 1 ? { from: arr[0], to: arr[1] } : null;
      },
    },
    { label: 'Email', value: 'email' },
    { label: 'Telephone', value: 'phone' },
    {
      label: 'Service Level Agreement',
      value: 'sla',
      component: components.MenuSourceSelect,
      componentProps: {
        single: true,
        sourceKey: 'sla',
        sourceMenu: 'desk',
        optionLabel: 'sla',
        optionValue: 'sla',
      },
      getValue: (value: any) => {
        return value?.sla || ''; //TODO
      },
      setValue: (value: string) => {
        return value; //TODO
      },
    }, //TODO
    // { label: 'Current Debits', value: 'currentDebit' },
    // { label: 'Unused Credits', value: 'unusedCredit' },
    // { label: 'Total Received/Paid', value: 'totalPaid' },
    // { label: 'Late Fee', value: 'lateFee' },
  ],
};

export const searchFields = [
  {
    name: keyNames.KEY_NAME_CUSTOMER_TYPE,
    type: 'custom',
    label: 'Type',
    component: components.DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'customer_category',
      sourceType: 'field',
    },
    getParam: (output: any) => {
      return output?.value;
    },
  },
  {
    name: keyNames.KEY_NAME_CUSTOMER_EMAIL,
    type: 'email',
    label: 'Email',
    defaultValue: '',
  },
  {
    name: keyNames.KEY_NAME_CUSTOMER_PHONES,
    type: 'text',
    label: 'Telephone',
    defaultValue: '',
  },
  // {
  //   name: 'conversionDate',
  //   type: 'range',
  //   label: 'Conversion Date',
  //   time: true,
  // },
  // {
  //   name: KEY_NAME_CUSTOMER_TYPE,
  //   type: 'select',
  //   label: 'Type',
  //   defaultValue: '',
  //   options: [
  //     { label: 'Customer', value: 'TYPE_CUSTOMER' },
  //     { label: 'Potential', value: 'TYPE_POTENTIAL' },
  //   ],
  // },
];

export const categoryEnum: { [key: string]: string } = {
  all: 'CATEGORY_ALL',
  account: 'CATEGORY_ACCOUNT',
  contact: 'CATEGORY_CONTACT',
  employee: 'CATEGORY_EMPLOYEE',
  none: 'CATEGORY_NONE',
};

//render columns components
export const getMapColumns = (category: string) => {
  return {
    [keyNames.KEY_NAME_CUSTOMER_PHOTO](col: string, data: any) {
      return (
        <div className="d-flex justify-content-center" style={{ maxWidth: '80px' }}>
          <Avatar
            key={data.id}
            url={data[col]}
            alt={data[keyNames.KEY_NAME_CUSTOMER_NAME]}
            height={36}
            width={36}
          />
        </div>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_CODE](col: string, data: any) {
      return <TextView value={data[col] || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_NAME](col: string, data: any) {
      let custName = data[col] ? data[col] : '';
      let custCategory = 'account';
      switch (data[keyNames.KEY_NAME_CUSTOMER_CATEGORY]) {
        case 'CATEGORY_ACCOUNT':
          custCategory = 'account';
          break;
        case 'CATEGORY_CONTACT':
          custCategory = 'contact';
          break;
      }
      let sourceId = data[keyNames.KEY_NAME_CUSTOMER_ID]
        ? data[keyNames.KEY_NAME_CUSTOMER_ID]
        : '';
      let url =
        category === 'all'
          ? `/${MENU_CUSTOMER}/${category}/${sourceId}/${custCategory}`
          : `/${MENU_CUSTOMER}/${category}/${sourceId}`;
      return <Link to={url}>{custName}</Link>;
    },
    [keyNames.KEY_NAME_CUSTOMER_TYPE](col: string, data: any) {
      return data?.[col] ? (
        <SpanLang keyLang={data?.[col]?.languageKey} />
      ) : (
        <TextView value="" />
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_CONTACT_TYPE](col: string, data: any) {
      return data?.[col] ? (
        <SpanLang keyLang={data?.[col]?.languageKey} />
      ) : (
        <TextView value="" />
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_EMPLOYEE_ROLE](col: string, data: any) {
      return data?.[col] ? (
        <SpanLang keyLang={data?.[col]?.languageKey} />
      ) : (
        <TextView value="" />
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_EMAIL](col: string, data: any) {
      let emails: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          emails.unshift({ ..._ele, name: _ele.email });
        } else {
          emails.push({ ..._ele, name: _ele.email });
        }
      });

      return (
        <>
          {emails.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={emails}
            />
          )}
        </>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_PHONES](col: string, data: any) {
      let phones: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          phones.unshift({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
        } else {
          phones.push({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
        }
      });

      return (
        <>
          {phones.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={phones}
            />
          )}
        </>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_MOBILE](col: string, data: any) {
      let mobiles: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          mobiles.unshift({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
        } else {
          mobiles.push({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
        }
      });

      return (
        <>
          {mobiles.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={mobiles}
            />
          )}
        </>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_GENDER](col: string, data: any) {
      return <TextView value={t(data?.[col]?.languageKey) || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES](col: string, data: any) {
      let industries: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele !== null) {
          industries.push({ ..._ele, name: t(_ele?.languageKey) });
        }
      });

      return (
        <>
          {industries.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={industries}
            />
          )}
        </>
      );
    },
    // [keyNames.KEY_NAME_CUSTOMER_RATING](col: string, data: any) {
    //   let ranking: any = data[col] ? data[col] : [];
    //   return ranking.length > 0 ? <SpanLang keyLang={ranking[0]?.languageKey} /> : '(none)';
    // },
    [keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO](col: string, data: any) {
      let staffs = data[col] ? data[col] : [];
      //return staffs.length > 0 ? staffs[0].name : '(none)';

      return (
        <>
          {staffs.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={staffs}
            />
          )}
        </>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_WEBSITES](col: string, data: any) {
      return (
        <>
          {!data[col] ? (
            <TextView value="" />
          ) : (
            data[col]?.map((_ele: any) => (
              <div>
                <a href={_ele.website}>{_ele.website || '#'}</a>
              </div>
            ))
          )}
        </>
      );
    },
    // [keyNames.KEY_NAME_CUSTOMER_PREFERRED](col: string, data: any) {
    //   return data?.[col] ? 'Yes' : 'No';
    // },
    [keyNames.KEY_NAME_CUSTOMER_BUSINESS_NUMBER](col: string, data: any) {
      return <TextView value={data?.[col] || ''} />;
    },
    // [keyNames.KEY_NAME_CUSTOMER_SALES_COMMISSION](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_REFERRAL_REWARD](col: string, data: any) {
    //   return data?.[col];
    // },
    [keyNames.KEY_NAME_CUSTOMER_FAX](col: string, data: any) {
      let faxes: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          faxes.unshift({ ..._ele, name: `+${_ele.country} ${_ele.faxNumber}` });
        } else {
          faxes.push({ ..._ele, name: `+${_ele.country} ${_ele.faxNumber}` });
        }
      });

      return (
        <>
          {faxes.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={faxes}
            />
          )}
        </>
      );
    },
    // [keyNames.KEY_NAME_CUSTOMER_PAYMENT_TERM](col: string, data: any) {
    //   return data?.[col];
    // },
    [keyNames.KEY_NAME_CUSTOMER_ADDRESSES](col: string, data: any) {
      const billAddress = data?.billAddresses?.length > 0 ? data.billAddresses[0] : null;
      const shipAddress = data?.shipAddresses?.length > 0 ? data.shipAddresses[0] : null;
      return (
        <>
          <div>
            <div className='mg-b-5'><span className="tx-gray-500">Billing Address: </span></div>
            <div>{billAddress ? formatAddress(billAddress) : <TextView value="" />}</div>
          </div>
          <div>
            <div className='mg-y-5'><span className="tx-gray-500">Shipping Address: </span></div>
            <div>{shipAddress ? formatAddress(shipAddress) : <TextView value="" />}</div>
          </div>
        </>
      );
    },
    // [keyNames.KEY_NAME_CUSTOMER_CURRENCY](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_TAX](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_CHARGE_LATE_FEE](col: string, data: any) {
    //   return data?.[col];
    // },
    [keyNames.KEY_NAME_CUSTOMER_PARENT_ACCOUNT](col: string, data: any) {
      return <TextView value={data?.[col]?.name || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_MAIN_PRODUCT](col: string, data: any) {
      return <TextView value={data?.[col] || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_ANNUAL_REVENUE](col: string, data: any) {
      return moneyFormat(data?.[col] || '0');
    },
    [keyNames.KEY_NAME_CUSTOMER_EMPLOYEES_NUMBER](col: string, data: any) {
      return data?.[col];
    },
    [keyNames.KEY_NAME_CUSTOMER_SLA](col: string, data: any) {
      return <TextView value={data?.[col]?.name || ''} />;
    },
    // [keyNames.KEY_NAME_CUSTOMER_OPENING_BALANCE](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_CREDIT_LIMIT](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_RECEIPT_TYPE](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_SEND_MODE](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_CURRENT_DEBIT](col: string, data: any) {
    //   return data?.[col];
    // },
    // [keyNames.KEY_NAME_CUSTOMER_UNUSED_CREDIT](col: string, data: any) {
    //   return data?.[col];
    // },
    [keyNames.KEY_NAME_CUSTOMER_ACCOUNT](col: string, data: any) {
      const account = data?.[col];
      return <TextView value={account?.name || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_DEPARTMENT](col: string, data: any) {
      return <TextView value={data?.[col] || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_POSITION](col: string, data: any) {
      return <TextView value={data?.[col] || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_ANNIVERSARIES](col: string, data: any) {
      let rows: any = [];
      data[col]?.map((_ele: any) => {
        let labelTitle =
          _ele?.label?.label == LABEL_VALUE_CUSTOM_ANNI
            ? _ele?.labelValue
            : t(_ele?.label?.languageKey);
        rows.push({ ..._ele, name: `${labelTitle}: ${_ele.anniversary?.slice(0, 10)}` });
      });

      return (
        <>
          {rows.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={rows}
            />
          )}
        </>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_CREATED_BY](col: string, data: any) {
      return <TextView value={data?.[col]?.name || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_CREATED_AT](col: string, data: any) {
      return data?.[col] ? (
        convertDateTimeServerToClient({ date: data?.[col] })
      ) : (
        <TextView value="" />
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_UPDATED_BY](col: string, data: any) {
      return <TextView value={data?.[col]?.name || ''} />;
    },
    [keyNames.KEY_NAME_CUSTOMER_UPDATED_AT](col: string, data: any) {
      return data?.[col] ? (
        convertDateTimeServerToClient({ date: data?.[col] })
      ) : (
        <TextView value="" />
      );
    },
  };
};

//columns render in grid split mode
export const getMapColumnsInSplitMode = (category: string) => {
  return {
    [keyNames.KEY_NAME_CUSTOMER_NAME](col: string, data: any) {
      let custName = data[col] ? data[col] : '';
      let custCategory = 'account';
      switch (data[keyNames.KEY_NAME_CUSTOMER_CATEGORY]) {
        case 'CATEGORY_ACCOUNT':
          custCategory = 'account';
          break;
        case 'CATEGORY_CONTACT':
          custCategory = 'contact';
          break;
      }
      let sourceId = data[keyNames.KEY_NAME_CUSTOMER_ID]
        ? data[keyNames.KEY_NAME_CUSTOMER_ID]
        : '';
      let url =
        category === 'all'
          ? `/${MENU_CUSTOMER}/${category}/${sourceId}/${custCategory}`
          : `/${MENU_CUSTOMER}/${category}/${sourceId}`;
      return <Link to={url}>{custName}</Link>;
    },
    [keyNames.KEY_NAME_CUSTOMER_EMAIL](col: string, data: any) {
      let emails: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          emails.unshift({ ..._ele, name: _ele.email });
        } else {
          emails.push({ ..._ele, name: _ele.email });
        }
      });

      return (
        <li className="d-flex align-items-center mg-t-5">
          <span className="split-list-icon">
            <Mail />
          </span>
          {emails.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={emails}
            />
          )}
        </li>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_PHONES](col: string, data: any) {
      let phones: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          phones.unshift({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
        } else {
          phones.push({ ..._ele, name: `+${_ele.country} ${_ele.phoneNumber}` });
        }
      });

      return (
        <li className="d-flex align-items-center mg-t-5">
          <span className="split-list-icon">
            <Phone />
          </span>
          {phones.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={phones}
            />
          )}
        </li>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_MOBILE](col: string, data: any) {
      let mobiles: any = [];
      data[col]?.map((_ele: any) => {
        if (_ele.label?.label === LABEL_VALUE_PRIMARY) {
          mobiles.unshift({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
        } else {
          mobiles.push({ ..._ele, name: `+${_ele.country} ${_ele.mobileNumber}` });
        }
      });

      return (
        <li className="d-flex align-items-center mg-t-5">
          <span className="split-list-icon">
            <Phone />
          </span>
          {mobiles.length === 0 ? (
            <TextView value="" />
          ) : (
            <ListTableCellDroplist
              className="no-padding cursor-default"
              //showAvatar={true}
              values={mobiles}
            />
          )}
        </li>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES](col: string, data: any) {
      return (
        <li className="d-flex flex-wrap mg-t-5">
          {data[col]?.length > 0 && (
            <span className="bg-primary op-5 mg-y-5 mg-r-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
              {data[col]?.map((_ele: any) => t(_ele?.languageKey)).join(', ')}
            </span>
          )}
          {data?.type && (
            <span className="bg-indigo op-5 mg-y-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
              <>{t(data.type.languageKey)}</>
            </span>
          )}
        </li>
      );
    },
    [keyNames.KEY_NAME_CUSTOMER_TYPE](col: string, data: any) {
      return (
        <li className="d-flex flex-wrap mg-t-5">
          {data?.[col] && (
            <span className="bg-indigo op-5 mg-y-5 pd-x-10 tx-white tx-13 text-truncate tx-normal rounded-30">
              <>{t(data[col].languageKey)}</>
            </span>
          )}
        </li>
      );
    },
  };
};
