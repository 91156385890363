import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { Gender } from '@customer/customer/components';

const Container = styled.div``;

const Edit: React.FC = (props: any) => {
  const { value = '', onChange = () => { }, errors = {}, onSave = () => { } } = props;

  return (
    <Container className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <Gender {...props} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </Container>
  );
};

export default Edit;
