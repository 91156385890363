import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CUST_GENDER_NONE, CUST_GENDER_MALE, CUST_GENDER_FEMALE } from './config';
import { Select } from '@base/components/form';

const Gender = (props: any) => {
  const {
    value, //initial data
    onChange,
  } = props;
  const { t } = useTranslation();

  //gender options
  const genderOptions = [
    // {
    //   label: CUST_GENDER_NONE,
    //   languageKey: 'crm_new_common_none',
    // },
    {
      label: CUST_GENDER_MALE,
      languageKey: 'crm_new_common_male',
    },
    {
      label: CUST_GENDER_FEMALE,
      languageKey: 'crm_new_common_female',
    },
  ];

  //state
  const [selectedValue, setSelectedValue] = useState(genderOptions[0]);

  //init rows
  useEffect(() => {
    if (value) {
      if (JSON.stringify(value) !== JSON.stringify(selectedValue)) {
        setSelectedValue(value);
      }
    } else {
      setSelectedValue(genderOptions[0]);
    }
  }, [value]);

  //value change
  const handleValueChange = (newValue: any) => {
    setSelectedValue(newValue);
    //callback
    onChange && onChange(newValue);
  };

  //main render
  return (
    <div>
      <Select
        isSearchable={false}
        options={genderOptions}
        getOptionLabel={(option: any) => t(option.languageKey)}
        getOptionValue={(option: any) => option.label}
        value={selectedValue}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default Gender;
