import { useState } from "react";
import { useRecoilState } from "recoil";
import { customerWriteOptionAtom } from '@customer/customer/recoil/atom/list-atom'; //for write
import WritePage from '@customer/customer/pages/write'; //for write
import { SpanLang } from "@base/components";
import { MENU_CUSTOMER } from "@base/config/menus";

interface WriteModalProps {
  writeMenu: 'view' | 'list';
  customerType?: string;
  refetch?: () => void;
  onGoView?: any;
}

const WriteModal = (props: WriteModalProps) => {
  const { customerType, writeMenu = 'list', refetch, onGoView } = props;
  const [fullScreen, setFullScreen] = useState<boolean>(false); //for write page
  const [writeOption, setWriteOption] = useRecoilState(customerWriteOptionAtom); //for write page
  const writeCategory = `${MENU_CUSTOMER}_${writeOption.writeType}`;
  const keyTitle = `crm_new_${writeOption.writeType}`;

  return (
    <>
      {writeOption.isOpenWrite &&
        (writeOption.writeType === 'account' || writeOption.writeType === 'contact') &&
        writeOption.writeMenu === writeMenu && (
          <WritePage
            title={<SpanLang keyLang={keyTitle} />}
            size="lg"
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            isOpen={writeOption.isOpenWrite}
            onClose={() =>
              setWriteOption((filter) => ({
                ...filter,
                isOpenWrite: false,
              }))
            }
            onReload={refetch}
            menuApi={writeCategory}
            customerType={customerType}
            onGoView={onGoView}
          />
        )}
    </>
  );
}

export default WriteModal;