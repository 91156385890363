import React, { useEffect, useState, VFC } from 'react';
import dayjs from 'dayjs';
import SelectDropdown from '@base/components/form/select-dropdown';
import SelectDropdownWithDates from '@base/components/form/select-dropdown-dates';
import SelectDropdownFilters from '@base/components/form/select-dropdown-filters';
import ListHeaderFilterSplitMode from '@customer/customer/components/list/list-header/list-header-filter-splitmode';
import ListHeaderSort from '@base/components/list/list-header/list-header-sort';
import {
  groupByCategoryOptions,
  dateByOptions,
  filterByCategoryOptions,
} from '@customer/customer/config/list';

interface IListHeaderFilters {
  category: string;
  filterData: { [key: string]: any };
  onFilterDataChanged: (value: any) => void;
  isSplitMode?: boolean;
  sortColumns: any[];
  sortData: any;
  onSortDataChanged: (sortData: any) => void;
}

const ListHeaderFilterNormalMode = (props: any) => {
  const { filterData, onFilterDataChanged, groupByOptions, dateByOptions, filterByOptions } = props;

  //selected dates
  let selectedDates: any = null;
  dateByOptions.map((_option: any) => {
    if (filterData[_option.value]) {
      if (filterData[_option.value]?.criteria?.length > 0) {
        selectedDates[_option.value] = [
          filterData[_option.value]?.criteria[0].value,
          filterData[_option.value]?.criteria[1].value,
        ];
      }
    }
  });

  //selected filters by fields
  let selectedFilters: any = {};
  filterByOptions.map((_option: any) => {
    if (filterData[_option.value]) {
      if (filterData[_option.value]?.criteria?.length > 0) {
        const tmpValues: any = [];
        filterData[_option.value].criteria.map((_ele: any) => {
          tmpValues.push(_ele.value);
        });
        selectedFilters[_option.value] = tmpValues;
      } else {
        if (filterData[_option.value].value) {
          selectedFilters[_option.value] = filterData[_option.value].value;
        }
      }
    }
  });

  //// console.log('selectedFilters', selectedFilters);
  return (
    <>
      <SelectDropdown
        options={groupByOptions}
        selected={filterData?.groupBy?.value || groupByOptions[0].value}
        placeholder="Group By"
        onChange={(data: any) => onFilterDataChanged(data.value, 'groupBy')}
      />
      <SelectDropdownWithDates
        placeholder="Select date"
        options={dateByOptions}
        selected={selectedDates}
        onChange={(data: any) => {
          onFilterDataChanged(data, 'date');
        }}
      />
      <SelectDropdownFilters
        placeholder="Filters"
        options={filterByOptions}
        selected={selectedFilters}
        onChange={(data: any) => onFilterDataChanged(data, 'filter')}
      />
    </>
  );
};

const ListHeaderFilters: React.FC<IListHeaderFilters> = (props) => {
  const {
    category,
    isSplitMode,
    sortColumns,
    sortData,
    onSortDataChanged,
    filterData, //filter on Recoil
    onFilterDataChanged,
  } = props;
  //// console.log('filterData', filterData);

  const [date, setDate] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [groupBy, setGroupBy] = useState<any>({});

  useEffect(() => {
    onFilterDataChanged({ ...date, ...filter, ...groupBy });
  }, [date, filter, groupBy]);

  //group by options by category
  const groupByOptions =
    category && category !== 'all' && groupByCategoryOptions?.[category]
      ? [...groupByCategoryOptions[category], ...groupByCategoryOptions.general]
      : groupByCategoryOptions?.[category];

  //filter by options by category
  const filterByOptions =
    filterByCategoryOptions?.[category]
      ? [...filterByCategoryOptions[category], ...filterByCategoryOptions.general]
      : filterByCategoryOptions.general;

  //all filter props
  const filterProps = {
    filterData,
    onFilterDataChanged: (data: any, type: string) => {
      if (type === 'date') {
        const newData: any = {};
        for (const k in data) {
          newData[k] = [dayjs(data[k][0]).toISOString(), dayjs(data[k][1]).toISOString()];
          //data[k][0] = dayjs(data[k][0]).toISOString();
          //data[k][1] = dayjs(data[k][1]).toISOString();
        }
        setDate(newData);
      } else if (type === 'filter') {
        setFilter(data);
      } else {
        setGroupBy(data ? { groupBy: data } : {});
      }
    },
    groupByOptions,
    filterByOptions,
    dateByOptions,
  };

  const sortProps = { onSortDataChanged, sortData, sortColumns };

  return (
    <>
      {isSplitMode ? (
        <ListHeaderFilterSplitMode
          {...filterProps}
          sort={sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} isCollapse />}
        />
      ) : (
        <>
          <ListHeaderFilterNormalMode {...filterProps} />
          {sortColumns && !!sortColumns.length && <ListHeaderSort {...sortProps} />}
        </>
      )}
    </>
  );
};

export default ListHeaderFilters;
