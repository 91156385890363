import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import ListHeader from '@customer/customer/components/list/list-header/new-index';
import ListHeaderFilters from '@customer/customer/components/list/list-header/list-header-filters';
import {
  dateByOptions,
  filterByCategoryOptions,
  //searchFields,
} from '@customer/customer/config/list';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import * as keyNames from '@customer/customer/config/key-names';
import { t } from 'i18next';
import CustomerMergeModal from '@customer/customer/containers/customer-merge-modal';

const PageHeader: React.FC<any> = (props) => {
  const { isSplitMode, fields, category, selectedIds, onChecked, refetch } = props;
  //state + recoil
  const [showMerge, setShowMerge] = useState(false);
  const [listFilter, setListFilter] = useRecoilState(customerListFilterState);
  let { filter, listType } = listFilter;
  const isGroupByDeleted = listFilter.filter[category].query?.deleted?.value || false;
  //console.log('customer listFilter', listFilter);

  //define sort columns
  const sortColumns = [
    //keyNames.KEY_NAME_CUSTOMER_CODE,
    //keyNames.KEY_NAME_CUSTOMER_NAME,
    //keyNames.KEY_NAME_CUSTOMER_TYPE,
    keyNames.KEY_NAME_CUSTOMER_CREATED_AT,
    keyNames.KEY_NAME_CUSTOMER_UPDATED_AT,
  ];

  //sorting
  const sort = filter[category].sort;
  const setSort = (sort: any) => {
    //// console.log('sort....', sort);
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        [category]: {
          ...listFilter.filter[category],
          sort: sort,
        },
      },
    };
    setListFilter(newListFilter);
  };

  //sort columns
  const getSortColumns = () => {
    const layoutSortColumns = fields?.reduce((options: any, col: any) => {
      if (col.sortable || sortColumns.includes(col.keyName)) {
        options.push({
          label: t(col.title),
          value: col.keyName,
        });
      }
      return options;
    }, []);

    return layoutSortColumns;
  };

  //start search
  const handleSearchSubmit = (searchData: any) => {
    let newQuery: any = { ...filter[category].query };
    newQuery.name = { value: searchData?.keyword || '', operator: ':' };
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        [category]: {
          ...listFilter.filter[category],
          query: newQuery,
        },
      },
    };
    setListFilter(newListFilter);
  };

  //filter change
  const handleFilterSubmit = (filterData: any) => {
    //// console.log('header filter change', filterData);
    let newQuery: any = { ...filter[category].query };

    //group by
    if (filterData?.groupBy) {
      switch (filterData.groupBy) {
        case 'typeCustomer':
          newQuery.groupBy = { value: '', operator: '=' }; //reset
          newQuery.deleted = { value: '', operator: '=' };
          newQuery.type = { value: 1, operator: '=' };
          break;
        case 'typeVendor':
          newQuery.groupBy = { value: '', operator: '=' }; //reset
          newQuery.deleted = { value: '', operator: '=' };
          newQuery.type = { value: 3, operator: '=' };
          break;
        case 'typeSalesAgent':
          newQuery.groupBy = { value: '', operator: '=' }; //reset
          newQuery.deleted = { value: '', operator: '=' };
          newQuery.type = { value: 4, operator: '=' };
          break;
        case 'typePartner':
          newQuery.groupBy = { value: '', operator: '=' }; //reset
          newQuery.deleted = { value: '', operator: '=' };
          newQuery.type = { value: 5, operator: '=' };
          break;
        case 'allCustomer':
        case 'allAccount':
        case 'allContact':
          newQuery.type = { value: '', operator: '=' }; //reset
          newQuery.groupBy = { value: '', operator: '=' };
          newQuery.deleted = { value: '', operator: '=' };
          break;
        case 'deletedCustomer':
        case 'deletedAccount':
        case 'deletedContact':
          newQuery.type = { value: '', operator: '=' }; //reset
          newQuery.groupBy = { value: filterData.groupBy, operator: '=' };
          newQuery.deleted = { value: true, operator: '=' };
          break;
        default:
          newQuery.type = { value: '', operator: '=' }; //reset
          newQuery.deleted = { value: '', operator: '=' };
          newQuery.groupBy = { value: filterData.groupBy, operator: '=' };
      }
    }

    //by date: dateByOptions
    dateByOptions.map((_option: any) => {
      if (filterData[_option.value] && filterData[_option.value]?.length > 0) {
        newQuery[_option.value] = {
          condition: 'AND',
          criteria: [
            { value: filterData[_option.value][0], operator: '>=' },
            { value: filterData[_option.value][1], operator: '<=' },
          ],
        };
      } else {
        newQuery[_option.value] = {}; //all
      }
    });

    //filter by options by category
    const filterByOptions: any = filterByCategoryOptions?.[category]
      ? [...filterByCategoryOptions[category], ...filterByCategoryOptions.general]
      : filterByCategoryOptions.general;
    filterByOptions.map((_option: any) => {
      //finding the filter data
      if (filterData[_option.value]) {
        if (Array.isArray(filterData[_option.value])) {
          const tmpValues: any[] = [];
          filterData[_option.value].map((_ele: any) => {
            tmpValues.push({ value: _ele, operator: '=' });
          });
          newQuery[_option.value] = { condition: 'OR', criteria: tmpValues };
        } else {
          newQuery[_option.value] = { value: filterData[_option.value], operator: '=' };
        }
      } else {
        newQuery[_option.value] = { value: '', operator: '=' };
      }
    });

    //set global filter
    let newListFilter = {
      ...listFilter,
      filter: {
        ...listFilter.filter,
        [category]: {
          ...listFilter.filter[category],
          query: newQuery,
        },
      },
    };
    setListFilter(newListFilter);
  };

  //empty all deleted items
  const handleEmpty = () => {
    //TOTO
  }

  //do action
  const handleSelectAction = (actionKey: string) => {
    switch (actionKey) {
      case 'merge':
        setShowMerge(true);
        break;
    }
  };

  //more actions
  const moreActions = {
    actions: {
      merge: {
        label: 'Merge',
        icon: 'merge',
        iconType: 'common',
      },
      import: {
        label: 'Import',
        icon: 'UploadCloud',
      },
      export: {
        label: 'Export',
        icon: 'Download',
      },
    },
    onSelect: (key: string) => handleSelectAction(key),
  };

  //render
  return (
    <>
      <ListHeader
        listType={listType}
        isSplitMode={isSplitMode}
        // searchFields={searchFields.reduce((final: any[], field: any) => {
        //   if (columnKeys.indexOf(field.name) !== -1) {
        //     final.push(field);
        //   }
        //   return final;
        // }, [])}
        searchValue={listFilter.filter[category].query?.name?.value || ''}
        onSearchSubmit={handleSearchSubmit}
        columns={fields}
        category={category || 'account'}
        filterComponent={
          <ListHeaderFilters
            category={category || 'account'}
            isSplitMode={isSplitMode}
            filterData={filter[category].query} //query
            onFilterDataChanged={handleFilterSubmit}
            sortData={sort}
            onSortDataChanged={setSort}
            sortColumns={getSortColumns()}
          />
        }
        selectedIds={selectedIds}
        onSelectedAll={() => onChecked('all')}
        onDeselectedAll={() => onChecked('notAll')}
        //onRefresh={refetch}
        onEmpty={isGroupByDeleted ? handleEmpty : undefined}
        actionsProps={moreActions}
      />
      {showMerge &&
        <CustomerMergeModal
          title={'Merge Customer'}
          isOpen={showMerge}
          onClose={() => setShowMerge(false)}
          defaultItems={[]}
          category={category}
          onReload={refetch}
        />
      }
    </>
  );
};

export default PageHeader;
