import React from 'react';
import TextView from '@base/containers/view-field/text/view';

//TODO: convert value to address string
const View: React.FC = (props: any) => {
  const { value } = props;
  return <TextView value={value ?? ''} />;
};

export default View;
