export const formatAddress = (address: any) => {
  let strAddr = [];
  address?.street && strAddr.push(address?.street);
  address?.city && strAddr.push(address?.city);
  address?.state && strAddr.push(address?.state);
  address?.country?.country && strAddr.push(address?.country?.country);
  return `${strAddr.join(', ')} ${address?.zipcode || ''}`;
};

export const convertItemToAddress = (address: any) => {
  if (!address) {
    return {};
  }
  return {
    id: address?.id,
    option: {
      value: '4',
      label: 'Home',
    },
    optionDescription: '',
    country: address?.country,
    zipcode: address?.zipcode || '',
    state: {
      value: address?.state,
      label: address?.state,
    },
    city: {
      value: address?.city,
      label: address?.city,
    },
    address: address?.street,
  };
};

export const convertAddressToItem = (address: any) => {
  return {
    id: address?.id,
    street: address?.address,
    city: address?.city?.value,
    state: address?.state?.value,
    country: address?.country?.value,
    zipcode: address?.zipcode,
  };
};

export const getCountryCode = (item: any) => {
  try {
    return JSON.parse(item?.country)?.iso_code_2;
  } catch (error) {
    return '';
  }
};

export const getAddressesBaseType = (data = [], idType = '') => {
  let address = null;
  let addresses: any = [];
  data.map((item: any) => {
    if (item?.label?.label == idType) {
      addresses.push(item);
      if (item.isDefault == 1) {
        address = item;
      }
    }
  });
  //set default selected
  if (!address) {
    address = addresses?.[0] || {};
  }
  return {
    address,
    addresses,
  };
};

// {
// option: {
//     value: "4",
//     label: "Home",
// },
// optionDescription: "",
// country: {
//     value: "VN",
//     label: "Vietnam",
//     iso_code_2: "VN",
// },
// zipcode: "700000",
// state: {
//     value: "Ho Chi Minh City",
//     label: "Ho Chi Minh City",
// },
// city: {
//     value: "Ho Chi Minh City",
//     label: "Ho Chi Minh City",
// },
// address:
//     "3rd Floor, Phuc Tan Nguyen Building 400 Nguyen Thi Thap, Tan Quy Ward, District 7, HCMC, Vietnam",
// id: 135,
// idLocal: "_0d56g70gy",
// }
