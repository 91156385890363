import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const MainPage: React.FC = () => {
  return (
    <Routes>
      {/* default router */}
      <Route path="/" element={<Navigate replace to="/customer/all" />} />
    </Routes>
  );
};

export default MainPage;
