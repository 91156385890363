import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import WebsiteView from '@base/containers/view-field/website/view';
import PhoneView from '@base/containers/view-field/phone/view';
import EmailView from '@base/containers/view-field/email/view';
import { MENU_CUSTOMER } from '@base/config/menus';

interface ICompanyProps {
  //category?: string;
  //id?: string;
  data?: any;
}

const CompanyInfo: React.FC<ICompanyProps> = (props: ICompanyProps) => {
  const { data } = props;
  //// console.log('company data', data);
  const routeLink = `/${MENU_CUSTOMER}/account/${data?.id}`;
  //render
  return (
    <>
      <div className="form-group">
        <label className="form-item-title d-block">Name</label>
        <Link to={routeLink}>
          {data?.name}
          <ExternalLink size="14" className="mg-l-5" />
        </Link>
      </div>
      <div className="form-group">
        <label className="form-item-title">Email</label>
        <EmailView value={data?.emails} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Website</label>
        <WebsiteView value={data?.websites} />
      </div>
      <div className="form-group">
        <label className="form-item-title">Phone</label>
        <PhoneView value={data?.phones} />
      </div>
    </>
  );
};

export default CompanyInfo;
