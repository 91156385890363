import * as keyNames from '@customer/customer/config/key-names';
import * as components from '@customer/customer/config/write-field/components';
import validators from '@base/utils/validation/field-validator';
import {
  LABEL_VALUE_BIRTHDAY,
  LABEL_VALUE_CUSTOM_WEB,
  LABEL_VALUE_OTHER,
} from '@customer/customer/config/constants';

export default {
  [keyNames.KEY_NAME_CUSTOMER_NAME]: {
    component: components.Input,
    validate: {
      required: validators.required,
    },
    defaultValue: '',
    //parseValue: (valueApi: any) => valueApi || '',
    parseParam: (value: any) => value,
  },
  [keyNames.KEY_NAME_CUSTOMER_PHOTO]: {
    component: components.ImageUploader,
    componentProps: {
      isSingle: true,
      type: 'img',
    },
    validate: {},
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value, //File[]
  },
  //[dataTypes.DATA_TYPE_CUSTOMER_EMAIL]: {
  [keyNames.KEY_NAME_CUSTOMER_EMAIL]: {
    component: components.Email,
    componentProps: {
      isMultiple: true,
    },
    validate: {
      emails: validators.emails, //'emails' --> error key
    },
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value?.map((_ele: any) => ({
        label: _ele.label?.label || LABEL_VALUE_OTHER,
        labelValue: _ele.labelValue,
        email: _ele.email,
      })),
  },
  //[dataTypes.DATA_TYPE_CUSTOMER_GENDER]: {
  [keyNames.KEY_NAME_CUSTOMER_GENDER]: {
    component: components.Gender,
    validate: {},
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value.value || '',
  },
  [keyNames.KEY_NAME_CUSTOMER_PHONES]: {
    component: components.Phone,
    componentProps: {
      isMultiple: true,
    },
    validate: {},
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value
        ?.filter((_ele: any) => _ele.phoneNumber.length > 0)
        ?.map((_ele: any) => ({
          label: _ele.label?.label || LABEL_VALUE_OTHER,
          labelValue: _ele.labelValue,
          country: _ele.country,
          phoneNumber: _ele.phoneNumber,
          extension: _ele.extension,
        })),
  },
  [keyNames.KEY_NAME_CUSTOMER_MOBILE]: {
    component: components.Mobile,
    componentProps: {
      isMultiple: true,
    },
    validate: {},
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value
        ?.filter((_ele: any) => _ele.mobileNumber.length > 0)
        ?.map((_ele: any) => ({
          label: _ele.label?.label || LABEL_VALUE_OTHER,
          labelValue: _ele.labelValue,
          country: _ele.country,
          mobileNumber: _ele.mobileNumber,
          //extension: _ele.extension,
        })),
  },
  [keyNames.KEY_NAME_CUSTOMER_ADDRESSES]: {
    component: components.Address,
    validate: {
      isMultiple: true,
    },
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value
        .filter((_ele: any) => _ele.street.length > 0)
        .map((_ele: any) => ({
          //label: _ele.label?.label,
          //labelValue: _ele.labelValue,
          country: _ele.country?.isoCode2,
          addrState: _ele.state,
          city: _ele.city,
          street: _ele.street,
          zipcode: _ele.zipcode,
        })),
  },
  [keyNames.KEY_NAME_CUSTOMER_TYPE]: {
    // component: components.CustomerType,
    component: components.DataSourceSelect,
    componentProps: {
      single: true,
      sourceKey: 'customer_category',
      sourceType: 'field',
    },
    validate: {
      required: validators.required,
    },
    showFullRow: true,
    defaultValue: null,
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any) => value?.value || 'TYPE_NONE',
  },
  [keyNames.KEY_NAME_CUSTOMER_INDUSTRIES]: {
    //component: components.Fax,
    component: components.DataSourceSelect,
    componentProps: {
      single: false,
      sourceKey: 'industry',
      sourceType: 'field', //group
    },
    showFullRow: true,
    validate: {},
    defaultValue: [],
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value.map((_ele: any) => ({
        id: _ele.id,
        name: _ele.label,
      })),
  },
  [keyNames.KEY_NAME_CUSTOMER_ANNIVERSARIES]: {
    component: components.Anniversary,
    validate: {},
    defaultValue: [],
    showFullRow: true,
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value.map((_ele: any) => ({
        label: _ele.label?.label || LABEL_VALUE_BIRTHDAY,
        labelValue: _ele?.labelValue || '',
        anniversary: _ele.anniversary,
      })),
  },
  [keyNames.KEY_NAME_CUSTOMER_WEBSITES]: {
    component: components.Website,
    validate: {},
    defaultValue: [],
    //parseValue: (valueApi: any) => valueApi || [],
    parseParam: (value: any) =>
      value.map((_ele: any) => ({
        label: _ele.label?.label || LABEL_VALUE_CUSTOM_WEB,
        labelValue: _ele?.labelValue || '',
        website: _ele.website,
        protocol: _ele.protocol,
      })),
  },
  [keyNames.KEY_NAME_CUSTOMER_ASSIGN_TO]: {
    component: components.UserAutoComplete,
    componentProps: {
      showAvatar: true,
      single: false,
    },
    validate: {},
    defaultValue: [],
    //parseValue: (valueApi: any) => valueApi || null,
    parseParam: (value: any[]) =>
      value?.map((_ele: any) => ({
        user: {
          id: _ele.id ? _ele.id : '',
          name: _ele.name ? _ele.name : '',
        },
        group: {
          id: _ele.properties?.crmGroups?.length > 0 ? _ele.properties?.crmGroups[0]?.id : '',
          name: _ele.properties?.crmGroups?.length > 0 ? _ele.properties?.crmGroups[0]?.name : '',
        },
      })),
  },
  //REMOVED
  // [keyNames.KEY_NAME_CUSTOMER_CONNECTED_IPS]: {
  //   component: components.IpAddress,
  //   validate: {},
  //   defaultValue: [],
  //   //parseValue: (valueApi: any) => valueApi || [],
  //   parseParam: (value: any) =>
  //     value.map(
  //       (_ele: any) =>
  //         _ele.ip !== '...' && {
  //           ip: _ele.ip,
  //         },
  //     ),
  // },
  // [keyNames.KEY_NAME_CUSTOMER_GROUPS]: {
  //   component: components.DataSourceSelect,
  //   componentProps: {
  //     sourceKey: 'customer_group',
  //     sourceType: 'group',
  //     single: false,
  //   },
  //   validate: {},
  //   defaultValue: [],
  //   //parseValue: (valueApi) => valueApi || [],
  //   parseParam: (value: any) => value?.map((_ele: any) => ({ id: _ele.id, name: _ele.title })),
  // },
};
