import React from 'react';
import classnames from 'classnames';
import { Anniversary } from '@customer/customer/components';

const Edit: React.FC = (props: any) => {
  const { value = [], onChange = () => { }, errors = {}, onSave = () => { } } = props;

  return (
    <div className={classnames('')}>
      <div
        className={classnames({
          'is-required': errors.isRequired,
        })}
      >
        <Anniversary {...props} />
      </div>
      {errors.isRequired && <div className="tx-danger tx-12">{errors.isRequired}</div>}
    </div>
  );
};

export default Edit;
