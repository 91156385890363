import React from 'react';
import ListTableColumnSetting from '@base/components/list/list-table-column-setting';
import ListSearch from '@base/components/list/list-search';
import ListHeaderContainer, { IListHeader } from '@base/components/list/list-header';
import { ListType } from '@base/types/enums';
import { useRecoilState } from 'recoil';
import { customerListFilterState } from '@customer/customer/recoil/atom/list-atom';
import { MENU_CUSTOMER } from '@base/config/menus';
import useMutationPost from '@base/hooks/useMutationPost';
import { BaseMutationResponse } from '@base/types/interfaces/response';
import { UPDATE_USER_SETTING } from '@base/services/graphql/setting';

interface IListHeaderExtend extends IListHeader {
  filterComponent?: React.ReactNode;
  isSplitMode: boolean;
  listType: ListType;
  category: string;
  columns?: any[];
  searchFields?: any[];
  searchValue?: string;
  onSearchSubmit?: (fieldsData: any) => void;
  onEmpty?: () => void;
}

const ListHeader: React.FC<IListHeaderExtend> = (props) => {
  const {
    listType,
    isSplitMode,
    filterComponent,
    category,
    columns = [], //not use
    searchFields,
    searchValue,
    onSearchSubmit,
    ...remainProps
  } = props;
  //recoil
  const [listFilter, setListFilter] = useRecoilState(customerListFilterState);

  //mutation update
  const mUserSettingUpdate: any = useMutationPost<BaseMutationResponse>(
    UPDATE_USER_SETTING,
    'setting_updateUserSetting',
    {},
  );

  //column setting change
  const handleColumnSettingChange = (data: any) => {
    //update to recoil
    const newListFilter = {
      ...listFilter,
      settingColumns: {
        ...listFilter.settingColumns,
        [category]: data,
      },
      activeMenu: category,
    };
    setListFilter(newListFilter);

    //save setting to db
    const params = {
      userSetting: {
        menu: MENU_CUSTOMER,
        key: 'list',
        value: JSON.stringify(newListFilter)
      },
    };
    mUserSettingUpdate.mutate(params);
  };

  return (
    <ListHeaderContainer
      isSimple={isSplitMode}
      filterComponent={filterComponent}
      searchComponent={
        // onSearchSubmit && (
        // <React.Suspense fallback={<></>}>
        <ListSearch
          isSimple={isSplitMode}
          placeholder={'Name'}
          //searchFields={searchFields}
          searchValue={searchValue}
          onSubmit={onSearchSubmit}
        />
        // </React.Suspense>
        // )
      }
      columnSettingComponent={
        !isSplitMode &&
        listType == ListType.LIST && (
          <ListTableColumnSetting
            menuSource={`${MENU_CUSTOMER}_${category}`}
            columns={listFilter.settingColumns[category]}
            //hideColumns={[]}
            columLimit={10}
            onChange={handleColumnSettingChange}
          />
        )
      }
      {...remainProps}
    />
  );
};

export default ListHeader;
