import React, { useEffect, useState } from 'react';
import CustomerAutoComplete from '@customer/customer/containers/customer-auto-complete';

interface ICustomerContactProps {
  hideCustomerLabel?: boolean;
  value?: any;
  onChange?: any;
}

const CustomerContact: React.FC<ICustomerContactProps> = (props) => {
  const { hideCustomerLabel = false, value, onChange } = props;
  //// console.log('edit value', value);
  //state
  const [curCustomer, setCurCustomer] = useState<any>(null);
  const [curContact, setCurConctact] = useState<any>(null);

  //initial value
  useEffect(() => {
    if (value) {
      if (value?.customer) {
        if (value.customer?.id !== curCustomer?.id) {
          setCurCustomer(value.customer);
        }
      }
      if (value?.contact) {
        if (value.contact?.id !== curContact?.id) {
          setCurConctact(value.contact);
        }
      }
    } else {
      setCurCustomer(null);
      setCurConctact(null);
    }
  }, [value]);

  //value change
  const handleCustomerChange = (newCustomer: any) => {
    setCurCustomer(newCustomer);
    setCurConctact(null);
    //callback
    onChange && onChange({ customer: newCustomer, contact: null });
  };

  //value change
  const handleContactChange = (newContact: any) => {
    setCurConctact(newContact);
    //callback
    onChange && onChange({ customer: curCustomer, contact: newContact });
  };

  return (
    <div className="pos-relative">
      <div className="form-group">
        {!hideCustomerLabel && <label className="d-block">Customer</label>}
        <CustomerAutoComplete
          //category={'all'}
          single={true}
          type={'TYPE_CUSTOMER'}
          showAvatar={true}
          value={curCustomer}
          onChange={handleCustomerChange}
        />
      </div>
      {curCustomer?.category === 'CATEGORY_ACCOUNT' && (
        <div className="form-group">
          <label className="form-item-title">Contact</label>
          <CustomerAutoComplete
            category="contact"
            single={true}
            accountId={curCustomer?.id}
            showAvatar={true}
            value={curContact}
            onChange={handleContactChange}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerContact;
