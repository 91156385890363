import { lazy } from 'react';

const CustomerMain = lazy(() => import('@customer/customer/pages/main'));

export default [
  {
    path: '/customer/all/*',
    component: CustomerMain,
  },
  {
    path: '/customer/account/*',
    component: CustomerMain,
  },
  {
    path: '/customer/contact/*',
    component: CustomerMain,
  },
];
