import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { MinusSquare, PlusSquare } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Select, SpanLang } from '@base/components/form';
import { defaultPhoneAtom, phonesAtom } from '@base/recoil/atoms';
import { GENERAL_LABEL_OPTIONS, LABEL_VALUE_CUSTOM, LABEL_VALUE_PRIMARY, PERSONAL_LABEL_OPTIONS } from '@customer/customer/config/constants';

const Fax: React.FC<any> = (props: any) => {
  const { category = 'account', value, onChange, isMultiple = false } = props;

  const { t } = useTranslation();
  const defaultPhone = useRecoilValue(defaultPhoneAtom);
  const phoneCodes = useRecoilValue(phonesAtom);

  //default row
  const defaultRow = {
    label: {
      languageKey: 'Primary',
      label: LABEL_VALUE_PRIMARY
    },
    labelValue: '',
    country: defaultPhone?.value, //phone code
    faxNumber: '',
    primary: false,
  };

  const FAX_OPTIONS = PERSONAL_LABEL_OPTIONS; //category === 'account' ? GENERAL_LABEL_OPTIONS : PERSONAL_LABEL_OPTIONS;

  //state
  const [rows, setRows] = useState<any>([]);

  //init rows
  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (JSON.stringify(value) !== JSON.stringify(rows)) {
            setRows(value);
          }
        } else {
          setRows([_.cloneDeep(defaultRow)]);
        }
      } else {
        setRows([value]);
      }
    } else {
      setRows([_.cloneDeep(defaultRow)]);
    }
  }, [value]);

  //add more email
  const handleAddMore = () => {
    const newDefaultRow = {
      ...defaultRow,
      label: {
        languageKey: 'Custom',
        label: LABEL_VALUE_CUSTOM
      },
    };
    let newRows = [...rows, newDefaultRow];
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //remove email
  const handleRemove = (i: number) => {
    let newRows = [...rows];
    newRows.splice(i, 1);
    let existPrimary = false;
    //reset primary
    newRows.map((_ele: any) => {
      if (_ele.label.label === LABEL_VALUE_PRIMARY) {
        existPrimary = true;
      }
    })
    if (!existPrimary) {
      newRows[0].label = { languageKey: 'Primary', label: LABEL_VALUE_PRIMARY };
      //newRows[0].primary = true;
    }
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  };

  //value change
  const handleValueChange = (index: number, keyAttribute: string, keyValue: any) => {
    const newRows = [...rows];
    newRows[index][keyAttribute] = keyValue;
    //set primary
    if (keyAttribute === 'label') {
      if (keyValue.label === LABEL_VALUE_PRIMARY) {
        //primary
        newRows[index].primary = true;
        //reset to others to false
        for (let i = 0; i < newRows.length; i++) {
          if (i !== index) {
            if (newRows[i].label.label === LABEL_VALUE_PRIMARY) {
              newRows[i].label = { languageKey: 'Custom', label: LABEL_VALUE_CUSTOM };
              //newRows[i].primary = false;
            }
          }
        }
      }
    }
    setRows(newRows);
    //callback
    onChange && onChange(isMultiple ? newRows : newRows[0]);
  }

  //render
  return (
    <div>
      {rows.map((row: any, idx: number) => {
        return (
          <div className="pos-relative" key={idx}>
            <div className="form-row mg-b-10">
              <div className="col-3">
                <Select
                  isSearchable={false}
                  value={phoneCodes[phoneCodes.findIndex((opt) => opt.value === row.country)]}
                  options={phoneCodes}
                  onChange={(newPhoneCode: any) => handleValueChange(idx, 'country', newPhoneCode.value)}
                //isLoading={isLoading}
                />
              </div>
              <div className={classNames('col-5')}>
                <input
                  placeholder={'Fax number'}
                  className="form-control ht-38"
                  type="number"
                  value={row?.faxNumber || ''}
                  onChange={(e: any) => handleValueChange(idx, 'faxNumber', e.target.value)}
                />
              </div>
              <div className={classNames('col-4')}>
                <Select
                  isSearchable={false}
                  value={FAX_OPTIONS[FAX_OPTIONS.findIndex((opt) => opt.value === row.label.label)]}
                  options={FAX_OPTIONS}
                  onChange={(option: any) => handleValueChange(idx, 'label', { ...option, label: option.value, languageKey: option.label })}
                />
              </div>
            </div>
            {row.label?.label === LABEL_VALUE_CUSTOM && (
              <div className="form-row mg-b-5">
                <div className="col-md-12">
                  <input
                    placeholder={t('crm_new_common_description')}
                    className="form-control ht-38"
                    type="text"
                    size={7}
                    value={row.labelValue}
                    onChange={(e) => handleValueChange(idx, 'labelValue', e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="form-row mg-b-5">
              {idx !== 0 && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-danger pd-x-5 pd-y-0"
                  onClick={() => {
                    handleRemove(idx);
                  }}
                >
                  <MinusSquare />
                  <SpanLang keyLang="Delete" />
                </button>
              )}
              {idx != rows.length - 1 && (
                <div className="d-flex justify-content-center mg-y-10" style={{ width: '100%' }}>
                  <div className="wd-50" style={{ border: "gray dashed 0.1px" }}></div>
                </div>
              )}
              {idx === rows.length - 1 && isMultiple && (
                <button
                  type="button"
                  className="btn btn-xs add-mode-btn text-primary pd-x-5 pd-y-0"
                  onClick={handleAddMore}
                >
                  <PlusSquare />
                  <SpanLang keyLang="Add" />
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Fax;
